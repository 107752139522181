<template>
  <div
    class="h-[var(--ld-viewport-height)] leading-[1.8] px-6 bg-ld-background g-safe-area"
  >
    <Loading
      v-if="initLoading"
      class="h-full"
    />

    <RatioSpacedContainer
      v-else-if="status == ApplyStatus.AVAILABLE && showPurposeForm"
      class="h-full g-body-width"
    >
      <div class="basis-[90%] flex flex-col">
        <div class="flex greet items-center">
          <Img
            name="ld-greet"
            class="w-77px"
          />
          <div class="chat">{{ _t('你想制作卡包的目的是？') }}</div>
        </div>

        <div class="flex flex-col w-full gap-4 mt-11 mb-4">
          <Button
            v-for="item of PURPOSES"
            :scene="selectedKey === item.key ? 'choiceSelected' : 'choice'"
            class="w-full"
            @click="selectedKey = item.key"
          >
            <div>
              {{ _t(item.title) }}
            </div>
          </Button>
        </div>

        <Textarea
          v-show="selectedKey === 'other'"
          v-model="customReason"
          class="w-full mb-2 max-h-304px"
          rows="4"
          autoResize
          placeholder="请输入"
        />

        <Button
          :label="_t('下一步')"
          :loading="submitLoading"
          class="w-full mt-auto"
          @click="onNextForm"
        ></Button>
      </div>
    </RatioSpacedContainer>

    <RatioSpacedContainer
      v-else-if="status == ApplyStatus.AVAILABLE && showSuggestionForm"
      class="h-full g-body-width"
    >
      <div class="basis-[90%] flex flex-col overflow-hidden">
        <div class="flex greet items-center">
          <Img
            name="ld-greet"
            class="w-77px"
          />
          <div class="chat">{{ _t('你在小灵鸭体验如何，有什么建议吗？') }}</div>
        </div>

        <div class="flex-1 overflow-hidden my-6">
          <Textarea
            v-model="suggestion"
            class="w-full max-h-full"
            rows="4"
            autoResize
            placeholder="请输入"
          />
        </div>

        <Button
          :label="_t('提交申请')"
          :loading="submitLoading"
          class="w-full mt-auto"
          @click="onSubmit"
        ></Button>
      </div>
    </RatioSpacedContainer>

    <RatioSpacedContainer
      v-else
      class="h-full g-body-width"
    >
      <Img
        name="ld-look"
        class="w-107px"
      />

      <div class="text-33px mt-4 mb-12">
        {{ _t(beta?.title) }}
      </div>

      <div class="text-24px text-[var(--text-color-secondary)] mb-12">
        {{ _t(beta?.content) }}
      </div>

      <div
        v-if="status === ApplyStatus.UNAVAILABLE"
        class="text-21px text-ld-label-secondary"
      >
        {{ closedOrRejectedReason }}
      </div>

      <div
        v-else-if="status === ApplyStatus.PENDING"
        class="text-15px text-ld-label-secondary text-center w-full"
      >
        <div>
          {{ _t('你已加入 waitinglist ') }}
        </div>
        <div>{{ _t('申请成功后将通过『小灵鸭公众号』发送通知') }}</div>

        <div class="mt-3 flex flex-col gap-3">
          <Button
            :label="_t('已申请')"
            disabled
            class="w-full"
          ></Button>

          <Button
            :label="_t('回到首页')"
            scene="secondary"
            class="w-full"
            @click="onBackToHomePage"
          ></Button>
        </div>
      </div>

      <div
        v-else-if="status === ApplyStatus.APPROVED"
        class="flex flex-col items-stretch w-full"
      >
        <div
          class="flex items-center justify-center bg-bluegray-200 rounded-12px h-65px px-2"
        >
          <div class="w-40px h-40px relative items-center">
            <LottieBox
              name="check-mark"
              :loop="false"
              :autoplay="true"
              class="absolute absolute-center w-80px h-80px"
            />
          </div>
          <span
            class="text-21px ml-2 font-semibold text-ld-brand-500 leading-[1.4]"
          >
            {{ _t('你已通过申请，快去体验吧') }}
          </span>
        </div>

        <Button
          :label="_t('去体验')"
          :loading="submitLoading"
          class="mt-8 mx-2"
          @click="onToPackage"
        ></Button>
      </div>

      <Button
        v-else
        :label="_t('立即申请')"
        :loading="submitLoading"
        class="w-full"
        @click="onApply"
      ></Button>
    </RatioSpacedContainer>
  </div>
</template>
<script setup lang="ts">
import {
  applyBetaFeature,
  fetchBetaFeatureStatus,
  ApplyStatus,
  FeatureType,
  updateSubscribeScene,
} from '@/api/beta-feature'
import RatioSpacedContainer from '@/components/RatioSpacedContainer.vue'
import { ref } from 'vue'
import Textarea from 'primevue/textarea'
import LottieBox from '@/components/LottieBox.vue'
import { useRoute, useRouter } from 'vue-router'
import { useClipboard } from '@vueuse/core'
import {
  ALPHA_CREATOR_APPROVED_SCENE,
  openWechatApp,
  wechatSubscribeRedirect,
} from '@/utils/wechat'
import { uploadWechatOpenId } from '@/api/user'

const PURPOSES = [
  {
    title: '✍️ 自己学习',
    key: 'own',
  },
  {
    title: '🌐 分享给他人使用',
    key: 'share',
  },
  {
    title: '🤔 其他',
    key: 'other',
  },
]

const route = useRoute()
const router = useRouter()

const initLoading = ref(true)
const submitLoading = ref(false)
const status = ref<ApplyStatus>(ApplyStatus.AVAILABLE)
const beta = ref<{
  title: string
  content: string
}>()
const closedOrRejectedReason = ref<string>()
const showPurposeForm = ref(false)
const showSuggestionForm = ref(false)
const selectedKey = ref<string>()
const customReason = ref('')
const suggestion = ref('')

const { copy } = useClipboard({ source: '' })

function onApply() {
  if (!_global.isInsideWechat) {
    _confirm({
      type: 'wechat',
      icon: {
        name: 'ld-avatar',
        type: 'svg',
      },
      okText: _t('复制名称、去微信'),
      content: _t('目前仅支持通过微信公众号申请\n请前往微信搜索：小灵鸭公众号'),
      async onConfirm() {
        copy('小灵鸭公众号')
        openWechatApp()

        return false
      },
    })
    return
  }
  showPurposeForm.value = true
}

function onNextForm() {
  if (selectedKey.value == null) {
    _message.info(_t('请选择你申请内测的目的'))
    return
  }

  if (selectedKey.value === 'other' && customReason.value.trim() === '') {
    _message.info(_t('请填写内容'))
    return
  }

  showPurposeForm.value = false
  showSuggestionForm.value = true
}

function onSubmit() {
  if (submitLoading.value) return

  const purpose = PURPOSES.find(item => item.key === selectedKey.value)!

  submitLoading.value = true
  applyBetaFeature(FeatureType.AlphaCreator, {
    purpose: purpose.title,
    customReason: customReason.value,
    suggestion: suggestion.value,
  })
    .then(() => {
      status.value = ApplyStatus.PENDING
      showPurposeForm.value = false

      // 提交成功后跳转至微信一次性订阅消息授权
      wechatSubscribeRedirect(
        ALPHA_CREATOR_APPROVED_SCENE,
        location.pathname + location.search
      )
    })
    .finally(() => {
      submitLoading.value = false
    })
}

function fetchApplyStatus() {
  fetchBetaFeatureStatus(FeatureType.AlphaCreator)
    .then(res => {
      status.value = res.status
      beta.value = res.beta

      // 无论什么原因不可申请，都显示「本次内测申请人数已满」
      closedOrRejectedReason.value = _t('本次内测申请人数已满')
    })
    .finally(() => {
      initLoading.value = false
    })
}

function onToPackage() {
  router.push({
    name: 'shelf',
  })
}

function onBackToHomePage() {
  router.push(_global.homePage)
}

function checkSubscribeCallback() {
  // 如果页面带有这两个参数，则认为是从一次性订阅消息授权通知回调回来的
  if (route.query.action && route.query.template_id) {
    // 授权同意则会带上 openId，此时更新一下 openid
    if (route.query.openid) {
      uploadWechatOpenId(route.query.openid as string)
    }

    // 如果用户同意了授权，则把 scene 上传给后端表示授权通过
    if (route.query.action === 'confirm') {
      updateSubscribeScene(
        FeatureType.AlphaCreator,
        ALPHA_CREATOR_APPROVED_SCENE
      )
    }

    router.replace({
      name: route.name as string,
    })
  }
}

onInit(() => {
  checkSubscribeCallback()
  fetchApplyStatus()
})
</script>
<style scoped>
.greet .chat {
  background-color: white;
  border-radius: 18px;
  padding: 12px 16px;
  font-size: 17px;
  line-height: 22px;
  height: fit-content;
  border: 1px solid var(--ld-border);
  box-shadow: 0px 0px 2px 0px var(--slate-300);
}
</style>
