<template>
  <div
    v-if="editor"
    class="h-full flex flex-col"
  >
    <FloatingMenu :editor="editor" />

    <EditorContent
      :editor="editor"
      class="cloze-editor flex-1"
      @keydown="onKeydown"
      @contextmenu="onContextMenu"
    />

    <div
      v-if="wordOverflow > 0"
      class="text-red-500 text-17px mt-10px"
    >
      超出字数限制，请再删减 {{ wordOverflow }} 个字
    </div>
    <div
      v-if="toolbtns"
      class="flex items-center ml--2"
    >
      <div
        v-tooltip.top="
          _global.isMac ? '标记知识点\nCmd + D' : '标记知识点\nCtrl + D'
        "
        class="toolbtn"
        @click="editor.chain().toggleCloze().run()"
      >
        <Icon
          name="toolbar-cloze"
          class="w-24px text-gray-600"
        />
      </div>

      <div class="ml-auto text-gray-600">
        <slot name="toolbtns"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEditor, EditorContent, type Extensions } from '@tiptap/vue-3'
import History from '@tiptap/extension-history'
// block nodes
import Document from './extensions/doc'
import Paragraph from './extensions/p'
// inline nodes
import Text from './extensions/text'
// marks
import Cloze from './extensions/cloze'
import PlainText from './extensions/plain-text'
import Placeholder from '@tiptap/extension-placeholder'
import CustomPaste from './extensions/custom-paste'
import ClearMarksOnEnter from './extensions/clear-mark-on-enter'
import { computed, onMounted, onUnmounted } from 'vue'
import bus, { BusEvent } from '@/bus/bus'
import { content2Doc, doc2Content } from '@/utils/card'
import TextStyle, { DotText } from './extensions/text-style'
import { FloatingMenu } from './FloatingMenu'

import type { Content } from '@/types/core'

const props = withDefaults(
  defineProps<{
    content: Content
    id?: number
    editable?: boolean
    limit?: number
    autofocus?: boolean
    placeholder?: string
    toolbtns?: boolean
  }>(),
  {
    editable: true,
    autofocus: false,
    placeholder: '在此输入卡片内容',
    toolbtns: true,
  }
)

const emit = defineEmits<{
  update: [Content]
  focus: []
  blur: []
  prev: []
  next: []
  createNext: []
}>()

const extensions: Extensions = [
  Document,
  // Block
  Paragraph,
  // Inline
  Text,
  // marks
  Cloze,
  PlainText,
  TextStyle,
  Placeholder.configure({
    placeholder: props.placeholder,
  }),
  History,
  CustomPaste,
  ClearMarksOnEnter,
]

// 编辑器某些 feature 在 prod 隐藏
// 注意需要检查一下其他地方是否依赖相应的插件如浮动菜单
if (!_global.isProd) {
  extensions.push(...[DotText])
}

const editor = useEditor({
  editable: props.editable,
  content: content2Doc(props.content),
  extensions,
  editorProps: {
    attributes: {
      class: 'focus:outline-none',
    },
  },
  onUpdate({ editor }) {
    const doc = editor.getJSON()

    emit('update', doc2Content(doc))
  },
  onFocus() {
    emit('focus')
  },
  onBlur() {
    emit('blur')
    if (editor.value != null) {
      // 当前字数
      const currentWordCount = editor.value.getText().length
      if (props.limit != null && currentWordCount > props.limit) {
        editor.value.commands.deleteRange({
          from: props.limit + 1,
          to: currentWordCount + 1,
        })
      }
    }
  },
})

// 超出多少字
const wordOverflow = computed(() => {
  if (editor.value != null) {
    // 当前字数
    const currentWordCount = editor.value.getText().length
    if (props.limit != null && currentWordCount > props.limit) {
      return currentWordCount - props.limit
    }
  }
  return 0
})

function onKeydown(e: KeyboardEvent) {
  const key = e.key.toLowerCase()

  if (key === 'enter' && (e.metaKey || e.ctrlKey)) {
    emit('createNext')
  }

  if (key === 'tab') {
    e.preventDefault()

    if (e.shiftKey) {
      emit('prev')
    } else {
      emit('next')
    }
  }
}

// 禁用系统的浮动菜单
function onContextMenu(e: PointerEvent) {
  e.preventDefault()
}

function onFocusCall(id: number) {
  if (props.id === id && id != null && !editor.value?.isFocused) {
    editor.value?.commands.focus('end')
  }
}

onMounted(() => {
  bus.on(BusEvent.CardFocus, onFocusCall)
})

onUnmounted(() => {
  bus.off(BusEvent.CardFocus, onFocusCall)
})
</script>
<style lang="stylus">
.tiptap
  outline: none

  p.is-editor-empty:first-child::before {
    color: var(--gray-500);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  p {
    margin-bottom: 0px;
  }

  span.dot {
    margin: 0px 4px;
  }

  span.dot:before,span.dot:after {
    content: '·';
    display: inline;
  }
  span.dot:before {
    margin-right: 2px;
  }
  span.dot:after {
    margin-left: 2px;
  }

.toolbtn
  padding: 4px
  border-radius: 4px
  user-select: none

  &:hover
    background-color: var(--gray-200)
</style>
