<template>
  <div
    class="text-center pb-4 px-4 min-h-[var(--ld-viewport-height)] flex flex-col bg-ld-background"
  >
    <template v-if="showRewards">
      <RatioSpacedContainer class="flex flex-col items-center flex-1">
        <RewardView
          class="w-150px h-150px"
          icon-name="diamonds"
          @animation-end="isRewardAnimationEnd = true"
        />

        <div class="font-semibold text-21px flex m-y-20px">
          <span>获得：</span>

          <template v-if="diamonds">
            <Icon
              name="diamond"
              class="w-30px h-30px mr-2px"
            ></Icon>
            <span class="text-ld-brand-500">钻石x{{ diamonds }}</span>
          </template>
        </div>
      </RatioSpacedContainer>

      <div class="h-44px">
        <Button
          v-if="isRewardAnimationEnd"
          class="w-full g-footer-width g-fade-in"
          label="继续"
          @click="emit('done')"
        ></Button>
      </div>
    </template>

    <template v-else>
      <RatioSpacedContainer class="flex-1 g-body-width">
        <Icon
          name="daily-goal"
          :class="['mx-auto mb-48px h-42px']"
        />

        <DailyTasks
          :tasks="displayTasks"
          :stats="stats"
          isEndView
          class="text-left w-full"
        ></DailyTasks>
      </RatioSpacedContainer>

      <div class="h-44px">
        <Button
          v-if="isTasksAnimationEnd"
          class="w-full g-footer-width g-fade-in"
          label="继续"
          @click="onTaskContinue"
        ></Button>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import type { DailyTaskResponse } from '@/api/learn'
import {
  RewardType,
  type Reward,
  receiveTaskReward,
  type DailyTask,
  TaskType,
} from '@/api/task'
import DailyTasks from '@/mobile/pages/Reward/DailyTasks.vue'
import RewardView from '@/components/RewardView.vue'
import { onMounted } from 'vue'
import { ref, computed } from 'vue'
import { cloneDeep } from 'lodash-es'
import { useCommonStore } from '@/stores'

const store = useCommonStore()

const props = defineProps<{
  response: DailyTaskResponse
}>()

const emit = defineEmits<{
  done: []
}>()

const isTasksAnimationEnd = ref(false)
const isRewardAnimationEnd = ref(false)
const showRewards = ref(false)
const rewards = ref<Reward[]>([])
const stats = ref(cloneDeep(props.response.lastStats))
const claimedTask = ref<Set<number>>(new Set())

const displayTasks = computed(() => {
  return props.response.tasks.filter(item => !item.rewardClaimed)
})

const diamonds = computed(() => {
  const diamondReward = rewards.value.find(
    item => item.type === RewardType.DIAMOND
  )
  return diamondReward?.value ?? 0
})

const DELAY = 800
function updateTaskStats() {
  let updated = 0

  for (let i = 0; i < displayTasks.value.length; i++) {
    const task = displayTasks.value[i]

    // 如果进度有更新，则延迟后更新
    if (stats.value[task.goalType] !== props.response.stats[task.goalType]) {
      setTimeout(() => {
        stats.value[task.goalType] = props.response.stats[task.goalType]
      }, DELAY * updated)

      updated++
    }
  }

  setTimeout(
    () => {
      isTasksAnimationEnd.value = true
    },
    DELAY * updated + 400
  )
}

async function claimTask(task: DailyTask) {
  if (claimedTask.value.has(task.taskNo)) return

  return receiveTaskReward(
    {
      taskNo: task.taskNo,
      type: task.newbie ? TaskType.NEWBIE : TaskType.DAILY,
    },
    store
  ).then(res => {
    if (res.code !== 0) {
      throw res.message
    }

    claimedTask.value.add(task.taskNo)

    for (const rewardItem of task.rewards) {
      const existRewardItem = rewards.value.find(
        item => item.type === rewardItem.type
      )

      if (existRewardItem) {
        existRewardItem.value += rewardItem.value
      } else {
        rewards.value.push(rewardItem)
      }
    }
  })
}

function onTaskContinue() {
  if (rewards.value.length > 0) {
    showRewards.value = true
  } else {
    emit('done')
  }
}

onMounted(() => {
  updateTaskStats()
})
</script>
<style scoped></style>
