import {
  type Card,
  CardType,
  type ClozeCard,
  type EnWordCard,
} from '@/types/core'
import { preloadAssetImage, preloadLocalImage } from '@/utils/img'
import {
  tryJSONParse,
  loadLottie,
  bossList,
  getBossAvatar,
  getBossHalf,
} from '@/utils'
import { parseClozeCard, parseEnWordCard } from '@/utils/card'

// 角色数量
export const ROLE_COUNT = 9
// 送分角色数量
export const GIVE_AWAY_ROLE_COUNT = 3

const lottieAssets = ['flame0', 'flame1', 'lesson-end']

export async function preLoadCards(cards: string[]): Promise<number> {
  // 预加载 lottie
  const lottieLoaders: Promise<void>[] = []
  lottieAssets.forEach(name => lottieLoaders.push(loadLottie(name)))
  await Promise.all(lottieLoaders)

  const imageLoaders: Promise<void>[] = []
  // 先 load 本地资源，人物图像，怪物图像

  // boss 形象 有三种 1. boss 2. boss-avatar 3. boss-half

  const bossLoaders = bossList
    .map(boss => [
      preloadLocalImage(`${boss.name}`),
      preloadLocalImage(getBossAvatar(boss.name)),
      preloadLocalImage(getBossHalf(boss.name)),
    ])
    .flat()
  imageLoaders.push(...bossLoaders)

  const giveAwayRoleLoaders = Array.from({ length: GIVE_AWAY_ROLE_COUNT })
    .map((_, i) => i + 1)
    .map(id => `give-away-role-${id}`)
    .map(id => preloadLocalImage(id))
  imageLoaders.push(...giveAwayRoleLoaders)

  const roleLoaders = Array.from({ length: ROLE_COUNT })
    .map((_, i) => i + 1)
    .map(id => `role-${id}`)
    .map(id => preloadLocalImage(id))
  imageLoaders.push(...roleLoaders)

  // 加载卡片中的图片
  for (const card of cards) {
    const cardObject = tryJSONParse(card) as Card | null

    // TODO: 目前只处理知识点卡片中的图片
    if (cardObject?.type === CardType.CLOZE) {
      const parsedClozeCard = parseClozeCard(cardObject)
      if (parsedClozeCard.error == null) {
        imageLoaders.push(...loadParsedClozeCardImages(parsedClozeCard.card))
      }
    }

    if (cardObject?.type === CardType.EN_WORD) {
      const parsedWordCard = parseEnWordCard(cardObject)
      if (parsedWordCard.error == null) {
        imageLoaders.push(...loadParsedWordCardImages(parsedWordCard.card))
      }
    }
  }

  await Promise.all(imageLoaders)

  return imageLoaders.length
}

function loadParsedClozeCardImages(card: ClozeCard): Promise<void>[] {
  const loaders: Promise<void>[] = []
  if (card.illustration) {
    loaders.push(...loadAssets([card.illustration]))
  }

  return loaders
}

function loadParsedWordCardImages(card: EnWordCard): Promise<void>[] {
  const loaders: Promise<void>[] = []

  if (card.illustration) {
    loaders.push(...loadAssets([card.illustration]))
  }

  return loaders
}

function loadAssets(assetIds: string[]): Promise<void>[] {
  const loaders = []

  for (const assetId of assetIds) {
    loaders.push(preloadAssetImage(assetId))
  }

  return loaders
}
