import { UnitStatus, reportUnitEvents } from '@/api/learn'
import { computed, reactive } from 'vue'

import type { DailyTaskResponse, UnitCompleteStat } from '@/api/learn'
import { LearnStatus } from '@/types/core'
import bus, { BusEvent } from '@/bus/bus'
import {
  checkInStats,
  type CheckInCtRes,
  type CheckInStatsItem,
} from '@/api/user'
import { formatDate } from '@/utils'

const state = reactive({
  cardReports: {} as Record<number, Promise<void> | null>,
  completeReport: null as Promise<void> | null,
  completedStats: null as UnitCompleteStat | null,
  dailyTaskResponse: null as DailyTaskResponse | null,
  checkInData: null as {
    checkInCtRes: CheckInCtRes
    stats: CheckInStatsItem[]
  } | null,
})

export const completedStats = computed(() => state.completedStats)
export const dailyTaskResponse = computed(() => state.dailyTaskResponse)
export const checkInData = computed(() => state.checkInData)

export const pending = computed(
  () =>
    state.completeReport != null ||
    Object.values(state.cardReports).some(item => item != null)
)

bus.on(BusEvent.StageUnitClear, function clearState() {
  state.cardReports = {}
  state.completeReport = null
  state.completedStats = null
  state.dailyTaskResponse = null
  state.checkInData = null
})

export async function reportCardEvents(cardId: number) {
  if (_store.stageUnit == null) return null

  if (_store.stageUnit.local) return null

  // 如果之前的上传还未结束，再次调用时不管，防止重复调用上传同一个卡片的学习数据
  if (state.cardReports[cardId]) {
    return
  }

  // TODO: 增加自动重试机制
  state.cardReports[cardId] = reportUnitEvents({
    unitId: _store.stageUnit.unitId,
    learnEvents: {
      [cardId]: _store.stageUnit.events[cardId],
    },
    cardDurations: _store.stageUnit.cardDurations,
  })
    .then(() => {
      _store.clearCardEvents(cardId)
    })
    .finally(() => {
      state.cardReports[cardId] = null
    })
}

export async function completeReport(leftBlood?: number) {
  if (_store.stageUnit == null) return null

  // 兼容本地处理
  if (_store.stageUnit.local) {
    const schedules = _store.stageUnit.schedules
    state.completedStats = {
      unitId: 0,
      debutCount: schedules.filter(
        item => item.cardStatus === LearnStatus.DEBUT
      ).length,
      reviewCount: schedules.filter(
        item => item.cardStatus === LearnStatus.REVIEW
      ).length,
      duration: 0,
      exp: 20,
      star: 3,
      checkInPopped: true,
    }
    _store.completeStageUnit()
  }

  // 当调用完成时的上传，需要检测所有的卡片数据是否已经上传完成
  await Promise.allSettled(Object.values(state.cardReports))

  if (state.completeReport) return

  if (state.completedStats) return

  state.completeReport = reportUnitEvents({
    unitId: _store.stageUnit.unitId,
    status: UnitStatus.COMPLETED,
    learnEvents: _store.stageUnit.events,
    cardDurations: _store.stageUnit.cardDurations,
    challengeStageParam:
      leftBlood == null
        ? undefined
        : {
            duelParam: {
              leftBlood,
            },
          },
  })
    .then(res => {
      const reportedCardIds = Object.keys(_store.stageUnit!.events).map(Number)

      reportedCardIds.forEach(_store.clearCardEvents)
      state.completedStats = res.stats
      state.dailyTaskResponse = res.dailyTaskResponse
      if (res.stats != null) {
        getCheckData()
        // 上报完成后，重新获取用户资产
        _store.fetchUserAssets()
        _store.completeStageUnit()
        _store.fetchEnergyStatus()
      }
    })
    .finally(() => {
      state.completeReport = null
    })
}

export async function cancelReport() {
  if (_store.stageUnit == null) return

  if (_store.stageUnit.local) {
    _store.clearStageUnit()
    return
  }

  const events = _store.stageUnit.events
  // TODO(buding): 这里之前只上传答错的事件，会导致只有答对的事件时退出会返还面包
  // const wrongEvents: Record<string, UnitEvent[]> = {}

  // for (const cardId in events) {
  //   if (events[cardId].some(evt => evt.event === 'WRONG')) {
  //     wrongEvents[cardId] = events[cardId]
  //   }
  // }

  _store.cancelStageUnit()
  await reportUnitEvents({
    unitId: _store.stageUnit.unitId,
    status: UnitStatus.CANCELLED,
    learnEvents: events,
    cardDurations: {},
  })
  _store.fetchEnergyStatus()
}

// 上报完成后 获取签到数据
export async function getCheckData() {
  const checkInPopped = state.completedStats?.checkInPopped

  if (checkInPopped) return

  const checkInCtRes = await _store.fetchCheckInCtRes()
  // 本周签到数据

  const now = new Date()
  const week = now.getDay()
  // 本周第一天
  const currentWeekFirstDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - (week == 0 ? 7 : week) + 1
  )

  // 本周最后一天
  const currentMonthLastDay = new Date(currentWeekFirstDay)
  currentMonthLastDay.setDate(currentWeekFirstDay.getDate() + 6)
  const stats = (
    await checkInStats(
      formatDate(currentWeekFirstDay, 'YYYY-MM-DD'),
      formatDate(currentMonthLastDay, 'YYYY-MM-DD')
    )
  ).stats
  state.checkInData = {
    checkInCtRes,
    stats,
  }
}
