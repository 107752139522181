<template>
  <div class="h-full flex-1 flex flex-col bg-ld-background overflow-hidden">
    <div class="header">
      <div
        v-if="!isPcMode"
        class="w-20px relative cursor-pointer"
        @click="onClickMessage"
      >
        <Badge
          class="top--8px left-10px"
          :count="store.unreadMsgCount"
        />
        <Icon
          name="remind"
          class="w-full h-full"
        />
      </div>
      <div v-else></div>
      <Icon
        name="setting"
        class="w-20px cursor-pointer"
        @click="onSetting"
      />
    </div>

    <div class="flex-1 overflow-y-auto relative flex justify-center">
      <Icon
        name="arch"
        preserveAspectRatio="none"
        class="absolute top-0 w-full h-120px text-ld-brand-100"
      ></Icon>
      <div class="absolute w-full max-w-632px px-4 py-2">
        <div class="vstack items-center mb-6 gap-3">
          <Avatar
            :avatar="user.avatar"
            class="w-90px h-90px"
          />

          <div class="text-21px leading-21px font-medium">
            {{ user.nickname }}
          </div>
          <div
            class="text-15px leading-15px font-medium text-[var(--text-color-secondary)]"
          >
            {{ $f.date(user.createdAt, 'YYYY 年 MM 月 DD 日') }}{{ _t('加入') }}
          </div>
        </div>

        <div class="title">
          {{ _t('数据概览') }}
        </div>

        <div class="mt-3 grid grid-cols-2 gap-3">
          <div
            v-for="item in items"
            :key="item.title"
            class="stats-item"
          >
            <div
              class="flex gap-1 items-center"
              :style="{ color: `var(--${item.color}-500)` }"
            >
              <Icon
                :name="item.icon"
                class="w-24px h-24px"
              />
              <span class="text-16px font-semibold">
                {{ item.title }}
              </span>
            </div>
            <div class="flex items-baseline gap-1">
              <span class="font-[DIN] text-28px leading-none font-bold">
                {{ item.value }}
              </span>
              <span
                v-if="item.unit"
                class="text-12px"
              >
                {{ item.unit }}
              </span>
            </div>
          </div>
        </div>

        <div class="title">
          {{ _t('灵鸭支持') }}
        </div>
        <div class="mt-3">
          <ListCell
            title="🛠 问题反馈"
            @click="onHelpLinkOpen"
          ></ListCell>
        </div>

        <div class="title">
          {{ _t('关注小灵鸭') }}
        </div>
        <div class="mt-3">
          <ListCell
            title="小红书"
            img="xhs"
            @click="onXhsLinkOpen"
          ></ListCell>
          <ListCell
            title="哔哩哔哩"
            img="bilibili"
            @click="onBiliLinkOpen"
          ></ListCell>
        </div>

        <template v-if="!_global.isProd">
          <div class="title flex">
            {{ _t('测试') }}
          </div>

          <div class="mt-3">
            <ListCell
              title="DebugPanel"
              debug
              @click="onDebugPanelShow"
            ></ListCell>
          </div>
        </template>

        <div class="flex flex-col gap-1 justify-center items-center py-2 mt-6">
          <Icon
            name="logo"
            class="w-32px"
          />

          <div class="text-ld-brand-500 font-600 text-15px">
            小灵鸭 LingDuck
          </div>

          <div class="text-ld-label-secondary text-13px">
            {{ _t('闯关玩游戏，学习零压力') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useCommonStore } from '@/stores'

import Icon from '@/components/Icon.vue'
import ListCell from '@/components/ListCell/ListCell.vue'

import { useRouter } from 'vue-router'

import Badge from '@/components/Badge.vue'
import { StatsIcons } from '@/shared/icons'
import UserSetting from '@/components/UserSetting/UserSetting.vue'

import { openHelpLink } from '@/utils'

const store = useCommonStore()
const router = useRouter()
const user = store.user!

const isPcMode = _global.isPcMode

interface StatsItem {
  icon: string
  color: string
  title: string
  value: string
  unit?: string
}
const items = computed<StatsItem[]>(() => [
  {
    icon: StatsIcons.stageCount.icon,
    color: StatsIcons.stageCount.color,
    title: StatsIcons.stageCount.label,
    value: store.statsOverview?.stageCount?.toString() ?? '',
  },
  {
    icon: StatsIcons.exp.icon,
    color: StatsIcons.exp.color,
    title: StatsIcons.exp.label,
    value: store.statsOverview?.exp?.toString() ?? '',
  },
  {
    icon: StatsIcons.accumulatedCheckInDays.icon,
    color: StatsIcons.accumulatedCheckInDays.color,
    title: StatsIcons.accumulatedCheckInDays.label,
    value: store.statsOverview?.accumulatedCheckInDays?.toString() ?? '',
    unit: _t('天'),
  },
  {
    icon: StatsIcons.maxCheckInCtDays.icon,
    color: StatsIcons.maxCheckInCtDays.color,
    title: StatsIcons.maxCheckInCtDays.label,
    value: store.statsOverview?.maxCheckInCtDays?.toString() ?? '',
    unit: _t('天'),
  },
])

function onDebugPanelShow() {
  _openDebugPanel()
}

function onHelpLinkOpen() {
  openHelpLink(store.user, store.vip)
}

function onBiliLinkOpen() {
  window.open(_global.bilibiliLink)
}

function onXhsLinkOpen() {
  window.open(_global.xhsLink)
}

function onSetting() {
  if (isPcMode) {
    _openDialog(UserSetting, {
      title: _t('设置'),
      rootClass: 'min-w-600px min-h-600px bg-ld-background',
      dialog: {
        pt: {
          content: {
            class: 'flex-1',
          },
        },
      },
    })
  } else {
    router.push({ name: 'user-setting' })
  }
}

function onClickMessage() {
  router.push({
    name: 'message',
  })
}
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--ld-brand-100);
}

.vip {
  display: flex;
  color: var(--ld-label-white);
  position: relative;
  background: var(--slate-800);
  overflow: hidden;
  cursor: pointer;
}

.vip-bg {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    315deg,
    rgba(252, 85, 255, 0.4) 0%,
    rgba(38, 138, 255, 0.4) 48%,
    rgba(38, 246, 99, 0.3) 100%
  );
}

.vip.unavaiable {
  padding: 20px 16px;
  border-radius: 16px;
}

.vip.avaiable {
  padding: 12px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.vip-btn {
  color: var(--primary-color);
  padding: 6px 16px;
  border-radius: 16px;
  background-color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 0px 0px var(--slate-300);
}

.title {
  font-size: 15px;
  line-height: 22px;
  color: var(--text-color-secondary);
  margin-top: 24px;
  font-weight: 600;
}

.stats-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0px 2px 8px 0px var(--slate-300);
  background-color: white;
  border-radius: 12px;
  color: var(--ld-label-primay);
  gap: 12px;
}
</style>
