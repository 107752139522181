<template>
  <RatioSpacedContainer v-if="isPackageEmpty">
    <Empty
      :text="_t('这个卡包还是空的')"
      :desc="isOwner ? _t('点击右上角按钮，可新建卡片') : ''"
    >
      <template #icon>
        <Icon
          name="empty-package"
          class="h-120px"
        />
      </template>
    </Empty>
  </RatioSpacedContainer>

  <RatioSpacedContainer v-else-if="isChapterEmpty">
    <Empty
      :text="$t('package.no_card')"
      :desc="isOwner ? _t('点击右上角按钮，新建卡片') : ''"
    >
      <template #icon>
        <Icon
          name="empty-package"
          class="h-120px"
        />
      </template>
    </Empty>
  </RatioSpacedContainer>

  <div
    v-else
    class="p-4"
  >
    <div
      v-if="showTitle"
      class="my-2 text-13px text-[var(--text-color-secondary)]"
    >
      章节
    </div>

    <div class="grid-chapter">
      <div
        v-for="chapter in chapters"
        :key="chapter.id"
        class="p-4 rounded-lg space-y-1 bg-slate-100 chapter"
        @click="onChapterClick(chapter)"
      >
        <div class="text-17px line-clamp-1">{{ chapter.title }}</div>
        <div class="text-15px text-gray">
          {{ allChapterCountMap[chapter.id] + ' ' + _t('张卡片') }}
        </div>
      </div>
    </div>

    <div
      v-if="showTitle"
      class="my-2 text-13px text-[var(--text-color-secondary)]"
    >
      卡片
    </div>

    <div class="flex flex-col">
      <template
        v-for="(item, index) in cards"
        :key="item.id"
      >
        <slot
          name="card"
          :cardRes="item"
          :noteIndex="index"
        >
          <CardBrowserPad
            :cardResponse="item"
            class="overflow-hidden"
            :class="{ 'mt-3': index !== 0 }"
            @click="onCardClick(item)"
          ></CardBrowserPad>
        </slot>
      </template>
    </div>

    <div
      v-if="isOwner"
      class="flex flex-col justify-center items-center mt-3 w-full gap-3"
    >
      <div class="flex justify-center items-center w-full h-60px gap-4">
        <div
          class="flex justify-center items-center rounded-lg bg-white text-ld-brand-500 border-ld-border border-1px cursor-pointer gap-10px ld-shadow flex-1 h-full whitespace-nowrap font-600"
          @click="onCreateCard"
        >
          <Icon name="add_card" />
          {{ _t('新建卡片') }}
        </div>

        <Button
          class="flex-1 h-full"
          @click="onAICardGenerate"
        >
          <div
            class="flex flex-col justify-center items-center w-full gap-1 whitespace-nowrap text-center font-600"
          >
            <div class="flex gap-1">
              <Icon
                name="ai-add-card"
                class="h-20px"
              />
              <div class="text-17px leading-20px">{{ _t('AI 智能建卡') }}</div>
            </div>
            <div class="text-13px">{{ _t('内测期间 限时免费') }}</div>
          </div>
        </Button>
      </div>

      <div
        v-if="!_global.isPcMode"
        class="text-15px text-ld-label-secondary text-center leading-[1.4] font-600"
      >
        {{ _t('欢迎使用小灵鸭网页版：') }}
        <span class="text-ld-brand-500">
          {{ _global.pcLink }}
        </span>

        <br />
        {{ _t('电脑编辑，效率更高哦') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  type Package,
  type CardResponse,
  type ChapterItem,
} from '@/api/package-source'

import Empty from '@/components/Empty.vue'
import Icon from '@/components/Icon.vue'
import CardBrowserPad from '@/components/Package/CardBrowserPad.vue'

import {
  getAllChapterTotalCardCount,
  getChapterChildren,
} from '@/utils/package'

import { computed } from 'vue'

const props = defineProps<{
  chapterId: string
  package: Package
  cards: CardResponse[]
}>()

const emit = defineEmits<{
  chapterClick: [ChapterItem]
  cardClick: [CardResponse]
  createCard: []
  aiGenerate: []
}>()

const chapters = computed(() => {
  if (props.package)
    return getChapterChildren(props.package.chapters, props.chapterId)
  return []
})
const allChapterCountMap = computed(() => {
  if (props.package) return getAllChapterTotalCardCount(props.package)
  return {}
})

const isOwner = computed(() => props.package?.owned != null)

// package 是空的
const isPackageEmpty = computed(() => {
  return (
    Object.values(props.package.chapters).length === 0 &&
    props.cards.length === 0
  )
})

// 当前章节是空的 (没有卡片也没有子章节)
const isChapterEmpty = computed(() => {
  return chapters.value.length === 0 && props.cards.length === 0
})

// 显示章节title
const showTitle = computed(() => {
  if (chapters.value.length === 0) {
    return false
  }
  if (props.cards.length === 0 && !isOwner.value) {
    return false
  }
  return true
})

function onChapterClick(chapter: ChapterItem) {
  emit('chapterClick', chapter)
}

function onCardClick(card: CardResponse) {
  emit('cardClick', card)
}

function onCreateCard() {
  emit('createCard')
}

function onAICardGenerate() {
  emit('aiGenerate')
}
</script>

<style scoped>
.grid-chapter {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.chapter {
  box-shadow: 0px 0px 4px 0px var(--slate-300) inset;
}
</style>
