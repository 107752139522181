import { Mark, mergeAttributes } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cloze: {
      setCloze: () => ReturnType
      toggleCloze: () => ReturnType
      unsetCloze: () => ReturnType
    }
  }
}
const Cloze = Mark.create({
  name: 'cloze',

  // 用于解决初始化时两个连续的挖空只会渲染一次
  // 增加该属性后，连续的挖空不会自动合并
  spanning: false,

  exitable: true,

  // 不能和 textStyle 同时存在，后续可以开放
  excludes: 'textStyle',

  parseHTML() {
    return [
      {
        tag: 'span[data-cloze]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-cloze': true, class: 'g-cloze' }, HTMLAttributes),
      0,
    ]
  },

  addAttributes: () => {
    return {
      group: {
        default: undefined,
      },
      distrators: {
        default: undefined,
      },
      giveAwayDistrators: {
        default: undefined,
      },
    }
  },

  addCommands() {
    return {
      setCloze:
        () =>
        ({ chain }) => {
          return chain().unsetMark('textStyle').setMark(this.name).run()
        },
      toggleCloze:
        () =>
        ({ chain }) => {
          return chain().unsetMark('textStyle').toggleMark(this.name).run()
        },
      unsetCloze:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-d': () => this.editor.commands.toggleCloze(),
      'Mod-D': () => this.editor.commands.toggleCloze(),
      'Ctrl-d': () => this.editor.commands.toggleCloze(),
      'Ctrl-D': () => this.editor.commands.toggleCloze(),
    }
  },
})

export default Cloze
