import { ROOT_CHAPTER_KEY } from '@/types/core'
import { difference } from 'lodash-es'

import {
  PackageColor,
  ShareStatus,
  type ChapterItem,
  type Chapters,
  type Package,
  type PackageBasic,
  type PackageIconEmoji,
  type PackageIconImage,
} from '@/api/package-source'
import type { TreeNode } from '@/components/Tree/Tree.vue'
import emojiTable from '@/components/PkgList/emoji.json'
import { randomPick } from '.'

export function getChapterChildren(chapters: Chapters, parentId?: string) {
  return Object.values(chapters)
    .filter(c => {
      if (parentId == null || parentId === ROOT_CHAPTER_KEY) {
        return c.parentId == null
      }

      return parentId === String(c.parentId)
    })
    .sort((a, b) => a.orderKey - b.orderKey)
}

export function getChapterAncestors(chapters: Chapters, id: string | number) {
  const ancestors: (string | number)[] = [id]

  const chapterList = Object.values(chapters)

  let current = chapterList.find(c => c.id === Number(id))

  while (current != null) {
    current = chapterList.find(c => c.id === current?.parentId)

    if (current) {
      ancestors.unshift(current.id)
    }
  }

  if (id !== ROOT_CHAPTER_KEY) {
    ancestors.unshift(ROOT_CHAPTER_KEY)
  }

  return ancestors
}

export function genChapterTree(
  rootLabel: string,
  chapters: Chapters
): TreeNode {
  const root: TreeNode = {
    key: ROOT_CHAPTER_KEY,
    label: rootLabel,
    root: true,
    children: [],
  }

  const m = new Map()

  const genNode = (chapter: ChapterItem): TreeNode => {
    return {
      key: String(chapter.id),
      label: chapter.title,
      children: [],
    }
  }

  const sortNodes = (nodes: TreeNode[]) => {
    nodes.sort((a, b) => {
      const chapterA = chapters[a.key]
      const chapterB = chapters[b.key]

      return chapterA.orderKey - chapterB.orderKey
    })
  }

  for (const chapterId in chapters) {
    const chapter = chapters[chapterId]

    let node = m.get(chapterId)

    if (!node) {
      node = genNode(chapter)
      m.set(chapterId, node)
    }

    const parentId = chapters[chapterId].parentId?.toString()

    if (parentId) {
      let parentNode = m.get(parentId)

      if (!parentNode) {
        const parentChapter = chapters[parentId]
        parentNode = genNode(parentChapter)

        m.set(parentId, parentNode)
      }

      parentNode.children.push(node)
      sortNodes(parentNode.children)
    } else {
      root.children!.push(node)
      sortNodes(root.children!)
    }
  }

  return root
}

export function getAllChapterTotalCardCount(pkg: {
  chapterCardsCount: Package['chapterCardsCount']
  chapters: Package['chapters']
}) {
  if (pkg == null) return {}

  const totalCountMap: Record<string, number> = {}
  const tree = genChapterTree(ROOT_CHAPTER_KEY, pkg.chapters)

  function countTreeNode(node: TreeNode): number {
    const count = pkg.chapterCardsCount[node.key] ?? 0
    const totalCount =
      count +
      (node.children?.map(countTreeNode).reduce((acc, cur) => acc + cur, 0) ??
        0)

    totalCountMap[node.key] = totalCount

    return totalCount
  }

  countTreeNode(tree)

  return totalCountMap
}

export function isChapterExists(pkg: Package, chapterId: string) {
  return chapterId === ROOT_CHAPTER_KEY || pkg.chapters[chapterId] != null
}

interface DiffablePackage {
  chapters: Package['chapters']
  chapterCardIds: Package['chapterCardIds']
  cardsContentHash: Package['cardsContentHash']
}

// 返回的结果是 a 相对于 b 的
export function diffPackage(a: DiffablePackage, b: DiffablePackage) {
  const result = {
    cardAdd: 0,
    cardUpdate: 0,
    cardDelete: 0,
    chapterAdd: 0,
    chapterUpdate: 0,
    chapterDelete: 0,
  }

  const aAllCardIds: number[] = a.chapterCardIds[ROOT_CHAPTER_KEY] ?? []
  const bAllCardIds: number[] = b.chapterCardIds[ROOT_CHAPTER_KEY] ?? []

  const allChapterIds = new Set([
    ...Object.keys(a.chapters),
    ...Object.keys(b.chapters),
    ROOT_CHAPTER_KEY,
  ])

  for (const chapterId of allChapterIds) {
    const aChapter = a.chapters[chapterId]
    const bChapter = b.chapters[chapterId]

    const aCardIds = a.chapterCardIds[chapterId] ?? []
    const bCardIds = b.chapterCardIds[chapterId] ?? []

    if (aChapter && bChapter) {
      aAllCardIds.push(...aCardIds)
      bAllCardIds.push(...bCardIds)
      if (
        aChapter.title !== bChapter.title ||
        aChapter.parentId !== bChapter.parentId
      ) {
        result.chapterUpdate++
      }
    } else if (!bChapter && aChapter) {
      aAllCardIds.push(...aCardIds)

      result.chapterAdd++
    } else if (!aChapter && bChapter) {
      bAllCardIds.push(...bCardIds)

      result.chapterDelete++
    }
  }

  const addedCardIds = difference(aAllCardIds, bAllCardIds)
  const deletedCardIds = difference(bAllCardIds, aAllCardIds)

  result.cardAdd += addedCardIds.length
  result.cardDelete += deletedCardIds.length

  const bothAllCardIds = new Set([...aAllCardIds, ...bAllCardIds])

  for (const id of bothAllCardIds) {
    const hashId = a.cardsContentHash[id]
    const snapshotHashId = b.cardsContentHash[id]

    if (hashId != null && snapshotHashId != null && hashId !== snapshotHashId) {
      result.cardUpdate++
    }
  }

  return result
}

export function getPackageShowDate(pkg: PackageBasic): number {
  if (pkg.owned) {
    return new Date(pkg.createdAt).getTime()
  } else if (pkg.obtained) {
    return new Date(pkg.obtained.obtainedAt).getTime()
  } else if (pkg.shelf) {
    return new Date(pkg.shelf.addedShelfAt).getTime()
  }

  return 0
}

export const PKG_ICONS = [
  'atlas-pkg-5',
  'atlas-pkg-3',
  'atlas-pkg-8',
  'atlas-pkg-10',
  'atlas-pkg-7',
  'atlas-pkg-6',
  'atlas-pkg-1',
  'atlas-pkg-4',
  'atlas-pkg-9',
  'atlas-pkg-2',
  ...Array.from({ length: 58 }).map((_, i) => {
    return `pkg-icon-${i + 1}`
  }),
]

export function genRandomIcon(): PackageIconEmoji | PackageIconImage {
  const allIcons: (PackageIconEmoji | PackageIconImage)[] = []

  for (const item of emojiTable) {
    item.emojis.forEach(emoji => {
      allIcons.push({
        type: 'emoji',
        content: emoji,
      })
    })
  }

  for (const imgIcon of PKG_ICONS) {
    allIcons.push({
      type: 'img',
      content: imgIcon,
    })
  }

  return randomPick(allIcons)
}

export function genRandomColor(): PackageColor {
  const allColors = Object.values(PackageColor)

  return randomPick(allColors)
}

// 卡包有未发布的更新
export function isPackageUpdateUnreleased(pkg: PackageBasic): boolean {
  // (已开启共享) && (最近编辑时间 > 最近快照时间) 显示 【有修改】
  // 已开启共享
  const shared = pkg.owned?.shareStatus === ShareStatus.COMMUNITY

  if (!shared) {
    return false
  }

  // 最近编辑时间
  const updatedAt = pkg.updatedAt
  // 最近快照时间
  const lastSnapshotAt = pkg.lastSnapshotAt

  if (!lastSnapshotAt) {
    return false
  }
  return updatedAt != lastSnapshotAt
}
