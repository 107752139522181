<template>
  <div class="vstack px-4 min-h-full g-safe-area">
    <RatioSpacedContainer
      class="flex-1"
      :top="4"
      :bottom="6"
    >
      <Img
        name="ld-greet"
        class="w-107px"
      />

      <div class="my-4 flex flex-col">
        <div
          id="text"
          class="text-24px"
        ></div>
      </div>
    </RatioSpacedContainer>

    <div class="mb-50px w-full">
      <Button
        v-if="_global.isInsideWechat"
        class="mt-auto w-full h-56px"
        label="Wechat"
        scene="wechat"
        @click="onWechatLogin"
      >
        <div class="flex justify-center items-center w-full">
          <Icon
            name="wechat-pure"
            class="h-28px"
          />
          <span class="ml-1 text-lg">{{ _t('微信登录') }}</span>
        </div>
      </Button>

      <Button
        v-else
        class="mt-auto w-full h-56px justify-center"
        :label="_t('马上开始')"
        @click="onLogin"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { wechatLogin } from '@/utils/wechat'
import { onBeforeMount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Typewriter from 'typewriter-effect/dist/core'

const store = useCommonStore()
const router = useRouter()
const route = useRoute()

onMounted(() => {
  const typewriter = new Typewriter('#text', {
    delay: 100,
  })

  typewriter
    .typeString('闯关玩游戏，')
    .pauseFor(200)
    .typeString('学习零压力')
    .pauseFor(1000)
    .start()
})

onBeforeMount(() => {
  if (store.isLoggedIn) {
    if (route.query.redirect) {
      const redirect = decodeURIComponent(route.query.redirect as string)

      router.replace(redirect)
    } else {
      router.replace({
        name: 'atlas',
      })
    }
  }
})

function onLogin() {
  router.push({
    name: 'auth',
    query: {
      from: route.query.redirect || undefined,
    },
  })
}

function onWechatLogin() {
  wechatLogin()
}
</script>
<style scoped></style>
