<template>
  <div
    :class="[
      'h-full bg-ld-background',
      {
        'big-screen': viewSize.width > 1000,
      },
    ]"
  >
    <Loading
      v-if="loading"
      class="h-full"
    />

    <div
      v-else
      :class="[
        'h-full main overflow-hidden',
        {
          'px-6 pt-6': _global.isPcMode,
        },
      ]"
    >
      <div class="w-full h-full flex flex-col max-w-750px flex-1 mx-auto">
        <Header class="left-header mb-1" />

        <div
          class="region-header relative"
          :style="viewRegion != null ? getRegionStyle(viewRegion) : ''"
          :class="{
            'g-premium-bg': viewRegion?.received,
          }"
        >
          <template v-if="viewRegion">
            <span
              class="text-21px font-semibold text-white"
              :class="{ 'text-yellow-800': viewRegion.received }"
            >
              {{ regionTitle(viewRegion) }}
            </span>

            <OverlayPanel ref="rewardOverlay">
              <div class="w-240px">
                <div class="flex items-center">
                  <Icon
                    name="light-star"
                    class="w-36px"
                  />
                  <ProgressBar
                    :percent="getRegionPercent(viewRegion)"
                    color="linear-gradient(92.6deg, var(--amber-100) -16.25%, var(--amber-400) 142.19%)"
                    :text="`${getRegionStar(viewRegion)}/${getRegionTotalStar(
                      viewRegion
                    )}`"
                  />
                </div>
                <div class="text-17px">
                  通过
                  <span class="font-bold">挑战关卡</span>
                  收集 ⭐
                </div>
                <div class="text-17px">收集满全部 ⭐️ 后可领取奖励</div>
              </div>
            </OverlayPanel>

            <template v-if="showRegionRewardButton()">
              <RewardButton
                v-if="!viewRegion.received"
                class="ml-auto"
                :star="getRegionStar(viewRegion)"
                :maxStar="getRegionTotalStar(viewRegion)"
                @click="onRegionRewardReceive"
              />

              <Icon
                v-else
                name="cup-gold"
                class="ml-auto w-38px h-38px"
              />
            </template>
          </template>

          <template v-if="viewRegion?.received">
            <LottieBox
              name="shine"
              class="absolute left-0 aspect-square h-full pointer-events-none"
              autoplay
              loop
            />
            <LottieBox
              name="shine"
              class="absolute right-0 aspect-square h-full pointer-events-none"
              autoplay
              loop
            />
          </template>
        </div>

        <div class="relative flex-1 flex flex-col overflow-hidden">
          <div
            ref="regionsContainer"
            class="regions-container"
            @scroll="onScroll"
          >
            <div class="unlock-region">
              <template v-if="currentRegion">
                <div class="text-21px font-bold">
                  {{ _t(`区域 ${currentRegion.index + 2}`) }}
                </div>

                <div class="text-15px my-1">
                  {{ _t(`通关第 ${currentRegionLastStage?.level} 关后解锁`) }}
                </div>

                <Icon
                  name="region-lock"
                  class="w-79px"
                />
              </template>
            </div>

            <div class="flex flex-col-reverse">
              <div
                class="flex flex-col items-center justify-center relative h-223px"
              >
                <div class="absolute w-full bottom-0 top-64px">
                  <Icon
                    name="atlas-footer-bg"
                    class="text-ld-brand-100 w-full h-full"
                  />
                </div>
                <LottieBox
                  name="lets-start"
                  loop
                  autoplay
                  class="w-72px"
                />
                <div
                  class="text-ld-brand-500 z-1 mt-2 mb-3 text-17px leading-none"
                >
                  {{ _t('从这里出发') }}
                </div>
                <Icon
                  name="atlas-footer-img"
                  class="w-306px z-1"
                />
              </div>

              <div
                v-for="region in regions"
                :id="`region-${region.index}`"
                :key="`region-${region.index}`"
                class="relative"
              >
                <Divider
                  align="center"
                  class="region-title"
                >
                  {{ regionTitle(region) }}
                </Divider>

                <div class="region-stages relative">
                  <div
                    v-for="stage in region.stages"
                    :key="stage.level"
                    :class="[
                      'h-70px flex items-center',
                      `level-${((stage.level - 1) % 10) + 1}`,
                    ]"
                  >
                    <div
                      v-if="stage.completed"
                      class="cursor-pointer relative"
                      :class="getStageClass(region, stage)"
                      :style="getStageStyle(region, stage)"
                      @click="onCompletedStageClick(stage)"
                    >
                      <PkgIcon
                        v-if="stage.atlasStage!.pkgInfo"
                        :style="stage.atlasStage!.pkgInfo.style"
                        class="w-38px h-38px text-32px"
                      />

                      <div class="id">{{ stage.level }}</div>

                      <LottieBox
                        v-if="region.received"
                        name="shine"
                        class="absolute absolute-center h-full aspect-square pointer-events-none"
                        autoplay
                        loop
                      />
                    </div>

                    <div
                      v-else
                      class="cursor-pointer relative"
                      :class="getStageClass(region, stage)"
                      :style="getStageStyle(region, stage)"
                      @click="onStageClick(stage)"
                    >
                      <div
                        v-if="stage.active"
                        class="anchor"
                      >
                        <svg
                          :style="getStageAnchorStyle(region)"
                          class="anchor-icon"
                          width="53"
                          height="70"
                          viewBox="0 0 53 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M26.25 70C26.25 70 52.5 49 52.5 26.25C52.5 11.7525 40.7475 0 26.25 0C11.7525 0 0 11.7525 0 26.25C0 49 26.25 70 26.25 70Z"
                          />
                        </svg>

                        <Avatar
                          v-if="store.user"
                          :avatar="store.user.avatar"
                          imgStyle="s"
                          class="avatar"
                        />
                      </div>

                      {{ stage.level }}

                      <LottieBox
                        v-if="region.received"
                        name="shine"
                        class="absolute aspect-square h-full absolute-center"
                        autoplay
                        loop
                      />
                    </div>
                  </div>

                  <template
                    v-for="challengeStage in region.challengeStages"
                    :key="`challenge-${challengeStage.index}-${challengeStage.star}`"
                  >
                    <div
                      :id="`challenge-stage-${challengeStage.index}`"
                      class="absolute flex flex-col items-center w-100px cursor-pointer"
                      :style="getChallengeStageStyle(challengeStage)"
                      @click="onChallengeStageClick(region, challengeStage)"
                    >
                      <div class="relative flex flex-col items-center">
                        <div class="z-1">
                          <Img
                            :name="challengeStage.npcImage"
                            :class="{
                              bounce:
                                currentBounceChallengeStageIndex ===
                                challengeStage.index,
                            }"
                          />
                        </div>
                        <div class="absolute bottom--14px">
                          <Img name="npc-shadow" />
                        </div>
                      </div>

                      <div
                        v-if="challengeStage.active"
                        class="flex items-center py-2px px-4px rounded-4px bg-neutral-900 bg-opacity-60 w-fit"
                      >
                        <Icon
                          :name="
                            challengeStage.star > 0 ? 'light-star' : 'dark-star'
                          "
                          class="w-14px"
                        />
                        <Icon
                          :name="
                            challengeStage.star > 1 ? 'light-star' : 'dark-star'
                          "
                          class="w-14px"
                        />
                        <Icon
                          :name="
                            challengeStage.star > 2 ? 'light-star' : 'dark-star'
                          "
                          class="w-14px"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="focusBtn !== 'none'"
            class="focus-btn"
            @click="focusToActiveStage()"
          >
            <Icon
              :name="`stage-arrow-${focusBtn}`"
              class="w-28px"
            />
          </div>
        </div>
      </div>

      <div class="w-350px hidden tasks">
        <Header class="hidden tasks-header" />

        <div class="daily-tasks">
          <div class="flex items-center px-4 pt-4 justify-between mb-2">
            <div class="text-ld-text text-17px font-semibold">
              {{ _t('每日奖励') }}
            </div>
            <div
              class="text-ld-brand-500 text-15px font-semibold flex items-center cursor-pointer"
              @click="onGetMoreRewards"
            >
              {{ _t('更多奖励') }}
              <i class="pi pi-chevron-right"></i>
            </div>
          </div>

          <DailyTasks
            v-if="taskStats"
            :tasks="taskStats.daily.tasks"
            :stats="taskStats.daily.stats"
            class="task-list"
          />
        </div>
      </div>
    </div>

    <div
      v-if="showChallengeStage && selectedChallengeStage"
      class="absolute top-0 left-0 h-full w-full flex flex-col bg-white z-1 p-4"
    >
      <div class="g-header-width">
        <Icon
          name="close"
          class="w-22px"
          @click="showChallengeStage = false"
        />
      </div>

      <RatioSpacedContainer class="p-4 flex-1">
        <div class="flex gap-4">
          <Icon
            :name="
              selectedChallengeStage.star > 0
                ? 'challenge-light-star'
                : 'challenge-dark-star'
            "
            class="w-46px"
          />
          <Icon
            :name="
              selectedChallengeStage.star > 1
                ? 'challenge-light-star'
                : 'challenge-dark-star'
            "
            class="relative bottom-6 w-46px"
          />
          <Icon
            :name="
              selectedChallengeStage.star > 2
                ? 'challenge-light-star'
                : 'challenge-dark-star'
            "
            class="w-46px"
          />
        </div>

        <Img
          :name="selectedChallengeStage.npcImage"
          class="w-200px"
        />

        <div class="text-22px mt-6">
          {{ challengeMessageMap[selectedChallengeStage.star] }}
        </div>
      </RatioSpacedContainer>

      <div class="g-header-width flex flex-col items-center relative">
        <DebugLabel class="flex gap-4 absolute bottom-24 left-0">
          <DebugButton
            label="一星"
            :loading="challengeStageStartLoading"
            @click="onDebugChallengeStageFinish(selectedChallengeStage, 1)"
          ></DebugButton>
          <DebugButton
            label="二星"
            :loading="challengeStageStartLoading"
            @click="onDebugChallengeStageFinish(selectedChallengeStage, 2)"
          ></DebugButton>
          <DebugButton
            label="三星"
            :loading="challengeStageStartLoading"
            @click="onDebugChallengeStageFinish(selectedChallengeStage, 3)"
          ></DebugButton>
        </DebugLabel>

        <ExpInfo
          :exp="preChallengeStageInfo.exp"
          class="mb-2"
        />
        <Button
          class="w-full h-56px"
          @click="onChallengeStageStart(selectedChallengeStage)"
        >
          <div class="flex justify-center w-full font-bold items-center">
            <span>{{ _t('开始挑战') }}</span>

            <EnergyCount
              :count="preChallengeStageInfo.requiredEnergy"
              class="ml-2"
            />
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import PkgIcon from './PkgIcon.vue'
import PkgSelector from './PkgSelector.vue'
import { ref, nextTick, computed } from 'vue'
import Icon from '@/components/Icon.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import EnergyCount from '@/components/EnergyCount.vue'
import ExpInfo from '@/components/ExpInfo.vue'

import {
  fetchAtlasStages,
  startAtlasChallengeStage,
  type Stage as AtlasStage,
  type ChallengeStage as AtlasChallengeStage,
  fetchAtlasChallengeStages,
  fetchAtlasRegionReward,
  fetchAtlasRegionRewardStatus,
  type ChallengeStagePreInfo,
  preStartAtlasChallengeStage,
} from '@/api/atlas'
import { first, last } from 'lodash-es'
import StageReview from './StageReview.vue'
import { useRoute, useRouter } from 'vue-router'
import { Code } from '@/api/code'
import { debugFinishChallengeStage } from './debug'
import RewardButton from '@/components/RewardButton.vue'
import Header from './Header.vue'
import DailyTasks from '@/mobile/pages/Reward/DailyTasks.vue'
import { fetchTasks, RewardType, type TaskStats } from '@/api/task'
import { onActivated, onMounted, onUnmounted } from 'vue'
import { watch } from 'vue'
import bus, { BusEvent } from '@/bus/bus'
import LottieBox from '@/components/LottieBox.vue'
import RecommendDialog from '@/components/RecommendDialog.vue'
import AlphaGiftDialog from '@/components/AlphaGiftDialog.vue'

const router = useRouter()
const route = useRoute()

const viewSize = window._viewSize

const challengeMessageMap: Record<number, string> = {
  0: _t('我们来比一比吧，看看你有多厉害！'),
  1: _t('哎哟不错哦，不过还是逊我一筹，敢不敢再来一次？'),
  2: _t('哎哟不错哦，不过还是逊我一筹，敢不敢再来一次？'),
  3: _t('太厉害了，甘拜下风，欢迎你随时来找我玩。'),
}

interface Stage {
  level: number
  active: boolean
  completed: boolean
  atlasStage?: AtlasStage
}

interface ChallengeStage {
  active: boolean
  star: number

  // 1 base
  index: number
  level: number
  npcImage: string

  lastCompletedAt?: string
}

interface Region {
  // 0 base
  index: number
  color: (typeof REGION_COLORS)[number]
  stages: Stage[]
  challengeStages: ChallengeStage[]
  // 领取过奖励
  received: boolean
}

const REGION_STAGES_COUNT = 10
const REGION_CHALLENGE_STAGE_INDEXS = [3, 6, 10]
const REGION_COLORS = [
  'ld-brand',
  'cyan',
  'purple',
  'teal',
  'blue',
  'green',
] as const

const store = useCommonStore()
const regionsContainer = ref<HTMLDivElement>()
const focusBtn = ref<'up' | 'down' | 'none'>('none')
const rewardOverlay = ref()
const selectedChallengeStageRegion = ref<Region>()
const selectedChallengeStage = ref<ChallengeStage>()

const preChallengeStageInfo = ref<ChallengeStagePreInfo>({
  requiredEnergy: 0,
  exp: 0,
})

const showChallengeStage = ref(false)
const scrollTop = ref(0)

const loading = ref(false)
const stages = ref<Stage[]>([])
const regions = ref<Region[]>([])
const viewRegion = ref<Region>()
const taskStats = ref<TaskStats>()
const challengeStageStartLoading = ref(false)
const lastSelectedPackageId = ref<number>()
const currentBounceChallengeStageIndex = ref<number>()

const currentRegion = computed(() => last(regions.value))
const currentRegionLastStage = computed(() => last(currentRegion.value?.stages))

const allChallengeStages = computed(() => {
  return regions.value.reduce<ChallengeStage[]>((acc, item) => {
    return acc.concat(item.challengeStages)
  }, [])
})

onActivated(() => {
  fetchDailyTasks()
  showLastChallengeStage()
  showQueryStage()
  showRecommendList()

  regionsContainer.value?.scrollTo({ top: scrollTop.value })
})

onMounted(() => {
  bus.on(BusEvent.StageUnitComplete, fetchStages)
})
onUnmounted(() => {
  bus.off(BusEvent.StageUnitComplete, fetchStages)
})

function showAlphaGiftDialog() {
  // 如果没有领取内测福利，显示内测福利弹窗
  _openDialog(AlphaGiftDialog, {
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-4' },
      },
    },
  })
}

async function fetchStages() {
  loading.value = true
  try {
    const res = await fetchAtlasStages()
    const challengeRes = await fetchAtlasChallengeStages()

    lastSelectedPackageId.value = last(res.stages)?.pkgInfo.id

    stages.value = []
    regions.value = []

    stages.value = res.stages.map(item => {
      return {
        level: item.level,
        active: item.completedAt == null,
        completed: item.completedAt != null,
        atlasStage: item,
      }
    })

    if (!last(stages.value)?.active) {
      stages.value.push({
        level: res.stages.length + 1,
        active: true,
        completed: false,
      })
    }

    await generateRegions(stages.value, challengeRes.stages)
  } finally {
    loading.value = false

    nextTick(() => {
      showLastChallengeStage()
      focusToActiveStage(true)
      showQueryStage()
      showRecommendList()
    })
  }
}

async function fetchDailyTasks() {
  store.fetchUnreceivedTaskCount()

  const stats = await fetchTasks()
  taskStats.value = stats
}

function onGetMoreRewards() {
  router.push({ name: 'reward' })
}

function regionTitle(region: Region): string {
  const firstStage = first(region.stages)
  const lastStage = last(region.stages)

  return _t(
    `区域 ${
      region.index + 1
    }（关卡 ${firstStage?.level} - ${lastStage?.level}）`
  )
}

function getRegionStar(region: Region): number {
  return region.challengeStages.reduce((acc, item) => acc + item.star, 0)
}

function getRegionTotalStar(region: Region): number {
  return region.challengeStages.length * 3
}

function getRegionPercent(region: Region): number {
  return (getRegionStar(region) / getRegionTotalStar(region)) * 100
}
async function generateRegions(
  stages: Stage[],
  challengeStageResponses: AtlasChallengeStage[]
) {
  let regionStages: Stage[] = []
  let regionIndex = 0

  for (const stage of stages) {
    regionStages.push(stage)

    if (regionStages.length === REGION_STAGES_COUNT) {
      let received =
        (await fetchAtlasRegionRewardStatus(regionIndex + 1)).reward !== null
      regions.value.push({
        color: REGION_COLORS[regionIndex % REGION_COLORS.length],
        index: regionIndex,
        stages: regionStages,
        challengeStages: [],
        received: received,
      })
      regionIndex++
      regionStages = []
    }
  }

  if (regionStages.length > 0) {
    let received =
      (await fetchAtlasRegionRewardStatus(regionIndex + 1)).reward !== null
    regions.value.push({
      index: regionIndex,
      color: REGION_COLORS[regionIndex % REGION_COLORS.length],
      stages: regionStages,
      challengeStages: [],
      received: received,
    })
  }

  const lastRegion = last(regions.value) as Region

  lastRegion.stages.push(
    ...Array.from({
      length: REGION_STAGES_COUNT - lastRegion.stages.length,
    }).map((_, i) => {
      const startLevel = lastRegion.index * REGION_STAGES_COUNT

      return {
        level: startLevel + lastRegion.stages.length + i + 1,
        active: false,
        completed: false,
      }
    })
  )

  let index = 1
  for (const region of regions.value) {
    for (const challengeIndex of REGION_CHALLENGE_STAGE_INDEXS) {
      const preStage = region.stages[challengeIndex - 1]
      const response = challengeStageResponses.find(
        item => item.index === index
      )

      region.challengeStages.push({
        active: preStage?.completed,
        index,
        level: preStage.level,
        npcImage: `10${(((index - 1) % 12) + 1).toString().padStart(2, '0')}`,

        star: response?.star ?? 0,
        lastCompletedAt: response?.lastCompletedAt,
      })

      index++
    }
  }

  viewRegion.value = last(regions.value)
}

async function showLastChallengeStage() {
  const challengeStageIndex = route.query.challengeStage
  const challengePreStart = route.query.preStart === 'true'
  showChallengeStage.value = challengePreStart

  if (challengeStageIndex != null) {
    const index = allChallengeStages.value.findIndex(
      item => item.index === Number(challengeStageIndex)
    )

    if (index > -1) {
      selectedChallengeStage.value = allChallengeStages.value[index]

      if (challengePreStart) {
        const params = getChallengeStageParams(allChallengeStages.value[index])
        const res = await preStartAtlasChallengeStage(params)
        preChallengeStageInfo.value = res
      }
      nextTick(() => {
        focusToChallengeStage(selectedChallengeStage.value!.index)
      })

      router.replace({
        query: {
          ...route.query,
          challengeStage: undefined,
          preStart: undefined,
        },
      })
    }
  }
}

async function showQueryStage() {
  const pkgId = route.query.pkgId
  if (pkgId != null) {
    const stage = stages.value.find(item => item.active)
    if (stage) {
      onStageClick(stage, Number(pkgId))
    }

    router.replace({
      query: {
        ...route.query,
        pkgId: undefined,
      },
    })
  }
}
// 显示推荐列表
async function showRecommendList() {
  if (route.query.to === 'recommend-list') {
    router.replace({
      query: {
        ...route.query,
        to: undefined,
      },
    })

    _presentContent(RecommendDialog, {
      rootClass: 'h-680px w-480px',
      bottomSheetClass: 'h-85vh',
      props: {
        navigator: _global.isPcMode ? 'modal' : 'bottomSheet',
        onPackageChallenge(pkgId: number) {
          _closeAllDialogs()
          const stage = stages.value.find(item => item.active)
          if (stage) {
            onStageClick(stage, Number(pkgId))
          }
        },
      },
    })
  }
}

function onScroll(e: UIEvent) {
  const target = e.target as HTMLDivElement
  const containerRect = target.getBoundingClientRect()
  scrollTop.value = target.scrollTop

  for (let i = regions.value.length - 1; i >= 0; i--) {
    const region = regions.value[i]
    const el = document.querySelector(
      `#region-${region.index}`
    ) as HTMLDivElement
    if (el != null) {
      const rect = el.getBoundingClientRect()

      if (rect.bottom > containerRect.top + 66) {
        viewRegion.value = region
        break
      }
    }
  }

  onStagesScroll()
}

fetchStages()
fetchDailyTasks()

function onCompletedStageClick(stage: Stage) {
  _openDialog(StageReview, {
    rootClass: 'w-[90vw] max-w-600px p-4 bg-[var(--surface-100)]',
    props: {
      stage: stage.atlasStage,
    },
    dialog: {
      showHeader: false,
      dismissableMask: true,
      pt: {
        content: {
          class: 'p-0 bg-[unset] pb-2',
          style: 'display: block',
        },
      },
    },
  })
}

function onRegionRewardOverlayToggle(evt: any) {
  rewardOverlay.value!.toggle(evt)
}

function onRegionRewardReceive(evt: any) {
  // 如果未收集满 ⭐️
  let isFullStar =
    getRegionStar(viewRegion.value!) === getRegionTotalStar(viewRegion.value!)
  if (!isFullStar) {
    onRegionRewardOverlayToggle(evt)
  } else {
    fetchAtlasRegionReward(viewRegion.value!.index + 1).then(res => {
      if (res.code === Code.Ok) {
        viewRegion.value!.received = true
        _showRewards([{ type: RewardType.DIAMOND, value: 20 }])
      } else {
        _message.info(res.message)
      }
    })
  }
}

async function onStageClick(stage: Stage, pkgId?: number) {
  if (!stage.active) {
    _message.info('请先完成当前关卡')
    return
  }

  const selectedPkgId = await _openDialog<number>(PkgSelector, {
    rootClass: 'g-dialog px-0 pb-2 border-none',
    props: {
      stageLevel: stage.level,
      lastPkgId: pkgId ?? lastSelectedPackageId.value,
      onStageCompleted() {
        fetchStages()
        fetchDailyTasks()
      },
    },
    dialog: {
      showHeader: false,
      dismissableMask: false,
      pt: {
        content: {
          class: 'p-0 bg-[unset] pb-2',
          style: 'display: block',
        },
      },
    },
  })

  if (selectedPkgId != null) {
    lastSelectedPackageId.value = selectedPkgId
  }
}

function getStageAnchorStyle(region: Region): string {
  const styleList: string[] = []
  const color = region.received ? 'amber' : region.color

  styleList.push(`filter: drop-shadow(0px 1px 2px var(--${color}-600));`)
  styleList.push(`fill: var(--${color}-200);`)

  return styleList.join('')
}

function getStageClass(region: Region, stage: Stage): string {
  const classList = ['stage']

  if (stage.active) {
    classList.push(...['active', 'anime-button'])
  } else if (stage.completed) {
    classList.push(...['completed', 'anime-button'])
  }

  if (region.received) {
    classList.push(...['received', 'g-premium-bg'])
  }

  return classList.join(' ')
}

function getStageStyle(region: Region, stage: Stage): string {
  const styleList: string[] = []

  if (region.received) {
    styleList.push(`box-shadow: 0px 8px var(--yellow-600)`)
  } else if (stage.active || stage.completed) {
    styleList.push(`background-color: var(--${region.color}-500)`)
    styleList.push(`box-shadow: 0px 8px var(--${region.color}-600)`)
  } else {
    styleList.push(`box-shadow: 0px 8px var(--surface-400)`)
    styleList.push('background-color: var(--surface-300);')
  }

  return styleList.join(';')
}

function getRegionStyle(region: Region) {
  const styleList: string[] = []

  const color: string = region.color

  if (region.received) {
    styleList.push(`border-bottom-width: 4px`)
  } else {
    styleList.push(`background-color: var(--${color}-500)`)
    styleList.push(`border-color: var(--${color}-200)`)
    styleList.push(`box-shadow: 0px 4px var(--${color}-600)`)
  }

  return styleList.join(';')
}

function getChallengeStageStyle(challengeStage: ChallengeStage) {
  const index = challengeStage.index - 1
  const styleList: string[] = []

  switch (index % 3) {
    case 0:
      styleList.push('right: 24px; bottom: 210px;')
      break
    case 1:
      styleList.push('left: 24px; bottom: 500px;')
      // 每个区域 2 号位 boss 图片，水平翻转
      // 我们准备的图片，默认会“向左看”
      // 然后让 2 号位置的 boss 翻转“向右看”
      styleList.push('transform: scaleX(-1);')
      break
    case 2:
      styleList.push('right: 24px; bottom: 800px;')
  }

  if (!challengeStage.active) {
    styleList.push('filter: grayscale(100%);')
  }

  return styleList.join('')
}

async function onChallengeStageClick(
  region: Region,
  challengeStage: ChallengeStage
) {
  if (!challengeStage.active) {
    _message.info(`通过第 ${challengeStage.level} 关后可挑战`)
    return
  }

  const params = getChallengeStageParams(challengeStage)
  const res = await preStartAtlasChallengeStage(params)
  preChallengeStageInfo.value = res

  selectedChallengeStageRegion.value = region
  selectedChallengeStage.value = challengeStage
  showChallengeStage.value = true
}

async function onDebugChallengeStageFinish(
  challengeStage: ChallengeStage,
  star: 1 | 2 | 3
) {
  if (challengeStageStartLoading.value) return
  const allChallengeStages = regions.value.reduce<ChallengeStage[]>(
    (acc, item) => {
      return acc.concat(item.challengeStages)
    },
    []
  )

  const index = allChallengeStages.indexOf(challengeStage)
  const prevChallengeStage = allChallengeStages[index - 1]
  const endLevel = challengeStage.level
  const startLevel = prevChallengeStage?.level ?? 1
  try {
    challengeStageStartLoading.value = true
    await debugFinishChallengeStage(
      challengeStage.index,
      startLevel,
      endLevel,
      star
    )
    showChallengeStage.value = false
    fetchStages()
    fetchDailyTasks()
    store.fetchStatsOverview()
  } finally {
    challengeStageStartLoading.value = false
  }
}

function getChallengeStageParams(challengeStage: ChallengeStage) {
  const index = allChallengeStages.value.findIndex(
    item => item.index === challengeStage.index
  )
  const prevChallengeStage = allChallengeStages.value[index - 1]
  const endLevel = challengeStage.level
  const startLevel = prevChallengeStage?.level ?? 1
  return {
    index: challengeStage.index,
    startLevel,
    endLevel,
  }
}

async function onChallengeStageStart(challengeStage: ChallengeStage) {
  if (challengeStage.active && !challengeStageStartLoading.value) {
    challengeStageStartLoading.value = true

    try {
      const params = getChallengeStageParams(challengeStage)
      const res = await startAtlasChallengeStage(params)

      if (res.code === Code.NotEnoughEnergy) {
        _message.info('面包不足')
        _openEnergyBuy()
        return
      }

      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      store.increaseEnergy(-preChallengeStageInfo.value.requiredEnergy)

      store.setChallengeStageUnit(
        {
          unitId: res.data.unitId,
          atlasStageId: res.data.stageId,
          schedules: res.data.schedules,
        },
        {
          index: challengeStage.index,
          npcName: challengeStage.npcImage,
        }
      )

      showChallengeStage.value = false
      router.push({
        name: 'unit/learn',
      })
    } finally {
      challengeStageStartLoading.value = false
    }
  }
}

function onStagesScroll() {
  if (regionsContainer.value == null) return

  const activeStageDom = document.querySelector('.stage.active .anchor')
  const rect = activeStageDom?.getBoundingClientRect()

  if (rect == null) return

  if (rect.top < 0) {
    focusBtn.value = 'up'
  } else if (rect.bottom > window.innerHeight) {
    focusBtn.value = 'down'
  } else {
    focusBtn.value = 'none'
  }
}

function focusToActiveStage(immediate: boolean = false) {
  const activeStageDom = document.querySelector('.stage.active .anchor')
  if (activeStageDom) {
    scrollToTarget(activeStageDom, immediate)
  }
}

function focusToChallengeStage(index: number) {
  const challengeStageDom = document.querySelector(`#challenge-stage-${index}`)

  if (challengeStageDom) {
    scrollToTarget(challengeStageDom, true)
  }
}

function scrollToTarget(el: Element, immediate: boolean = false) {
  if (regionsContainer.value == null) return

  const rect = el?.getBoundingClientRect()
  const containerRect = regionsContainer.value.getBoundingClientRect()

  if (rect == null) return

  const containerCenterTop = containerRect.top + containerRect.height / 2
  const elCenterTop = rect.top + rect.height / 2

  regionsContainer.value.scrollTo({
    top: regionsContainer.value.scrollTop + elCenterTop - containerCenterTop,
    behavior: immediate ? undefined : 'smooth',
  })
}

function getNextBounceChallengeIndex(
  region: Region,
  start?: number
): number | undefined {
  if (start == null) {
    const firstChallenge = region.challengeStages.find(
      item => item.lastCompletedAt == null && item.active
    )
    return firstChallenge?.index
  }

  const startIndex = region.challengeStages.findIndex(
    item => item.index === start
  )
  const reorderChallengeStages = region.challengeStages
    .slice(startIndex + 1)
    .concat(region.challengeStages.slice(0, startIndex + 1))

  return reorderChallengeStages.find(
    item => item.lastCompletedAt == null && item.active
  )?.index
}

let timer: number
function scheduleChallengeBounce(region: Region) {
  clearTimeout(timer)
  currentBounceChallengeStageIndex.value = getNextBounceChallengeIndex(
    region,
    currentBounceChallengeStageIndex.value
  )

  timer = setTimeout(() => {
    scheduleChallengeBounce(region)
  }, 3000)
}

function showRegionRewardButton() {
  const stageLevel3 = stages.value.find(item => item.level === 3)

  return stageLevel3?.completed
}

watch(
  () => store.user,
  user => {
    if (user != null) {
      fetchStages()
    }
  }
)

watch(
  () => viewRegion.value,
  val => {
    if (val) {
      scheduleChallengeBounce(val)
    }
  },
  { immediate: true }
)
</script>
<style scoped>
.big-screen {
  .main {
    display: flex;
    gap: 48px;
    justify-content: center;
  }

  .left-header.header {
    height: 0px;
    overflow: hidden;
    /* TODO(buding): 这里直接用 display none 会导致每日任务那里的数字 svg 渲染异常 */
    /* display: none; */
  }

  .tasks-header.header {
    display: flex;
    margin-bottom: 24px;
  }

  .tasks {
    display: block;
  }
}

.daily-tasks {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px var(--slate-300);
}

.daily-tasks :deep(.daily-task) {
  box-shadow: none;
}

.region-header {
  height: 70px;
  border-radius: 12px;
  margin: 0px 8px 4px;
  display: flex;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
}

.regions-container {
  flex: 1;
  overflow: auto;
}

.unlock-region {
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid var(--ld-border);
  background: var(--gray-100);
  border-radius: 16px;
  margin: 12px 16px;
  padding: 16px 0px;
}

.region-title {
  color: var(--text-color-secondary);
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
  margin: 16px;
  width: calc(100% - 32px);
}

.region-title :deep(.p-divider-content) {
  background-color: var(--gray-100);
}

.region-stages {
  max-width: 375px;
  margin: 0px auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 40px 32px;
}

.stage {
  font-family: 'DIN';
  width: 70px;
  height: 58px;
  flex-shrink: 0;
  font-size: 32px;
  font-weight: 700;
  border-radius: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

.stage:active {
  box-shadow: unset !important;
  position: relative;
  top: 8px;
}

.level-1 {
  padding-left: 48.8%;
  margin-top: 24px;
}
.level-2 {
  padding-left: 16%;
  margin-top: 46px;
}
.level-3 {
  padding-left: 0px;
  margin-top: 22px;
}
.level-4 {
  padding-left: 33.4%;
  margin-top: 18px;
}
.level-5 {
  padding-left: 67.2%;
  margin-top: 43px;
}

.level-6 {
  justify-content: flex-end;
  margin-top: 23px;
}
.level-7 {
  padding-left: 48.8%;
  margin-top: 22px;
}
.level-8 {
  padding-left: 13%;
  margin-top: 54px;
}
.level-9 {
  padding-left: 0px;
  margin-top: 25px;
}
.level-10 {
  padding-left: 33.4%;
}

.stage .anchor {
  width: 52px;
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.anchor {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 0px solid #ccc; /* 边框用于定位 */
  padding: 10px;
}

/* 使用伪元素来添加叠加色效果 */
.anchor::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 50%;
}

.stage.active .avatar {
  font-size: 24px;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.stage.completed {
  flex-direction: column;
}

.stage.received {
  color: var(--yellow-800);
}

.stage.received .id {
  color: white;
}

.stage.completed .id {
  height: 22px;
  width: 20px;
  font-size: 14px;
  font-weight: 700;
  font-family: DIN;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.focus-btn {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
  border: 1px solid var(--ld-border);
  background-color: white;
  border-bottom: 4px solid var(--ld-border);
  border-radius: 12px;
  z-index: 1;
}

.daily-tasks .task-list {
  gap: 0px;
}

.bounce {
  animation: bounce 3s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
  10% {
    transform: translateY(-12px) scaleY(1.05) scaleX(0.95);
  }
  20% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
  30% {
    transform: translateY(-12px) scaleY(1.05) scaleX(0.95);
  }
  40% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
}
</style>
