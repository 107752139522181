<template>
  <RatioSpacedContainer
    v-if="data.emptyFrames"
    class="text-red-500 text-center h-full"
  >
    {{ _t('没有可用的卡片') }}
  </RatioSpacedContainer>

  <BossBattle
    :face="data.activeFrame?.face"
    :index="data.cardLearnRound"
    :npc="{
      hp: data.npcHp,
      avatar: npcAvatar,
      name: displayNpcName,
    }"
    :player="{
      hp: data.playerHp,
      avatar: store.user!.avatar,
      name: store.user?.nickname,
    }"
    @event="onEventCollect"
    @star="onStar"
    @next="onNext"
    @close="emit('close')"
  ></BossBattle>
</template>

<script lang="ts" setup>
import { UnitEventType } from '@/api/learn'
import { provide, reactive, computed } from 'vue'
import { useCommonStore } from '@/stores'
import { feedbackStar } from '@/components/ConcreteCard/common/feedback'
import { DuelQueue, DuelStage } from './index'
import { type ConcreteFrame, QueueType } from '../queue'
import { cancelReport, completeReport } from '../report'

import { Interaction, type ConcreteCardFace } from '@/types/core'
import db from '@/db'
import { useRouter } from 'vue-router'
import { debounce } from 'lodash-es'
import BossBattle from '@/components/BossBattle.vue'
import { getBossAvatar, getBossDisplayName } from '@/utils'

const emit = defineEmits<{
  close: []
  star: [feedbackStar]
  leftBloodUpdate: [number]
}>()

const store = useCommonStore()
const router = useRouter()

let queue: DuelQueue

const data = reactive({
  cardLearnRound: 0,
  stage: DuelStage.Duel,
  activeFrame: null as ConcreteFrame | null,
  emptyFrames: false,
  npcHp: 100,
  playerHp: 100,
})

const npcName = computed(() => {
  return store.stageUnit?.challenge?.npcName
})

const npcAvatar = computed(() => {
  if (npcName.value) {
    return getBossAvatar(npcName.value)
  }
  return undefined
})

const displayNpcName = computed(() => {
  if (!npcName.value) return ''
  return getBossDisplayName(npcName.value)
})

// 传入 boss name
// boss 阶段/挑战模式 问题顶部的间距改为 8px
provide('bossName', store.stageUnit?.challenge?.npcName)

function loadUnit() {
  if (!store.checkStageUnit()) return

  if (store.stageUnit != null) {
    queue = new DuelQueue(store.stageUnit.schedules)
    const progressCache = db.unitProgressCache[QueueType.Duel]

    if (progressCache) {
      queue.setState(progressCache)
      updateHp()
    }

    data.emptyFrames = queue.frames.length === 0
    data.activeFrame = queue.frames[0]
  }
}

loadUnit()

async function updateHp() {
  data.npcHp = queue.npcHp === 0 ? 0 : (queue.npcHp / queue.fullNpcHp) * 90 + 10
  data.playerHp =
    queue.playerHp === 0 ? 0 : (queue.playerHp / queue.fullPlaylerHp) * 90 + 10

  const state = queue.getState()
  db.unitProgressCache[QueueType.Duel] = state

  if (data.playerHp === 0) {
    onDefeated()
  }
}

function onEventCollect(cardId: number | undefined, event: UnitEventType) {
  if (!data.activeFrame || cardId == null) return

  queue.onEvent(cardId, event)

  const cardInteraction = (data.activeFrame.face as ConcreteCardFace)
    .interaction

  store.insertLearningEvent(cardId, {
    event,
    // 需要后端增加类型，否则会报错
    stage: [Interaction.Practice, Interaction.Check].includes(cardInteraction)
      ? cardInteraction
      : Interaction.Practice,
    timestamp: Date.now(),
  })
  updateHp()
}

const onNext = debounce(() => {
  queue.next()

  data.activeFrame = queue.frames[0]
  data.cardLearnRound++
}, 500)

function onStar(star: feedbackStar) {
  emit('star', star)

  queue.tick()

  if (queue.stage === DuelStage.Victory) {
    onVictory()
  }
  updateHp()
}

async function onDefeated() {
  const ok = await _confirm({
    type: 'warn',
    icon: {
      name: 'ld-cry',
      type: 'img',
    },
    content: '挑战失败\n没关系，集中精神、再来一次！',
    okText: '退出挑战',
    cancelText: '重新挑战',
  })

  await cancelReport()
  if (ok) {
    router.push({
      name: 'atlas',
    })
  } else if (store.stageUnit) {
    router.push({
      name: 'atlas',
      query: {
        challengeStage: store.stageUnit?.challenge?.index,
        preStart: 'true',
      },
    })
  }
}

function onVictory() {
  data.stage = queue.stage

  if (store.stageUnit == null) return

  const cardIds = (store.stageUnit?.schedules ?? []).map(item => item.cardId)

  for (const id of cardIds) {
    store.completeCard(id)
  }
  const leftBlood = queue.playerHp / queue.fullPlaylerHp
  completeReport(leftBlood)
  emit('leftBloodUpdate', leftBlood)

  delete db.unitProgressCache[QueueType.Duel]
}
</script>

<style scoped></style>
