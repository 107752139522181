<template>
  <div
    ref="padEl"
    :class="['card-pad ld-shadow']"
  >
    <div class="flex items-center gap-2 h-24px mb-2">
      <div
        class="flex items-center text-[var(--gray-400)] cursor-pointer"
        @click="onCardTypeSwitch"
      >
        <span>{{ cardNameMap[card.type] }}</span>
        <i class="pi pi-chevron-down ml-1 text-14px"></i>
      </div>

      <div class="ml-auto flex gap-2">
        <Icon
          v-if="props.showFullEdit"
          name="fullscreen"
          class="cursor-pointer fullscreen w-20px"
          :draggable="false"
          @click.stop="onFullscreenEdit()"
        />

        <DragHandle>
          <Icon
            name="note-order"
            class="cursor-pointer order-drag-handle w-24px"
            :draggable="false"
          />
        </DragHandle>

        <CardMenu
          :can-edit="props.showFullEdit"
          :can-delete="props.canDelete"
          @edit="onFullscreenEdit()"
          @create-after="emit('create-after')"
          @create-before="emit('create-before')"
          @copy="emit('copy')"
          @delete="emit('delete')"
        />
      </div>
    </div>

    <div
      :class="[
        'flex',
        {
          'items-stretch': _global.isPcMode && card.type === CardType.CLOZE,
          'items-center': _global.isPcMode && card.type === CardType.EN_WORD,
          'flex-col': !_global.isPcMode,
        },
      ]"
    >
      <div class="flex-1 mr-2">
        <div
          v-if="card.type === CardType.CLOZE"
          class="h-full flex flex-col justify-between"
        >
          <ClozeEditor
            :id="props.cardRes.id"
            :key="props.cardRes.id + editorKey"
            :content="card.content"
            :limit="1000"
            editable
            @focus="onClozeContentFocus"
            @blur="showInvalidTip = true"
            @update="onClozeCardContentUpdate"
            @prev="emit('prev')"
            @next="emit('next')"
            @create-next="emit('create-after')"
          >
            <template
              v-if="!_global.isPcMode"
              #toolbtns
            >
              <Icon
                name="illustration"
                :class="[
                  'w-23px',
                  {
                    'opacity-30': card.illustration,
                  },
                ]"
                @click="onAddIllustration"
              />
            </template>
          </ClozeEditor>

          <div
            v-if="showInvalidTip"
            class="text-red text-14px"
          >
            {{ clozeCardInvalidMessage }}
          </div>
        </div>

        <div
          v-else-if="card.type === CardType.EN_WORD"
          :class="[
            'my-2 flex items-start',
            {
              'gap-4': _global.isPcMode,
              'gap-2 flex-col': !_global.isPcMode,
            },
          ]"
        >
          <div
            :class="[
              'relative',
              {
                'basis-2/5': _global.isPcMode,
                'w-full': !_global.isPcMode,
              },
            ]"
          >
            <Textarea
              :modelValue="card.word"
              class="en-input w-100%"
              rows="1"
              autoResize
              placeholder="单词"
              maxlength="1000"
              @focus="onEnWordInputFocus"
              @update:model-value="onEnWordCardUpdate({ word: $event })"
              @keydown.up="onPrevWordRecommend"
              @keydown.down="onNextWordRecommend"
              @keydown.enter="onWordInputEnter"
              @blur="onWordInputBlur"
            />

            <div
              v-if="enWordCardWordInvalidMessage && showInvalidTip"
              class="text-red text-14px"
            >
              {{ enWordCardWordInvalidMessage }}
            </div>

            <div
              v-if="showEnWordResult"
              class="absolute enword-list flex flex-col"
            >
              <div
                v-for="(item, index) in enWordRecommends"
                :key="item.spelling"
                :class="[
                  'text-15px leading-24px flex cursor-pointer hover:bg-[var(--surface-100)] px-12px py-2',
                  {
                    'bg-[var(--surface-100)]': selectedWordRecommend === index,
                  },
                ]"
                @mouseenter="selectedWordRecommend = index"
                @click="onWordRecommendPick(item)"
              >
                <div>{{ item.spelling }}</div>
                <div
                  class="text-[var(--text-color-secondary)] flex-1 ml-2 truncate"
                >
                  {{ item.explain }}
                </div>
              </div>
            </div>
          </div>

          <div
            :class="{
              'basis-3/5': _global.isPcMode,
              'w-full': !_global.isPcMode,
            }"
          >
            <Textarea
              :modelValue="card.definition"
              class="en-input w-full"
              autoResize
              rows="1"
              placeholder="释义"
              maxlength="1000"
              @focus="onEnWordInputFocus"
              @update:model-value="onEnWordCardUpdate({ definition: $event })"
              @blur="showInvalidTip = true"
            />
            <div
              v-if="enWordCardDefinitionInvalidMessage && showInvalidTip"
              class="text-red text-14px"
            >
              {{ enWordCardDefinitionInvalidMessage }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="(!card.illustration && _global.isPcMode) || illustrationUploading"
        :class="[
          'h-78px w-78px flex items-center justify-center border border-dashed rounded bg-[var(--surface-100)] cursor-pointer',
          {
            'ml-auto': !_global.isPcMode,
          },
        ]"
        @click="onAddIllustration"
      >
        <ProgressSpinner
          v-if="illustrationUploading"
          class="w-36px h-36px p-11px"
        />

        <Icon
          v-else
          name="illustration"
          class="w-23px text-gray-600"
        />
      </div>

      <Image
        v-else-if="card.illustration"
        preview
        :class="[
          'illustration w-fit',
          {
            'mt-2 ml-auto': !_global.isPcMode,
          },
        ]"
      >
        <template #image>
          <div class="relative">
            <Img
              :assetId="card.illustration"
              class="h-78px w-78px rounded-8px object-cover"
              @click.stop
            />

            <Icon
              name="image-delete"
              class="absolute top-0 right-0 z-1 cursor-pointer w-18px"
              @click="onNoteRemoveIllustration"
            />
          </div>
        </template>
        <template #preview="slotProps">
          <img
            :src="_global.assetUrl(card.illustration)"
            class="object-cover"
            :style="slotProps.style"
            @click="slotProps.onClick"
          />
        </template>
      </Image>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  updatePackage,
  type CardResponse,
  CardTypeName,
} from '@/api/package-source'
import CardEdit from '@/pc/pages/Package/CardEdit.vue'
import ClozeEditor from '@/components/ClozeEditor/ClozeEditor.vue'
import CardMenu from '@/components/Package/CardMenu.vue'
import {
  CardType,
  type Card,
  type ClozeCard,
  type EnWordCard,
  type PronunciationLanguage,
  type Content,
} from '@/types/core'
import { onMounted, onUnmounted, ref } from 'vue'
import { pickFile, resizeAndCompressImage } from '@/utils'
import { uploadImage } from '@/api/user'
import Image from 'primevue/image'
import ProgressSpinner from 'primevue/progressspinner'
import { DragHandle } from 'vue-slicksort'
import { searchEnWord, type DictEnWord } from '@/api/learn'
import { debounce } from 'lodash-es'
import CardTypeSwitchForm from './CardTypeSwitchForm.vue'
import {
  newClozeCard,
  newWordCard,
  removeCardEmptyFields,
  validateCard,
} from '@/utils/card'
import { computed } from 'vue'
import bus, { BusEvent } from '@/bus/bus'

const cardNameMap = {
  [CardType.CLOZE]: _t('知识点'),
  [CardType.EN_WORD]: _t('单词'),
}

const props = withDefaults(
  defineProps<{
    canDelete: boolean
    packageId: number
    cardRes: CardResponse
    cardResList: CardResponse[]
    showFullEdit?: boolean
    illustrationUploading?: boolean
  }>(),
  {
    showFullEdit: false,
    illustrationUploading: false,
  }
)

const emit = defineEmits<{
  'create-before': []
  'create-after': []
  copy: []
  delete: []
  focus: [offset: number]
  update: [Card]
  prev: []
  next: []
  'add-illustration': [assetId: string]
  'remove-illustration': []
  'change-card-type': [CardType]
}>()

const showEnWordResult = ref(false)
const enWordRecommends = ref<DictEnWord[]>([])
const padEl = ref<HTMLDivElement>()
const selectedWordRecommend = ref(-1)
const showInvalidTip = ref(false)
const editorKey = ref(0)

const card = computed(() => JSON.parse(props.cardRes.content) as Card)
const filteredCard = computed(() => removeCardEmptyFields(card.value))

const clozeCardInvalidMessage = computed(() => {
  if (card.value.type === CardType.CLOZE) {
    return validateCard(filteredCard.value)
  }
  return ''
})

const enWordCardWordInvalidMessage = computed(() => {
  if (card.value.type === CardType.EN_WORD) {
    if (card.value.word.trim() === '') {
      return _t('请填写「单词」')
    }
  }
  return ''
})

const enWordCardDefinitionInvalidMessage = computed(() => {
  if (card.value.type === CardType.EN_WORD) {
    if (card.value.definition.trim() === '') {
      return _t('请填写「释义」')
    }
  }
  return ''
})

function onClozeCardContentUpdate(newContent: Content) {
  emit('update', {
    ...card.value,
    illustration: card.value.illustration?.trim() || undefined,
    content: newContent,
  } as ClozeCard)
}

function onEnWordCardUpdate(
  {
    word,
    definition,
    prons,
  }: {
    word?: string
    definition?: string
    prons?: {
      label: string
      language: PronunciationLanguage
    }[]
  },
  search = true
) {
  if (word != null && search) {
    onEnWordKeywordChange(word)
  }

  const enWordCard = card.value as EnWordCard

  emit('update', {
    ...enWordCard,
    illustration: enWordCard.illustration?.trim() || undefined,
    word: word ?? enWordCard.word,
    definition: definition ?? enWordCard.definition,
    prons: prons ?? enWordCard.prons,
  } as EnWordCard)
}

const onEnWordKeywordChange = debounce(async function onEnWordKeywordChange(
  keyword: string
) {
  if (keyword.trim() === '') {
    showEnWordResult.value = false
    return
  }

  const result = await searchEnWord(keyword)

  if (result.dictEnList.length > 0) {
    enWordRecommends.value = result.dictEnList
    showEnWordResult.value = true
  }
}, 300)

function onWordRecommendPick(enWord: DictEnWord) {
  showEnWordResult.value = false

  onEnWordCardUpdate(
    {
      word: enWord.spelling,
      definition: enWord.explain,
      prons: [
        {
          label: enWord.phoneticUk,
          language: 'en-GB',
        },
        {
          label: enWord.phoneticUs,
          language: 'en-US',
        },
      ],
    },
    false
  )
}

function onWordInputBlur() {
  showInvalidTip.value = true
  setTimeout(() => {
    showEnWordResult.value = false
  }, 100)
}

function onWordInputEnter(e: KeyboardEvent) {
  if (showEnWordResult.value) {
    const item = enWordRecommends.value[selectedWordRecommend.value]
    onWordRecommendPick(item)
    e.preventDefault()
  }
}

function onNextWordRecommend() {
  if (!showEnWordResult.value) return

  if (selectedWordRecommend.value === enWordRecommends.value.length - 1) {
    selectedWordRecommend.value = 0
  } else {
    selectedWordRecommend.value++
  }
}

function onPrevWordRecommend() {
  if (!showEnWordResult.value) return

  if (selectedWordRecommend.value === 0) {
    selectedWordRecommend.value = enWordRecommends.value.length - 1
  } else {
    selectedWordRecommend.value--
  }
}

function onFullscreenEdit() {
  if (props.cardRes.id == null) {
    _message.info(_t('创建卡片后再尝试'))
    return
  }

  _openDialog(CardEdit, {
    title: _t('编辑'),
    props: {
      card: props.cardRes,
      cards: props.cardResList,
      onUpdate(newCard: CardResponse) {
        editorKey.value++
        emit('update', JSON.parse(newCard.content))
      },
    },
    rootClass: 'w-1000px',
  })
}
const IMG_FILES = '.jpg,.jpeg,.png,.svg,.bmp,.webp,.gif'
async function onAddIllustration() {
  if (card.value.illustration) return

  const file = await pickFile(IMG_FILES)
  const compressedFile = await resizeAndCompressImage(file, 2000, 2000)
  const res = await uploadImage(compressedFile)

  if (res.code != 0) {
    _message.info(res.message)
  } else {
    emit('add-illustration', res.data.assetId)
  }
}

function onCardTypeSwitch() {
  _openDialog(CardTypeSwitchForm, {
    title: '切换卡片类型',
    props: {
      card: card.value,
      packageId: props.packageId,
      onTypeSwitch(newType: CardType) {
        updatePackage(props.packageId, {
          defaultCardType: {
            [CardType.CLOZE]: CardTypeName.CLOZE,
            [CardType.EN_WORD]: CardTypeName.WORD,
          }[newType],
        })

        emit('change-card-type', newType)
        switch (newType) {
          case CardType.CLOZE:
            emit('update', newClozeCard())
            break
          case CardType.EN_WORD:
            emit('update', newWordCard())
        }
      },
    },
  })
}

function onNoteRemoveIllustration() {
  emit('remove-illustration')
}

function onFocus(id: number) {
  if (id === props.cardRes.id) {
    // 因为编辑器聚焦的时候会打断滚动，所以这里使用 setTimeout 后再触发
    setTimeout(() => {
      padEl.value?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    })
  }
}

function onClozeContentFocus() {
  emit('focus', 0)
  showInvalidTip.value = false
}

function onEnWordInputFocus() {
  emit('focus', 0)
  showInvalidTip.value = false
}

onMounted(() => {
  bus.on(BusEvent.CardFocus, onFocus)
})

onUnmounted(() => {
  bus.off(BusEvent.CardFocus, onFocus)
})
</script>
<style scoped>
.card-pad {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: 1px solid var(--ld-border);
  transition: all 0.3s;
  background-color: white;
}

.card-pad * {
  transition: all 0.3s;
}

.card-pad .fullscreen,
.card-pad .order-drag-handle {
  /* 当拖拽进行排序时，会选中输入框内的文本，所以这里需要禁止选中 */
  user-select: none;
  display: none;
}

.card-pad:hover .fullscreen,
.card-pad:hover .order-drag-handle {
  display: inline-block;
}

.keypoint-count {
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 8px;
  width: fit-content;
  border-radius: 24px;
  color: var(--primary-color);
  background-color: var(--primary-200);
  margin-bottom: 8px;
  cursor: pointer;
}

.illustration :deep(.p-image-preview-indicator) {
  opacity: 0;
}

.en-input {
  border: none;
  border-bottom: 1px solid var(--surface-300);
  padding: 0px 8px 4px;
  border-radius: 0px;
  outline: none;
  resize: none;
}

.enword-list {
  width: 100%;
  top: 100%;
  box-shadow: 0px 4px 4px 0px var(--slate-300);
  border: 1px solid var(--surface-300);
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  z-index: 2;
}
</style>
