<template>
  <div
    ref="cardDiv"
    class="flex flex-col w-full min-h-0"
  >
    <DebugLabel class="absolute right-4 top-30">
      <DebugButton
        label="卡片信息"
        class="float-right mr-4"
        @click="onCardInfoShow"
      ></DebugButton>
    </DebugLabel>

    <ClozeCardFace
      v-if="props.face.card.type === CardType.CLOZE"
      :face="props.face"
      :quiz-stage="props.quizStage"
    />

    <EnWordCardFace
      v-else-if="props.face.card.type === CardType.EN_WORD"
      :face="props.face"
    />
  </div>
</template>

<script lang="ts" setup>
import { CardType, QuizStage } from '@/types/core'
import { inject, provide, ref, computed, onMounted } from 'vue'
import { UnitEventType } from '@/api/learn'
import ClozeCardFace from './ClozeCardFace/ClozeCardFace.vue'
import EnWordCardFace from './EnWordCardFace/EnWordCardFace.vue'
import DebugLabel from '../DebugLabel.vue'
import CardSource from '@/pc/pages/Package/CardSource.vue'
import { useCommonStore } from '@/stores'

import type { feedbackStar } from './common/feedback'
import type { ConcreteCardFace } from '@/types/core'

// 用来统计卡片学习时长
const onCardStart = inject<(cardId: number) => void>('onCardStart', () => {})
const onCardEnd = inject<(cardId: number) => void>('onCardEnd', () => {})

const store = useCommonStore()

onMounted(() => {
  onCardStart(props.face.cardId)
})

const emit = defineEmits<{
  star: [feedbackStar]
  answerChange: [answered: boolean]
  resultChange: [correct: boolean]
  done: []
  close: []
  next: []
  event: [cardId: number | undefined, UnitEventType]
}>()

const props = withDefaults(
  defineProps<{
    face: ConcreteCardFace
    quizStage?: QuizStage
  }>(),
  {
    quizStage: undefined,
  }
)

const cardDiv = ref<HTMLDivElement | null>(null)

const isLargeScreen = computed(() => {
  if (cardDiv.value != null) {
    return cardDiv.value.clientWidth > 500
  }

  return false
})

function onCardInfoShow() {
  const card = store.cardResponseCache[props.face.cardId]
  _openDialog(CardSource, {
    title: '卡片信息',
    props: {
      card,
    },
  })
}

provide('isLargeScreen', isLargeScreen)
provide('onEvent', (event: UnitEventType) =>
  emit('event', props.face.cardId, event)
)
provide('onStar', (star: feedbackStar) => {
  emit('star', star)
  onCardEnd(props.face.cardId)
})
provide('onNext', () => emit('next'))

// quiz
provide('answerChange', (answered: boolean) => emit('answerChange', answered))
provide('resultChange', (correct: boolean) => emit('resultChange', correct))
</script>

<style scoped></style>
