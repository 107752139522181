<template>
  <div
    v-if="previewMode != null"
    class="h-full flex flex-col bg-ld-background"
  >
    <AppBar
      :title="previewTitle"
      @back="previewMode = null"
    />

    <Swiper
      class="w-full"
      slidesPerView="auto"
      :spaceBetween="40"
      :centeredSlides="true"
      :grabCursor="true"
      @slideChange="onSlideChange"
      @swiper="onSetSwiper"
    >
      <SwiperSlide
        v-for="(card, index) in previewCards"
        :key="index"
      >
        <CardPreview :card="card" />
      </SwiperSlide>
    </Swiper>
  </div>

  <div
    v-else
    class="h-full flex flex-col bg-ld-background"
  >
    <AppBar
      title="学习回顾"
      @back="emit('back')"
    >
      <template
        v-if="_global.isPcMode"
        #back
      >
        <span></span>
      </template>
    </AppBar>

    <div class="flex-1 px-4 overflow-auto flex flex-col">
      <div
        v-if="multiWrongCards.length > 0"
        class="flex flex-col gap-2 mb-4"
      >
        <div class="text-red-500 text-lg font-semibold">
          {{ _t(`需加强（${multiWrongCards.length}）`) }}
        </div>

        <div class="flex flex-col gap-3">
          <CardEssence
            v-for="(card, i) of multiWrongCards"
            :key="i"
            :card="card"
            class="border border-gray-200 p-3 rounded-8px"
          />
        </div>
      </div>

      <div
        v-if="oneWrongCards.length > 0"
        class="flex flex-col gap-2 mb-4"
      >
        <div class="text-orange-500 text-lg font-semibold">
          {{ _t(`还不错（${oneWrongCards.length}）`) }}
        </div>
        <div class="flex flex-col gap-3">
          <CardEssence
            v-for="(card, i) of oneWrongCards"
            :key="i"
            :card="card"
            class="border border-gray-200 p-3 rounded-8px"
          />
        </div>
      </div>

      <div
        v-if="zeroWrongCards.length > 0"
        class="flex flex-col gap-2 mb-4"
      >
        <div class="text-green-500 text-lg font-semibold">
          {{ _t(`完美（${zeroWrongCards.length}）`) }}
        </div>
        <div class="flex flex-col gap-3">
          <CardEssence
            v-for="(card, i) of zeroWrongCards"
            :key="i"
            :card="card"
            class="border border-gray-200 p-3 rounded-8px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import AppBar from '@/mobile/components/AppBar.vue'
import CardEssence from '@/components/CardEssence/CardEssence.vue'
import CardPreview from '@/components/CardPreview/CardPreview.vue'
import { computed, ref } from 'vue'
import type { Card } from '@/types/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as TSwiper } from 'swiper'
import { LookBackStatus, type AtlasStageCard } from '@/api/atlas'
import 'swiper/css'

const emit = defineEmits<{
  back: []
}>()

const props = defineProps<{
  cards: AtlasStageCard[]
}>()

const swiperController = ref<TSwiper>()
const previewMode = ref<'zero' | 'one' | 'multiple' | null>(null)
const previewCards = ref<Card[] | null>(null)
const previewIndex = ref(0)

const previewTitle = computed(() => {
  switch (previewMode.value) {
    case 'zero':
      return `完美 ${previewIndex.value + 1}/${zeroWrongCards.value.length}`
    case 'one':
      return `还不错 ${previewIndex.value + 1}/${oneWrongCards.value.length}`
    case 'multiple':
      return `需加强 ${previewIndex.value + 1}/${multiWrongCards.value.length}`
  }

  return ''
})

const zeroWrongCards = computed(() => {
  const cards = props.cards.filter(card => {
    return card.lookBackStatus === LookBackStatus.PERFECT
  })

  return cards.map(item => JSON.parse(item.content))
})

const oneWrongCards = computed(() => {
  const cards = props.cards.filter(card => {
    return card.lookBackStatus === LookBackStatus.GOOD
  })

  return cards.map(item => JSON.parse(item.content))
})

const multiWrongCards = computed(() => {
  const cards = props.cards.filter(card => {
    return card.lookBackStatus === LookBackStatus.NEEDS_IMPROVEMENT
  })

  return cards.map(item => JSON.parse(item.content))
})

function onSetSwiper(val: TSwiper) {
  swiperController.value = val
  swiperController.value?.slideTo(previewIndex.value, 0)
}

function onSlideChange(swiper: TSwiper) {
  previewIndex.value = swiper.activeIndex
}

// function onMultiWrongCardsPreview(index: number) {
//   previewMode.value = 'multiple'
//   previewCards.value = multiWrongCards.value
//   previewIndex.value = index
// }

// function onOneWrongCardsPreview(index: number) {
//   previewMode.value = 'one'
//   previewCards.value = oneWrongCards.value
//   previewIndex.value = index
// }

// function onZeroWrongCardsPreview(index: number) {
//   previewMode.value = 'zero'
//   previewCards.value = zeroWrongCards.value
//   previewIndex.value = index
// }
</script>
<style scoped></style>
