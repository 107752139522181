import { defineComponent, withDirectives } from 'vue'
import { BubbleMenu, type Editor } from '@tiptap/vue-3'
import Tooltip from 'primevue/tooltip'
import Icon from '../Icon.vue'

export const FloatingMenuItem = defineComponent({
  setup(
    props: { isActive: boolean; tooltip: string; onClick: VoidFunction },
    { slots }
  ) {
    return () => {
      return withDirectives(
        <div
          class={[
            'rounded px-2 py-1 cursor-pointer flex items-center gap-1',
            props.isActive
              ? 'bg-[var(--ld-brand-500)]'
              : 'bg-transparent hover:bg-[var(--surface-600)]',
          ]}
          onClick={props.onClick}
        >
          {slots.default?.()}
        </div>,
        [
          [
            Tooltip,
            {
              value: props.tooltip,
              pt: {
                root: {
                  class: 'pb-2',
                },
                text: {
                  class:
                    'py-1 px-2 shadow-sm border text-[var(--surface-0)] bg-[var(--surface-950)]',
                },
                arrow: {
                  class: 'hidden',
                },
              },
            },
            '',
            { top: true },
          ],
        ]
      )
    }
  },
  props: ['isActive', 'tooltip', 'onClick'],
})

export const FloatingMenu = defineComponent({
  setup(props: { editor: Editor }) {
    const extensions = props.editor.extensionManager.extensions
    const showDotMenu = extensions.some(item => item.name === 'dot')

    return () => {
      return (
        <BubbleMenu
          class="flex h-full leading-none gap-0.5 p-1 items-center text-[var(--surface-0)] bg-[var(--surface-950)] rounded-lg shadow-sm border"
          tippyOptions={{ duration: 100 }}
          editor={props.editor}
        >
          <FloatingMenuItem
            tooltip={_global.isMac ? 'Command + D' : 'Ctrl + D'}
            isActive={props.editor.isActive('cloze')}
            onClick={() => props.editor.chain().toggleCloze().run()}
          >
            <Icon
              name={'toolbar-cloze'}
              class={'w-24px'}
            ></Icon>
            {_t('标记知识点')}
          </FloatingMenuItem>

          {showDotMenu && (
            <FloatingMenuItem
              tooltip={_global.isMac ? 'Command + E' : 'Ctrl + E'}
              isActive={props.editor.isActive('textStyle', { dot: true })}
              onClick={() => props.editor.chain().toggleDot().run()}
            >
              {_t('加点字')}
            </FloatingMenuItem>
          )}
        </BubbleMenu>
      )
    }
  },
  props: ['editor'],
})
