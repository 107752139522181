<template>
  <template v-if="!started">
    <div class="flex h-44px px-4 g-header-width">
      <Icon
        name="close"
        class="cursor-pointer w-20px"
        @click="emit('close')"
      ></Icon>
    </div>
    <RatioSpacedContainer class="flex-1">
      <Img
        :name="props.boss.name"
        class="w-128px"
      />

      <div class="mt-5 text-22px leading-[1.4] text-ld-brand-900">
        {{ _t('你很强哦，来跟我比试比试吧！') }}
      </div>
    </RatioSpacedContainer>
    <Button
      class="bottom-button"
      :label="_t('继续')"
      @click="onStart"
    ></Button>
  </template>

  <BossBattle
    v-else
    :face="current"
    :index="index"
    :npc="{ hp: bossHp, avatar: npcAvatar }"
    :defeated="defeated"
    @event="onEvent"
    @next="onNext"
    @star="onStar"
    @done="onDone"
    @close="emit('close')"
  ></BossBattle>
</template>

<script setup lang="ts">
import { ClozeCardFaceType, type VirtualCardBossFace } from '@/types/core'
import { UnitEventType } from '@/api/learn'
import { computed, provide, ref } from 'vue'
import { useHotKey } from '@/hooks'
import type { ConcreteCardFace } from '@/types/core'
import BossBattle from '../BossBattle.vue'
import type { feedbackStar } from '../ConcreteCard/common/feedback'
import { getBossHalf } from '@/utils'

useHotKey('enter,space', () => {
  if (!started.value) {
    onStart()
  } else if (current.value == null) {
    onDone()
  }
})

const props = defineProps<{
  boss: VirtualCardBossFace
}>()

const npcAvatar = computed(() => {
  return getBossHalf(props.boss.name)
})
const cards = computed(() => {
  // boss 卡片 隐藏角色形象
  return props.boss.faces.map(card => {
    const res = {
      ...card,
      style: {
        ...card.style,
        hideRoleImage: true,
      },
    }
    if (
      card.type === ClozeCardFaceType.Choice ||
      card.type === ClozeCardFaceType.Judgement
    ) {
      return {
        ...res,
        style: {
          ...res.style,
          questionLayout: 'vertical',
        },
      }
    }
    return res
  })
})

const index = ref(0)

// 怪物的血量
const health = computed(() => props.boss.faces.length - index.value)

// 要显示的卡片
const current = computed(() => cards.value[index.value] as ConcreteCardFace)

// 打boss阶段
const started = ref(false)

// boss的血量
const bossHp = computed(() => {
  if (defeated.value) {
    return 0
  }
  return (health.value / props.boss.faces.length) * 100
})

const defeated = ref(false)

// 传入 boss name
// boss 阶段/挑战模式 问题顶部的间距改为 8px
provide('bossName', props.boss.name)

const emit = defineEmits<{
  event: [cardId: number, UnitEventType]
  done: []
  close: []
  star: [feedbackStar]
}>()

function onStart() {
  started.value = true
}

function onDone() {
  emit('done')
}
function onStar(star: feedbackStar) {
  emit('star', star)
}

function onNext() {
  if (index.value >= cards.value.length - 1) {
    defeated.value = true
    return
  }
  index.value += 1
}

function onEvent(_: number | undefined, event: UnitEventType) {
  emit('event', current.value.cardId, event)
}
</script>

<style scoped>
.bottom-button {
  width: calc(100% - 32px);
  max-width: 700px;
  height: 44px;
  margin-bottom: 16px;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}
</style>
