<template>
  <Loading />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { wxLogin, wxRegister } from '@/api/auth'
import { queryWechatOpenId } from '@/api/public'
import { useCommonStore } from '@/stores'
import { Code } from '@/api/code'
import { uploadWechatOpenId } from '@/api/user'

const router = useRouter()
const route = useRoute()
const code = route.query.code as string
const redirect = route.query.redirect as string
const store = useCommonStore()

function exit() {
  router.push(redirect ?? '/')
}

onMounted(async () => {
  // 异常情况，code 不存在，忽略这个错误，直接跳转到首页
  if (!code) {
    _reportError('wechat redirect page code not exist')
    exit()
    return
  }

  // 为了解决公众号底部返回栏的问题，公众号的入口是登录链接，然后直接回跳到 wxcallback 页面
  // 如果已经登录了，直接返回
  if (store.isLoggedIn || route.query.login === 'false') {
    if (!_db.wxOpenId) {
      const res = await queryWechatOpenId(code, 'MP')
      if (res.code === Code.Ok) {
        _db.wxOpenId = res.data.openId
        uploadWechatOpenId(res.data.openId)
      } else {
        _message.info('openId 获取失败')
      }
    }

    exit()
    return
  }

  wxLogin({ code, wechatType: 'MP' })
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      _db.wxOpenId = res.data.wxOpenId
      // 已经注册了
      if (res.data.loginResponse) {
        store.login(res.data.loginResponse.token, res.data.loginResponse.user)
        return
      }

      // 自动注册
      return wxRegister({ wxToken: res.data.wxToken }).then(res => {
        if (res.code !== 0) {
          _message.info(res.message)
          return
        }

        store.login(res.data.token, res.data.user)
      })
    })
    .then(() => {
      exit()
    })
})

//
</script>

<style scoped></style>
