<template>
  <div class="w-full p-4 flex flex-col items-center bg-ld-background">
    <div class="w-full flex items-enter h-24px">
      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>
    <Icon
      name="diamond"
      class="w-54px"
    />

    <div class="font-[DIN] text-ld-brand-500 text-21px font-700 mt-2">
      {{ _t(`当前钻石：${diamondAmount}`) }}
    </div>

    <div class="text-16px leading-[1.25] font-500 mt-2">
      {{ _t('钻石可以用来解锁卡包、补充面包') }}
    </div>

    <div class="flex w-full items-center mt-8">
      <Divider class="flex-1" />
      <div class="px-2 text-15px text-ld-label-secondary font-600">
        {{ _t('获取钻石') }}
      </div>
      <Divider class="flex-1" />
    </div>

    <div
      class="cell cursor-pointer"
      @click="onDiamondsRewardClick"
    >
      <div class="title">
        {{ _t('🎁 目标奖励') }}
      </div>

      <div class="flex items-center">
        <div class="text-ld-brand-500 sub-title">{{ _t('查看') }}</div>
        <i class="pi pi-angle-right text-12px ml-1"></i>
      </div>
    </div>
    <div
      class="cell"
      :class="[stageCount >= 3 ? 'cursor-pointer' : 'cursor-default']"
      @click="onAltasRewardClick"
    >
      <div class="title">
        {{ _t('⭐️ 闯关星级奖励') }}
      </div>
      <div class="flex items-center">
        <template v-if="stageCount >= 3">
          <div class="text-ld-brand-500 sub-title">{{ _t('查看') }}</div>
          <i class="pi pi-angle-right text-12px ml-1"></i>
        </template>
        <template v-else>
          <div class="sub-title">{{ _t('需通过 3 关') }}</div>
        </template>
      </div>
    </div>
    <div
      class="cell cursor-pointer"
      @click="onDiamondsBuy"
    >
      <div class="title">
        {{ store.showAlphaGift ? _t('👑 内测专属福利') : _t('🛒 商店购买') }}
      </div>
      <div class="flex items-center">
        <div class="text-ld-brand-500 sub-title">
          {{ store.showAlphaGift ? _t('立即领取') : _t('前往商店') }}
        </div>
        <i class="pi pi-angle-right text-12px ml-1"></i>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { openStore } from '@/shared'
import { computed, ref } from 'vue'
import Divider from 'primevue/divider'
import VideoDialog from './VideoDialog.vue'
import { preloadVideo } from '@/utils/video'

const emit = defineEmits<{
  done: []
}>()

const store = useCommonStore()

// 总闯关数
const stageCount = computed(() => store.statsOverview?.stageCount ?? 0)

const diamondAmount = computed(() => store.userAssets?.diamond ?? 0)

function onDiamondsRewardClick() {
  _openDialog(VideoDialog, {
    rootClass: 'g-dialog',
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-0' },
      },
    },
    props: {
      title: _t('目标奖励'),
      src: questRewardSrc,
      ratio: questRewardRatio.value,
    },
  })
}

function onAltasRewardClick() {
  if (stageCount.value < 3) {
    return
  }
  _openDialog(VideoDialog, {
    rootClass: 'g-dialog',
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-0' },
      },
    },
    props: {
      title: _t('闯关星级奖励'),
      src: starRewardSrc,
      ratio: starRewardRatio.value,
    },
  })
}

function onDiamondsBuy() {
  openStore({
    onAlphaGiftClaimed() {
      emit('done')
    },
  })
}
// 星级奖励
const starRewardSrc =
  'https://cdn.lingduck.cn/sys/help/DiamondGet_StarReward.mp4'
// 目标奖励
const questRewardSrc =
  'https://cdn.lingduck.cn/sys/help/DiamondGet_QuestReward.mp4'

const starRewardRatio = ref(1)
const questRewardRatio = ref(1)
preloadVideo(starRewardSrc).then(size => {
  starRewardRatio.value = size.width / size.height
})
preloadVideo(questRewardSrc).then(size => {
  questRewardRatio.value = size.width / size.height
})
</script>
<style scoped>
.cell {
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 56px;
  justify-content: space-between;
  background-color: white;
  padding: 16px;
  margin-top: 12px;
  white-space: nowrap;
}

.title {
  font-size: 17px;
  line-height: 1;
}
.sub-title {
  font-size: 15px;
  line-height: 1;
}
</style>
