<template>
  <div class="h-full">
    <div
      v-if="mode === Mode.Search"
      class="h-full flex flex-col"
    >
      <div class="app-bar">
        <i
          class="pi pi-chevron-left cursor-pointer"
          @click="mode = Mode.Normal"
        />
        <InputText
          ref="searchInput"
          v-model="keyword"
          class="flex-1 m-l-4 rounded-3xl"
          placeholder="搜索分类"
        ></InputText>
      </div>

      <div
        class="overflow-y-auto scroll-container grid grid-cols-3 gap-x-4 gap-y-3 p-x-4 p-y-3"
      >
        <div
          v-for="element in searchResult"
          :key="element.tag.key"
          class="tag"
          :class="{
            'tag-disabled': followedContentTags.find(
              tag => tag.tag.key === element.tag.key
            ),
            'cursor-pointer': !followedContentTags.find(
              tag => tag.tag.key === element.tag.key
            ),
          }"
          @click="
            !followedContentTags.find(tag => tag.tag.key === element.tag.key) &&
              addTag(element.tag, element.parentTag)
          "
        >
          <i
            v-if="
              !followedContentTags.find(tag => tag.tag.key === element.tag.key)
            "
            class="pi pi-plus badge color-primary"
          ></i>
          <i
            v-else
            class="pi pi-check badge color-primary"
          ></i>
          <div class="tag-content p-2">
            <div class="text-base">
              {{ element.tag.name }}
            </div>
            <div class="text-sm text-[var(--text-color-secondary)]">
              {{ element.parentTag.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="h-full flex flex-col"
    >
      <div class="app-bar">
        <i
          class="pi pi-times cursor-pointer"
          @click="close"
        ></i>
        <i
          class="pi pi-search cursor-pointer"
          @click="mode = Mode.Search"
        ></i>
      </div>

      <div class="overflow-y-auto scroll-container flex-1 basis-0">
        <div class="p-x-4">
          <div class="flex items-center justify-between">
            <div>
              <span class="text-base font-bold">{{ _t('我的分类') }}</span>
              <span
                v-if="mode === Mode.Edit"
                class="text-sm text-[var(--text-color-secondary)] m-l-2"
              >
                {{ _t('拖拽调整顺序') }}
              </span>
            </div>

            <TextButton
              :label="mode === Mode.Edit ? _t('完成') : _t('编辑')"
              @click="mode = mode === Mode.Edit ? Mode.Normal : Mode.Edit"
            />
          </div>

          <draggable
            :list="followedContentTags"
            item-key="tag.key"
            class="grid grid-cols-3 gap-x-4 gap-y-3 m-t-3"
            ghost-class="opacity-30"
            @start="onDragStart"
          >
            <template #item="{ element }">
              <div
                class="tag cursor-pointer"
                @click="mode === Mode.Edit && removeTag(element)"
              >
                <i
                  v-if="mode === Mode.Edit"
                  class="pi pi-minus-circle badge color-[var(--surface-500)]"
                ></i>
                <div class="tag-content p2">
                  <div class="text-base">
                    {{ element.tag.name }}
                  </div>
                  <div class="text-sm text-[var(--text-color-secondary)]">
                    {{ element.parentTag.name }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>

        <div class="m-x-4 m-t-6">
          <template v-if="recommendedContentTags.length > 0">
            <div class="text-base font-bold m-b-2">
              {{ _t('推荐分类') }}
            </div>
            <div class="text-sm text-[var(--text-color-secondary)]">
              根据你当前的学习阶段{{ currentIdentityTagText }}推荐
              <br />
              如果你希望更改学习阶段，可以
              <a
                class="text-[var(--primary-color)] cursor-pointer"
                @click="onResetIdentityTags"
              >
                重置身份
              </a>
            </div>
            <div class="grid grid-cols-3 gap-4 p-t-3">
              <div
                v-for="element in recommendedContentTags"
                :key="element.tag.key"
                class="tag cursor-pointer"
                @click="addTag(element.tag, element.parentTag)"
              >
                <i class="pi pi-plus badge color-primary"></i>
                <div class="tag-content p2">
                  <div class="text-base">
                    {{ element.tag.name }}
                  </div>
                  <div class="text-sm text-[var(--text-color-secondary)]">
                    {{ element.parentTag.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-sm text-[var(--text-color-secondary)]">
              根据你当前的学习阶段{{
                currentIdentityTagText
              }}，已关注全部推荐分类，你可以继续关注其他感兴趣的分类，或
              <a
                class="text-[var(--primary-color)] cursor-pointer"
                @click="onResetIdentityTags"
              >
                重置身份
              </a>
            </div>
          </template>
        </div>

        <div class="m-t-6">
          <TabMenu
            :model="allContentTags"
            :pt="{
              root: { class: 'flex-1' },
              menu: { class: 'bg-[var(--surface-hover)]' },
            }"
            class="sticky top-0 z-1"
            @tab-change="onTabChange"
          >
            <template #item="{ item, active }">
              <span
                :class="{
                  tab: true,
                  active,
                }"
              >
                {{ item.name }}
              </span>
            </template>
          </TabMenu>

          <div
            v-if="allContentTags.length > 0"
            class="m-x-4 m-y-3"
          >
            <div v-if="tagGroupList.length === 0">
              <Empty text="暂无分类"></Empty>
            </div>
            <div
              v-for="item in tagGroupList"
              :key="item.key"
            >
              <div
                class="text-sm font-semibold text-[var(--text-color-secondary)] p-b-3"
              >
                {{ item.name }}
              </div>

              <div class="grid grid-cols-3 gap-4 p-b-4">
                <div
                  v-for="i in item.children"
                  :key="i.tag.key"
                  class="tag"
                  :class="{
                    'tag-disabled': followedContentTags.find(
                      tag => tag.tag.key === i.tag.key
                    ),
                    'cursor-pointer': !followedContentTags.find(
                      tag => tag.tag.key === i.tag.key
                    ),
                  }"
                  @click="
                    !followedContentTags.find(
                      tag => tag.tag.key === i.tag.key
                    ) && addTag(i.tag, i.parentTag)
                  "
                >
                  <i
                    v-if="
                      !followedContentTags.find(
                        tag => tag.tag.key === i.tag.key
                      )
                    "
                    class="pi pi-plus badge color-primary"
                  ></i>
                  <i
                    v-else
                    class="pi pi-check badge color-primary"
                  ></i>
                  <div class="tag-content p-4 text-lg">
                    {{ i.tag.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  fetchContentTags,
  fetchIdentityTags,
  fetchContentTagsByIdentityKeys,
} from '@/api/package-source'
import { computed, ref } from 'vue'
import TabMenu from 'primevue/tabmenu'
import { fetchUserTags, patchUser } from '@/api/user'
import draggable from 'vuedraggable'
import IdentityOnboarding from '@/components/IdentityOnboarding/IdentityOnboarding.vue'
import Empty from '../Empty.vue'

import type { ContentTagWithParent } from '@/api/package-source'
import type { ContentTag, IdentityTag } from '@/types/core'

enum Mode {
  Edit = 'edit',
  Search = 'search',
  Normal = 'normal',
}
const props = defineProps<{
  followedContentTags: ContentTagWithParent[]
}>()
const emit = defineEmits<{
  updateFollowedTags: []
  done: []
}>()

const allContentTags = ref<ContentTag[]>([])

const allIdentityTags = ref<IdentityTag[]>([])

// 当前关注的标签
const followedContentTags = ref<ContentTagWithParent[]>([
  ...props.followedContentTags,
])

// 身份标签对应的内容标签
const contentTagsByIdentityKeys = ref<ContentTagWithParent[]>([])

const identityTags = ref<IdentityTag[]>([])

// 学习阶段
const currentIdentityTagText = computed(() => {
  return identityTags.value
    .map(
      e =>
        `「${getIdentityTagPath(e.key, allIdentityTags.value)
          .slice(1)
          .join('/')}」`
    )
    .join('、')
})
// 推荐的内容标签。根据身份标签推荐
const recommendedContentTags = computed(() => {
  return contentTagsByIdentityKeys.value.filter(
    e => !followedContentTags.value.find(i => i.tag.key === e.tag.key)
  )
})

// 选择的Tab
const activeTabIndex = ref(0)

const mode = ref(Mode.Normal)
// 搜索关键字
const keyword = ref('')

const tagGroupList = computed<
  {
    name: string
    key: string
    children?: ContentTagWithParent[]
  }[]
>(() => {
  return generateTagGroupList(allContentTags.value[activeTabIndex.value], [], 0)
})

// 递归找到最深处的非叶子节点,生成 Group
// Group 名字是二级分类的名字 如果超出3级则拼接 规则[二级分类/三级分类/四级分类/叶子层级-一级分类]
function generateTagGroupList(
  item: ContentTag,
  paths: string[],
  deep: number
): {
  name: string
  key: string
  children?: ContentTagWithParent[]
}[] {
  const res: {
    name: string
    key: string
    children?: ContentTagWithParent[]
  }[] = []
  if (item.children?.some(i => i.children)) {
    item.children.forEach(i => {
      res.push(...generateTagGroupList(i, [...paths, item.name], deep + 1))
    })
  } else {
    const name =
      paths.length < 2
        ? item.name
        : [...paths, item.name].slice(1).join('/') + '-' + paths[0]

    if (item.children) {
      res.push({
        name: name,
        key: item.key,
        children: item.children?.map(e => {
          return {
            tag: e,
            parentTag: item,
          }
        }),
      })
    }
  }

  return res
}
// 搜索结果
const searchResult = computed(() => {
  const res: ContentTagWithParent[] = []
  if (!keyword.value) return res
  allContentTags.value.forEach(i => {
    res.push(...searchTag(i, keyword.value))
  })
  return res
})
// 通过关键字搜索标签
function searchTag(tag: ContentTag, keyword: string): ContentTagWithParent[] {
  const res: ContentTagWithParent[] = []
  if (!keyword) return res
  tag.children?.forEach(i => {
    if (i.children) {
      res.push(...searchTag(i, keyword))
    } else {
      // 搜索标签name 以及 aliases
      if (
        i.name.includes(keyword) ||
        i.aliases.some(j => j.includes(keyword))
      ) {
        res.push({
          tag: i,
          parentTag: tag,
        })
      }
    }
  })
  return res
}

// 移除关注的标签
function removeTag(tag: ContentTagWithParent) {
  followedContentTags.value = followedContentTags.value.filter(
    item => item.tag.key !== tag.tag.key
  )
}
// 添加关注的标签
function addTag(
  tag: {
    key: string
    name: string
  },
  parentTag: {
    key: string
    name: string
  }
) {
  if (followedContentTags.value.find(item => item.tag.key === tag.key)) return
  followedContentTags.value.push({
    tag,
    parentTag: parentTag,
  })
}
// 关闭页面
function close() {
  emit('done')
  if (
    JSON.stringify(followedContentTags.value) !==
    JSON.stringify(props.followedContentTags)
  ) {
    // 更新用户关注的标签
    // TODO: 这里需要更好的方案，应该避免修改 props
    props.followedContentTags.length = 0
    props.followedContentTags.push(...followedContentTags.value)
    patchUser({
      contentTags: followedContentTags.value.map(item => item.tag.key),
    })
  }
}

// 切换标签页
function onTabChange({ index }: { index: number }) {
  activeTabIndex.value = index
}
// 拖拽开始 进入编辑模式
function onDragStart() {
  mode.value = Mode.Edit
}

// 重置身份标签
async function onResetIdentityTags() {
  const res = await _confirm({
    title: '确认重置',
    content:
      '重置后，所有已关注的分类都会移除、并基于身份引导重新关注，确认要重置吗？',
    type: 'warn',
    okText: '开始重置',
    cancelText: '暂不',
  })
  if (res) {
    await _openDialog(IdentityOnboarding, {
      title: '身份引导',
      rootClass: 'w-md h-800px',
    })
    await fetchData()
  }
}

// 根据 IdentityTag key 获取 path
function getIdentityTagPath(key: string, tags: IdentityTag[]): string[] {
  for (const tag of tags) {
    if (tag.key === key) {
      return [tag.name]
    }
    if (tag.children) {
      const res = getIdentityTagPath(key, tag.children)
      if (res.length > 0) {
        return [tag.name, ...res]
      }
    }
  }
  return []
}

async function fetchData() {
  const res = await Promise.all([
    fetchContentTags('main'),
    fetchIdentityTags('main'),
    fetchUserTags(),
  ])
  allContentTags.value = res[0].tree.children ?? []
  allIdentityTags.value = res[1].tree.children ?? []
  identityTags.value = res[2].identityTagResponses
  followedContentTags.value = res[2].contentTagResponses

  contentTagsByIdentityKeys.value = (
    await fetchContentTagsByIdentityKeys(identityTags.value.map(e => e.key))
  ).contentTags
}
fetchData()
</script>
<style scoped>
.app-bar {
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.tab {
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  cursor: pointer;
}

.tab.active {
  color: var(--primary-color);
}

.badge {
  position: absolute;
  top: 4px;
  right: 4px;
}

.tag {
  position: relative;
  border-radius: 8px;
  border-bottom: 2px solid var(--slate-400);
  box-shadow: 0px 0px 1px 0px var(--slate-400);
  background: var(--opacityWhite);
}
.tag-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tag-disabled {
  opacity: 0.5;
  background: var(--surface-50);
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
</style>
