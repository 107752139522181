import { UnitEventType, type CardSchedule } from '@/api/learn'
import { Queue, type ConcreteFrame, getAltCards, orderFrames } from '../queue'
import {
  LearnStatus,
  type Card,
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
  Interaction,
} from '@/types/core'
import { parseCard } from '@/utils/card'

export interface DuelQueueState {
  npcHp: number
  playerHp: number
  fullNpcHp: number
  fullPlayerHp: number
  frames: ConcreteFrame[]
}

export enum DuelStage {
  Duel,
  Defeated,
  Victory,
}

export class DuelQueue extends Queue<{}, DuelQueueState> {
  constructor(cards: CardSchedule[], config: {} = {}) {
    super(cards, config)

    this.npcHp = cards.length
    this.fullNpcHp = cards.length
    this.playerHp = Math.floor(cards.length / 2)
    this.fullPlaylerHp = this.playerHp
    this.frames = this._genFrames()
  }

  npcHp: number = 10
  playerHp: number = 5
  fullNpcHp: number = 10
  fullPlaylerHp: number = 5

  stage: DuelStage = DuelStage.Duel

  frames: ConcreteFrame[] = []

  wrongCardIds: Set<number> = new Set()

  getState(): DuelQueueState {
    return {
      npcHp: this.npcHp,
      playerHp: this.playerHp,
      fullPlayerHp: this.fullPlaylerHp,
      fullNpcHp: this.fullNpcHp,
      frames: this.frames,
    }
  }

  setState(state: DuelQueueState) {
    this.npcHp = state.npcHp
    this.playerHp = state.playerHp
    this.fullPlaylerHp = state.fullPlayerHp
    this.fullNpcHp = state.fullNpcHp
    this.frames = state.frames

    if (this.npcHp <= 0) {
      this.stage = DuelStage.Victory
    } else if (this.playerHp <= 0) {
      this.stage = DuelStage.Defeated
    }
  }

  private _genFrames(): ConcreteFrame[] {
    const orderedCards: CardSchedule[] = []
    const frames: ConcreteFrame[] = []

    // eg: 复习1、新学1、复习2、新学2、复习3、新学3、新学4、新学5
    const reviewCards = this._cards.filter(
      item => item.cardStatus === LearnStatus.REVIEW
    )
    const debutCards = this._cards.filter(
      item => item.cardStatus === LearnStatus.DEBUT
    )

    let insertReviewCard = true
    while (reviewCards.length > 0 && debutCards.length > 0) {
      orderedCards.push(
        insertReviewCard ? reviewCards.shift()! : debutCards.shift()!
      )

      insertReviewCard = !insertReviewCard
    }

    orderedCards.push(...reviewCards)
    orderedCards.push(...debutCards)

    for (const item of orderedCards) {
      const card = JSON.parse(item.content) as Card
      const parseResult = parseCard(card)
      const altCards = getAltCards(this._cards, item.cardId, card)

      if (parseResult.error != null) return frames

      frames.push({
        type: 'concrete',
        status: item.cardStatus,
        cardId: item.cardId,
        face: {
          type: {
            [CardType.CLOZE]: ClozeCardFaceType.Choice,
            [CardType.EN_WORD]: EnWordCardFaceType.WordChoice,
          }[card.type],
          cardId: item.cardId,
          card: parseResult.card,
          interaction: Interaction.Flash,
          altCards,
          style: {
            hideRoleImage: true,
          },
        },
      } as ConcreteFrame)
    }

    return orderFrames(
      frames,
      orderedCards.map(item => item.cardId)
    )
  }

  onEvent(cardId: number, event: UnitEventType): void {
    super.onEvent(cardId, event)

    // 每张卡片答错只扣一次血量
    if (event === UnitEventType.WRONG && !this.wrongCardIds.has(cardId)) {
      this.playerHp--
      this.wrongCardIds.add(cardId)
    } else if (event === UnitEventType.CORRECT) {
      this.npcHp--
    }
  }

  next() {
    this.frames.shift()
  }

  tick() {
    if (this.stage !== DuelStage.Duel) {
      return
    }

    if (this.npcHp <= 0) {
      this.stage = DuelStage.Victory
    } else if (this.playerHp <= 0) {
      this.stage = DuelStage.Defeated
    }
  }
}
