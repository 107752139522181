<template>
  <div
    class="flex flex-col h-[var(--ld-viewport-height)] mx-auto bg-ld-background overflow-y-auto"
  >
    <AppBar
      :title="appBarTitle"
      @back="onBack"
    ></AppBar>

    <TrailHeader
      v-if="data.package != null && data.package.packageAccess == null"
      :package="data.package"
      @unlock="onUnlock"
    />
    <UnpublishedPackage
      v-if="data.package != null && isPackageUpdateUnreleased(data.package)"
      :package="data.package"
      @on-update="onUpdate"
    />

    <div class="flex flex-col flex-1 relative overflow-auto">
      <Loading
        v-if="data.packageLoading || data.cardsLoading"
        class="h-full"
      />

      <template v-else-if="data.package">
        <SlickList
          :list="data.cards"
          axis="y"
          class="flex-1 flex flex-col"
          useDragHandle
          @sortEnd="onCardsSortEnd"
        >
          <CardList
            class="flex-1"
            :package="data.package"
            :chapterId="chapterId"
            :cards="data.cards"
            @chapterClick="onChapterClick"
            @createCard="onCreateCard(data.cards.length)"
            @ai-generate="onAiGenerate"
          >
            <template #card="{ cardRes, noteIndex: cardIndex }">
              <SlickItem
                :key="cardRes.id"
                :index="cardIndex"
              >
                <div
                  v-if="cardIndex > 0"
                  class="h-12px"
                ></div>

                <CardPad
                  v-if="
                    isOwner &&
                    (data.cardFocus === cardIndex || invalidCards[cardRes.id])
                  "
                  :canDelete="canCardDelete(cardRes)"
                  :packageId="data.package.id"
                  :cardRes="cardRes"
                  :cardResList="data.cards"
                  :illustrationUploading="
                    data.illustrationUploading[cardRes.id]
                  "
                  :class="{
                    'card-focus': data.cardFocus === cardIndex,
                  }"
                  @create-after="onCreateCard(cardIndex + 1)"
                  @create-before="onCreateCard(cardIndex)"
                  @copy="onCardCopy(chapterId, cardRes.id, cardRes)"
                  @delete="onCardDraftDelete(cardRes, cardIndex)"
                  @update="onCardDraftUpdate(cardRes, cardIndex, $event)"
                  @add-illustration="
                    onIllustrationAdd(cardRes, cardIndex, $event)
                  "
                  @remove-illustration="onIllustrationRemove(cardRes)"
                  @change-card-type="defaultCardType = $event"
                />

                <CardBrowserPad
                  v-else
                  :cardResponse="cardRes"
                  :class="[
                    {
                      'card-focus': data.cardFocus === cardIndex,
                      'g-card-highlight': data.highlightCards[cardRes.id],
                    },
                  ]"
                  @click="onCardSelect(cardIndex)"
                />
              </SlickItem>
            </template>
          </CardList>
        </SlickList>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Code } from '@/api/code'
import {
  fetchCards,
  fetchPackageById,
  type Package,
  type CardResponse,
  type ChapterItem,
  PackageAccess,
  type PackageBasic,
  createCard,
  CardCreatedType,
  deleteCard,
  CardTypeName,
  CardUpdatedType,
  updateCard,
  moveCard,
} from '@/api/package-source'
import Loading from '@/components/Loading.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { computed, onMounted, ref, watch } from 'vue'
import { onUnmounted } from 'vue'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CardList from '@/components/Package/CardList.vue'
import TrailHeader from '@/components/TrailHeader.vue'
import UnpublishedPackage from '@/components/UnpublishedPackageHeader.vue'
import { isPackageUpdateUnreleased } from '@/utils/package'
import CardBrowserPad from '@/components/Package/CardBrowserPad.vue'
import CardPad from '@/components/Package/CardPad/CardPad.vue'
import { SlickList, SlickItem } from 'vue-slicksort'
import {
  getCardTitleForDialog,
  newClozeCard,
  newWordCard,
  validateCard,
} from '@/utils/card'
import { CardType, type Card } from '@/types/core'
import dayjs from 'dayjs'
import { debounce } from 'lodash-es'
import type { DragPosition } from '@/components/Tree/Tree.vue'
import bus, { BusEvent } from '@/bus/bus'
import AICardGenerate from '@/components/Package/AICardGenerate.vue/AICardGenerate.vue'

const store = useCommonStore()
const route = useRoute()
const router = useRouter()
const onBack = () => {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({
      name: 'shelf',
    })
  }
}

const packageId = Number(route.params.id)
const chapterId = computed(
  () => (route.query.chapterId as string | undefined) ?? 'root'
)

const isOwner = computed(() => data.package?.owned != null)

const defaultCardType = ref<CardType>(CardType.CLOZE)

const appBarTitle = computed(() => {
  if (data.package) {
    if (chapterId.value === 'root') return data.package.name
    return data.package.chapters[chapterId.value].title
  }
  return ''
})

const data = reactive({
  package: undefined as Package | undefined,
  packageLoading: true,
  cards: [] as CardResponse[],
  cardsLoading: true,
  cardFocus: -1,
  illustrationUploading: {} as Record<number, boolean>,

  highlightCards: {} as Record<number, boolean>,
})

// 如果编辑的卡片内容不合法，需要保持编辑器状态
// 这里用于存储哪些卡片未保存下来
const invalidCards = ref<Record<number, boolean>>({})

function canCardDelete(card: CardResponse) {
  // 已保存的卡片可以直接删除
  // 非最后一张草稿卡可以删除
  return card.id >= 0 || data.cards.length > 1
}

async function onCardCopy(
  chapterId: string,
  cardId: number,
  card: CardResponse
): Promise<CardResponse | undefined> {
  if (data.package == null) return
  if (cardId < 0) {
    _message.info(_t('卡片还未保存，稍后尝试'))
    return
  }

  const content = JSON.parse(card.content)

  return createCard({
    packageId: data.package.id,
    chapterId,
    content,
    afterCardId: cardId,
    createdType: CardCreatedType.DUPLICATE,
  }).then(res => {
    if (res.code === 0) {
      const index = data.cards.findIndex(item => item.id === cardId)

      if (index > -1) {
        data.cards.splice(index + 1, 0, res.data)
      }

      data.package!.cardCount += 1
      return res.data
    } else {
      _message.info(res.message)
    }
  })
}

let isDeleteConfirmOpen = false
async function onCardDraftDelete(cardRes: CardResponse, index: number) {
  if (isDeleteConfirmOpen) return

  const needConfirm = cardRes.id >= 0

  isDeleteConfirmOpen = true
  const ok = needConfirm
    ? await _confirm({
        title: _t(`删除${getCardTitleForDialog(cardRes.content)}？`),
        type: 'warn',
        content: _t('删除后无法恢复，请确认'),
        okText: _t('删除'),
        cancelText: _t('暂不'),
      })
    : true

  isDeleteConfirmOpen = false
  if (ok) {
    if (cardRes.id > 0) {
      onCardDelete(chapterId.value, cardRes.id, false)
    } else {
      data.cards.splice(index, 1)
    }
  }
}

async function onCardDelete(
  chapterId: string,
  cardId: number,
  needConfirm = true
) {
  const card = data.cards.find(item => item.id === cardId)

  if (card == null || data.package == null) return

  const ok = needConfirm
    ? await _confirm({
        title: _t(`删除${getCardTitleForDialog(card.content)}？`),
        type: 'warn',
        content: _t('删除后无法恢复，请确认'),
        okText: _t('删除'),
        cancelText: _t('暂不'),
      })
    : true

  if (ok) {
    const res = await deleteCard(data.package.id, chapterId, Number(cardId))

    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    data.package!.cardCount -= 1

    const index = data.cards.findIndex(item => item.id === cardId)

    if (index > -1) {
      data.cards.splice(index, 1)
      _message.info(_t('删除成功'))
      return true
    }
  }
}

// 每次新建一个卡片草稿时的 id，新增时自增
let cardDraftId = 1
function onCreateCard(index: number) {
  if (!isOwner.value) return

  let card: Card

  switch (defaultCardType.value) {
    case CardType.CLOZE:
      card = newClozeCard()
      break
    case CardType.EN_WORD:
      card = newWordCard()
      break
  }

  const newCardRes = {
    id: -cardDraftId,
    content: JSON.stringify(card),
    contentHash: 0,
    createdType: CardCreatedType.NORMAL,
    updatedType: CardUpdatedType.NORMAL,
    createdAt: dayjs().format('YYYY-DD-MM'),
    updatedAt: dayjs().format('YYYY-DD-MM'),
    authorId: '',
  } as CardResponse

  cardDraftId++
  data.cards.splice(index, 0, newCardRes)
  onCardSelect(index)
}

function onCardDraftUpdate(
  cardRes: CardResponse,
  noteIndex: number,
  newCard: Card
) {
  cardRes.content = JSON.stringify(newCard)

  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = noteIndex - 1; index >= 0; index--) {
      if (data.cards[index].id > 0) {
        afterCardId = data.cards[index].id
        break
      }
    }

    for (let index = noteIndex + 1; index < data.cards.length; index++) {
      if (data.cards[index].id > 0) {
        beforeCardId = data.cards[index].id
        break
      }
    }

    saveCreatedCard(cardRes, newCard, afterCardId, beforeCardId)
  } else {
    saveUpdatedCard(cardRes.id, newCard)
  }
}

const saveUpdatedCard = debounce((cardId: number, content: Card) => {
  if (validateCard(content)) {
    invalidCards.value[cardId] = true
    return
  }

  invalidCards.value[cardId] = false
  return updateCard(cardId, content).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    const index = data.cards.findIndex(item => item.id === res.data.id)

    if (index > -1) {
      data.cards.splice(index, 1, res.data)
    }

    data.illustrationUploading[cardId] = false
  })
}, 500)

const saveCreatedCard = debounce(
  (
    cardRes: CardResponse,
    card: Card,
    afterCardId?: number,
    beforeCardId?: number
  ) => {
    if (data.package == null) return

    if (validateCard(card)) {
      invalidCards.value[cardRes.id] = true
      return
    }

    createCard({
      packageId: data.package.id,
      chapterId: chapterId.value,
      content: card,
      afterCardId: afterCardId,
      beforeCardId: beforeCardId,
      createdType: CardCreatedType.NORMAL,
    }).then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      data.package!.cardCount += 1
      cardRes.id = res.data.id
      cardRes.content = res.data.content
    })
  },
  500
)

function onIllustrationAdd(
  cardRes: CardResponse,
  cardIndex: number,
  assetId: string
) {
  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = cardIndex - 1; index >= 0; index--) {
      if (data.cards[index].id > 0) {
        afterCardId = data.cards[index].id
        break
      }
    }

    for (let index = cardIndex + 1; index < data.cards.length; index++) {
      if (data.cards[index].id > 0) {
        beforeCardId = data.cards[index].id
        break
      }
    }

    const card = {
      type: CardType.CLOZE,
      content: [],
      analysis: [],
      altContents: [],
      illustration: assetId,
    } as Card
    saveCreatedCard(cardRes, card, afterCardId, beforeCardId)
    return
  }

  data.illustrationUploading[cardRes.id] = true
  const cardContent = JSON.parse(cardRes.content) as Card

  saveUpdatedCard(cardRes.id, {
    ...cardContent,
    illustration: assetId,
  })
}

function onIllustrationRemove(cardRes: CardResponse) {
  if (cardRes.id < 0) return

  const cardContent = JSON.parse(cardRes.content) as Card

  const newContent = {
    ...cardContent,
  }
  delete newContent.illustration
  saveUpdatedCard(cardRes.id, newContent)
}

function onCardsSortEnd({
  newIndex,
  oldIndex,
}: {
  newIndex: number
  oldIndex: number
}) {
  if (newIndex === oldIndex) return
  const focusedCardId = data.cards[data.cardFocus].id

  const sourceCard = data.cards[oldIndex]

  data.cards.splice(oldIndex, 1)
  data.cards.splice(newIndex, 0, sourceCard)

  // 如果移动的是一张草稿卡，则不需要调用接口，不影响
  if (sourceCard.id < 0) {
    return
  }

  const beforeCardId = data.cards[newIndex + 1]?.id

  if (beforeCardId != null) {
    moveCardPad(sourceCard.id, beforeCardId, 'before')
  } else {
    moveCardPad(sourceCard.id, beforeCardId, 'bottom')
  }

  data.cardFocus = data.cards.findIndex(item => item.id === focusedCardId)
}

async function moveCardPad(
  sourceCardId: number,
  targetCardId?: number,
  position?: DragPosition
) {
  if (data.package == null) return

  await moveCard({
    sourcePkgId: data.package.id,
    cardId: sourceCardId,
    beforeCardId: position === 'bottom' ? undefined : targetCardId,
    sourceChapterId: chapterId.value,
    targetChapterId: chapterId.value,
  })
}

async function fetchPackage() {
  data.packageLoading = true

  try {
    const res = await fetchPackageById(packageId)

    if (res.code === Code.PackageNotFound) {
      router.replace({
        name: '404',
      })
      return
    }

    data.package = res.data

    defaultCardType.value = {
      [CardTypeName.CLOZE]: CardType.CLOZE,
      [CardTypeName.WORD]: CardType.EN_WORD,
    }[data.package.owned?.defaultCardType ?? CardTypeName.CLOZE]
  } finally {
    data.packageLoading = false
  }
}
const cardListCache = ref<Record<string, CardResponse[]>>({})

async function fetchCardList(chapterId: string) {
  if (chapterId == null) return []

  try {
    if (cardListCache.value[chapterId] != null) {
      data.cards = cardListCache.value[chapterId]
    } else {
      data.cardsLoading = true
      data.cards = (await fetchCards(packageId, chapterId)).data.cards
      cardListCache.value[chapterId] = data.cards
      data.cards.forEach(c => {
        store.setCardResponseCache(c.id, c)
      })
    }
  } finally {
    data.cardsLoading = false
  }
}
function onChapterClick(chapter: ChapterItem) {
  router.push({
    query: {
      ...route.query,
      chapterId: chapter.id,
    },
  })
  fetchCardList(String(chapter.id))
}

function onRouteChange() {
  fetchCardList(chapterId.value)
}

function onUnlock(access: PackageAccess) {
  data.package!.packageAccess = access
}

function onUpdate(pkg: PackageBasic) {
  if (data.package) {
    Object.assign(data.package, pkg)
  } else {
    fetchPackage()
  }
}

onMounted(() => {
  window.addEventListener('popstate', onRouteChange)
})
onUnmounted(() => {
  window.removeEventListener('popstate', onRouteChange)
})

// 如果这个卡包是空的，则新建一张无法删除的草稿卡片
function createDraftCards() {
  if (data.package?.cardCount === 0) {
    onCreateCard(0)
  }
}

onInit(async () => {
  data.packageLoading = true
  data.cardsLoading = true
  await fetchPackage()
  if (data.package == null) return
  await fetchCardList(chapterId.value)

  watch(
    () => [data.cards.length, data.package?.cardCount],
    ([cardsLength, cardCount]) => {
      if (cardsLength === 0 && cardCount === 0) {
        createDraftCards()
      }
    },
    { immediate: true }
  )
})

function onCardSelect(index: number) {
  data.cardFocus = index
  const cardId = data.cards[index]?.id

  focusToCard(cardId)
}

function highlightCard(id: number) {
  data.highlightCards[id] = true
  setTimeout(() => {
    data.highlightCards[id] = false
  }, 1000)
}

function focusToCard(cardId: number) {
  setTimeout(() => {
    bus.emit(BusEvent.CardFocus, cardId)
  })
}

function onAiGenerate() {
  if (data.package == null) return

  _openDialog(AICardGenerate, {
    rootClass: 'g-dialog',
    props: {
      packageId: data.package.id,
      chapterId: chapterId.value,
      onCardsCreated(cards: CardResponse[]) {
        data.cards.push(...cards)

        cards.forEach(card => highlightCard(card.id))
        const firstCard = cards[0]
        if (firstCard) {
          focusToCard(firstCard.id)
        }
      },
    },
    dialog: {
      dismissableMask: true,
      showHeader: false,
      pt: {
        content: {
          class: 'p-4 bg-ld-background',
        },
      },
    },
  })
}
</script>

<style scoped>
.card-focus {
  border-color: var(--ld-brand-500);
}
</style>
