<template>
  <PreLoading
    v-if="preLoading"
    class="h-full"
  />

  <CheckInView
    v-else-if="checkInData != null && showCheckIn"
    class="h-full"
    :check-data="checkInData"
    @done="onCheckInDone"
  ></CheckInView>

  <DailyTasksView
    v-else-if="dailyTaskResponse != null && showDailyTasks"
    :response="dailyTaskResponse"
    @done="onBack"
  ></DailyTasksView>

  <template v-else-if="completedStats != null">
    <ChallengeStageEndView
      v-if="store.stageUnit?.challenge"
      class="px-24px py-16px h-full bg-ld-background"
      :duration="completedStats.duration"
      :exp="completedStats.exp"
      :star="completedStats.star"
      @continue="onContinue()"
    ></ChallengeStageEndView>

    <LessonEndView
      v-else
      class="px-24px py-16px h-full bg-ld-background"
      :duration="completedStats.duration"
      :count="completedStats.debutCount + completedStats.reviewCount"
      :exp="completedStats.exp"
      @continue="onContinue"
      @review="onCardsReview"
    ></LessonEndView>
  </template>

  <RatioSpacedContainer
    v-else-if="allCardsCompleted"
    class="text-center h-full"
  >
    <template v-if="pending">
      <Loading />

      <div class="mt-2">正在提交本次学习数据</div>
    </template>

    <template v-else>
      <div class="mb-2">网络异常，请重试</div>

      <Button
        label="重新提交"
        @click="completeReportAgain"
      ></Button>
    </template>
  </RatioSpacedContainer>

  <RatioSpacedContainer
    v-else-if="store.stageUnit == null"
    class="text-center h-full"
  >
    {{ $t('暂无正在学习的单元') }}
  </RatioSpacedContainer>

  <RatioSpacedContainer
    v-else-if="store.stageUnit.schedules.length === 0"
    class="text-center h-full"
  >
    {{ $t('该学习单元为空') }}
  </RatioSpacedContainer>

  <Loading
    v-else-if="pending"
    class="text-center h-full"
  ></Loading>

  <div
    v-else
    class="bg-gray-100"
  >
    <div class="flex flex-col h-[var(--ld-viewport-height)] mx-auto">
      <ThoughtsMemoQueue
        v-if="store.stageUnit.queueType == QueueType.ThoughtsMemo"
        @close="onClose"
      />

      <StagesQueue
        v-else-if="store.stageUnit.queueType === QueueType.Stages"
        @close="onClose"
      />

      <FlashQueue
        v-else-if="store.stageUnit.queueType === QueueType.Flash"
        @close="onClose"
      />

      <DuelQueue
        v-else-if="store.stageUnit.queueType === QueueType.Duel"
        @close="onClose"
        @left-blood-update="onLeftBloodUpdate"
      />

      <InsertQueue
        v-else
        @close="onClose"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { QueueType } from './queue'
import ThoughtsMemoQueue from './ThoughtsMemoQueue/ThoughtsMemoQueue.vue'
import StagesQueue from './StagesQueue/StagesQueue.vue'
import FlashQueue from './FlashQueue/FlashQueue.vue'
import DuelQueue from './DuelQueue/DuelQueue.vue'
import InsertQueue from './InsertQueue/InsertQueue.vue'
import { Howl } from 'howler'
import { ref, computed, onMounted, onUnmounted, provide } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import {
  cancelReport,
  completedStats,
  pending,
  completeReport,
  checkInData,
  dailyTaskResponse,
} from './report'
import db from '@/db'
import PreLoading from './PreLoading.vue'
import { preLoadCards } from './preload'
import UnitReview from '@/pc/components/UnitReview.vue'
import MobileUnitReview from '@/mobile/pages/UnitReview.vue'
import CheckInView from '@/components/CheckInView.vue'
import LessonEndView from '@/components/LessonEndView.vue'
import ChallengeStageEndView from '@/components/ChallengeStageEndView.vue'
import DailyTasksView from './DailyTasksView.vue'
import { markPopped } from '@/api/user'

provide('onCardStart', onCardStart)
provide('onCardEnd', onCardEnd)

provide('onAudioPlay', onAudioPlay)

const store = useCommonStore()
const router = useRouter()

const preLoading = ref(true)
const duelLeftBlood = ref(1)
const showDailyTasks = ref(false)

const correctSound = new Howl({
  src: ['/audio/public_correct.wav'],
  html5: true,
})

const inCorrectSound = new Howl({
  src: ['/audio/public_incorrect.wav'],
  html5: true,
})

const hitSound = new Howl({
  src: ['/audio/public_hit.MP3'],
  html5: true,
})

const allCardsCompleted = computed(() => {
  if (store.stageUnit == null) return false

  return (
    store.stageUnit.schedules.length > 0 &&
    store.stageUnit.completedCards.length === store.stageUnit.schedules.length
  )
})

const canShowDailyTasksView = computed(() => {
  if (dailyTaskResponse.value == null) return false

  // 如果存在未领取的任务，不管完成未完成都要展示进度
  return dailyTaskResponse.value.tasks.some(item => !item.rewardClaimed)
})

async function preLoadUnitCards() {
  if (store.stageUnit == null) {
    preLoading.value = false
    return
  }

  const cards: string[] = []
  store.stageUnit.schedules.forEach(item => {
    if (!store.stageUnit!.completedCards?.includes(item.cardId)) {
      cards.push(item.content)
    }
  })

  await preLoadCards(cards)
  preLoading.value = false
}

preLoadUnitCards()

onMounted(() => {
  correctSound.load()
  inCorrectSound.load()

  window.onbeforeunload = () => true
})

onUnmounted(() => {
  correctSound.unload()
  inCorrectSound.unload()

  window.onbeforeunload = null
})

onBeforeRouteLeave(async (_to, _from, next) => {
  const quitConfirmed = await quitConfirm()

  if (quitConfirmed) {
    if (!store.stageUnit?.completed) {
      cancelReport()
    }

    if (store.stageUnit) {
      delete db.unitProgressCache[store.stageUnit.queueType]
      delete db.unitProgressCache[QueueType.Duel]
    }

    next()
  } else {
    throw new Error('cancel quit')
  }
})

function onCardsReview() {
  if (_global.isPcMode) {
    _openDialog(UnitReview, {
      rootClass: 'min-w-600px max-w-900px min-h-600px',
      props: {
        stageId: store.stageUnit!.atlasStageId,
      },
      dialog: {
        showHeader: false,
        pt: {
          content: {
            class: 'flex-1 p-0',
          },
        },
      },
    })
  } else {
    _openDialog(MobileUnitReview, {
      fullscreenInMobile: true,
      props: {
        stageId: store.stageUnit!.atlasStageId,
      },
      dialog: {
        showHeader: false,
        pt: {
          content: {
            class: 'p-0',
          },
        },
      },
    })
  }
}

async function quitConfirm(): Promise<boolean> {
  const events = store.stageUnit?.events
  const completeCardsCount = store.stageUnit?.completedCards.length ?? 0

  if (events == null) return true

  if (store.stageUnit?.completed || store.stageUnit?.cancelled) return true

  // 如果已经学习过，退出时需要提示
  const hasEvent =
    Object.values(events).some(item => item.length > 0) ||
    completeCardsCount > 0

  if (hasEvent) {
    const ok = await _confirm({
      type: 'warn',
      icon: {
        name: 'energy-broken',
        type: 'svg',
      },
      content: '一个单元很快，再坚持一会吧！\n现在退出，面包就浪费了哦',
      okText: '放弃进度，退出学习',
      cancelText: '坚持一下，继续学习',
    })

    if (!ok) return false
  }

  return true
}

function completeReportAgain() {
  if (store.stageUnit == null) return

  if (store.stageUnit.queueType === QueueType.Duel) {
    completeReport(duelLeftBlood.value)
  } else {
    completeReport()
  }
}

function onLeftBloodUpdate(val: number) {
  duelLeftBlood.value = val
}

async function onClose() {
  const quitConfirmed = await quitConfirm()

  if (quitConfirmed) {
    await cancelReport()

    onBack()
  }
}
const showCheckIn = ref(false)
// 学习完成继续
function onContinue() {
  if (checkInData.value) {
    // 上报签到
    markPopped()
    showCheckIn.value = true
  } else if (canShowDailyTasksView.value) {
    showDailyTasks.value = true
  } else {
    onBack()
  }
}

function onCheckInDone() {
  if (canShowDailyTasksView.value) {
    showCheckIn.value = false
    showDailyTasks.value = true
  } else {
    onBack()
  }
}

function onBack() {
  router.push({
    name: 'atlas',
    query: {
      challengeStage: store.stageUnit?.challenge?.index,
    },
  })
}

function onAudioPlay(type: 'correct' | 'incorrect' | 'hit') {
  switch (type) {
    case 'correct':
      correctSound.play()
      break
    case 'incorrect':
      inCorrectSound.play()
      break
    case 'hit':
      hitSound.play()
      break
  }
}

let cardStartTime = 0
function onCardStart() {
  cardStartTime = Math.floor(Date.now() / 1000)
}

function onCardEnd(cardId: number) {
  const end = Math.floor(Date.now() / 1000)

  if (cardStartTime > 0) {
    store.addCardDuration(cardId, end - cardStartTime)
  }
}
</script>
<style scoped></style>
