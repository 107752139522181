// this file is the very first file to run
// we use this file to init _global and store something
// that can be used by all other files
import db from './db'

export type Env = 'local' | 'staging' | 'prod' | 'gray'
export type ApiEnv = 'staging' | 'prod'

const OSS_URL_STAGING = 'https://cdn.lingduck.top'
const OSS_URL_PROD = 'https://cdn.lingduck.cn'

// 不直接暴露，使用相关的方法去访问
const env = parseEnv(location.hostname)

window.onInit = (cb: () => void) => cb()

window._global = {
  sentryEnvironment: env,

  get isLocal() {
    return env === 'local'
  },
  get isStaging() {
    return env === 'staging'
  },
  get isProd() {
    return env === 'prod' || env === 'gray'
  },

  aliyunCaptchaSceneId: 'n9j3ojpv',
  aliyunCaptchaPrefix: '1ynvpx',

  get apiEnv() {
    if (this.isProd) {
      return 'prod'
    }

    return db.debug.apiEnv
  },

  // 某些服务配置了固定跳转到生产地址，例如微信登录
  // 生产网关增加了特殊配置，从而使得其他环境也可用
  get prodGatewayPrefix(): string {
    return {
      local: '/__local__',
      staging: '/__staging__',
      gray: '/__gray__',
      prod: '',
    }[env]
  },

  // LingDuck 帮助文档
  helpLink: 'https://help.lingduck.cn',

  bilibiliLink: 'https://space.bilibili.com/2006586621',
  xhsLink: 'https://www.xiaohongshu.com/user/profile/654b4341000000000301d1a4',

  get pcLink(): string {
    return this.isProd ? 'www.lingduck.cn' : 'www.lingduck.top'
  },

  homePage: {
    name: 'atlas',
  },

  // 兔小巢
  txcProductId: 669589,

  gitCommit: __GIT_COMMIT__,

  appMode: __APP_MODE__,
  get isPcMode() {
    return this.appMode === 'pc'
  },
  get isMobileMode() {
    return this.appMode === 'mobile'
  },

  isMac: /(Macintosh|iPad)/i.test(navigator.userAgent),

  now(): Date {
    if (_store.debugTs) {
      return new Date(_store.debugTs)
    }

    return new Date()
  },

  get isInsideWechat() {
    return /MicroMessenger/i.test(navigator.userAgent)
  },

  // 微信开放平台，网站应用
  wxAppId: 'wxe2e0a1b8eb6b4c08',

  assetUrl(assetId, imgStyle?: string) {
    const ossURL = this.apiEnv === 'prod' ? OSS_URL_PROD : OSS_URL_STAGING
    const url = `${ossURL}/${assetId}`

    if (imgStyle) {
      return url + `?x-oss-process=style/${imgStyle}`
    }

    return url
  },
}

function parseEnv(host: string): Env {
  switch (host) {
    case 'localhost':
      return 'local'

    case 'lingduck.top':
    case 'm.lingduck.top':
      return 'staging'

    case 'gray.lingduck.top':
    case 'm.gray.lingduck.top':
      return 'gray'

    case 'lingduck.cn':
    case 'm.lingduck.cn':
      return 'prod'

    default:
      if (location.port !== '') return 'local'
      return 'prod'
  }
}
