import api from './base'
import { readImageSize } from '@/utils'

import type { CommonResponse } from './base'
import type { IdentityTag } from '@/types/core'
import type { UserInfo } from './auth'
import type { ContentTagWithParent } from './package-source'

type ThirdParty = 'WECHAT'

export function unbindWechat() {
  return api.post<unknown, CommonResponse<void>>('/v1/users/wechat/unbind')
}
export function bindWechat(code: string) {
  return api.post<unknown, CommonResponse<void>>('/v1/users/wechat/bind', {
    code,
    wechatType: 'WEB',
  })
}

export function unbindEmail() {
  return api.post<unknown, CommonResponse<void>>('/v1/users/email/unbind')
}
export function bindEmail(payload: {
  email: string
  code: string
  password: string
}) {
  return api.post<unknown, CommonResponse<void>>(
    '/v1/users/email/bind',
    payload
  )
}

type PatchUserPayload = {
  nickname?: string
  contentTags?: string[]
}
export function patchUser(payload: PatchUserPayload) {
  return api.patch<unknown, UserInfo>('/v1/users', payload)
}

export function fetchThirdParties() {
  return api.post<unknown, ThirdParty[]>('/v1/users/thirdparties')
}

export function updateIdentityTags(identityTags: string[]) {
  return api.put<unknown, never>('/v1/users/identity_tags', {
    identityTags,
  })
}

export function fetchUserTags() {
  return api.get<
    unknown,
    {
      contentTagResponses: ContentTagWithParent[]
      identityTagResponses: IdentityTag[]
    }
  >('/v1/users/tags')
}

export function followContentTag(key: string) {
  return api.put<unknown, CommonResponse<never>>('/v1/users/content_tags', {
    action: 'FOLLOW',
    contentTagKey: key,
  })
}

export function unfollowContentTag(key: string) {
  return api.put<unknown, CommonResponse<never>>('/v1/users/content_tags', {
    action: 'CANCEL',
    contentTagKey: key,
  })
}

export async function uploadImage(file: File) {
  const fd = new FormData()

  const size = await readImageSize(file)

  fd.append('file', file)
  fd.append('width', size.width.toString())
  fd.append('height', size.height.toString())

  return api.post<unknown, CommonResponse<{ assetId: string }>>(
    '/v1/users/image/upload',
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}

export async function uploadAvatar(file: File) {
  const fd = new FormData()

  fd.append('file', file)

  return api.post<unknown, CommonResponse<{ assetId: string }>>(
    '/v1/users/avatar/upload',
    fd,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}

export interface UserVipInfo {
  expireAt: string
  type: 'NORMAL'
}

export function fetchUserVip() {
  return api.get<unknown, { vip: UserVipInfo | null }>('/v1/users/vip')
}

export function putToBookShelf(packageId: number) {
  return api.post<unknown, CommonResponse<never>>('/v1/users/bookshelf', {
    packageId,
  })
}

export async function hasPkgGift() {
  return api.get<never, { obtainedGift: boolean }>('/v1/users/has-pkg-gift')
}
export async function pkgGift(packageId: number) {
  return api.post<unknown, CommonResponse<never>>('/v1/users/pkg-gift', {
    packageId,
  })
}

export type CheckInCtRes = {
  // 连续签到天数
  continuousDays: number
  // 最大连续签到天数
  maxContinuousDays: number
  // 累计签到天数
  accumulatedDays: number
}
// 获取连签信息
export async function checkInCt() {
  return api.get<unknown, CheckInCtRes>('/v1/users/check-in/ct')
}

export interface CheckInStatsItem {
  date: string
  type: 'NORAML' | 'MAKE_UP' | null
}
// 获取签到统计数据
export async function checkInStats(startDate: string, endDate: string) {
  return api.get<
    unknown,
    {
      stats: CheckInStatsItem[]
    }
  >(`/v1/users/check-in/stats?startDate=${startDate}&endDate=${endDate}`)
}

export function removeShelfPackageById(id: number) {
  return api.delete<never, CommonResponse<never>>(`/v1/users/pkg/${id}`)
}

// 标记已经弹出过签到单窗
export function markPopped() {
  return api.post<unknown, CommonResponse<never>>(
    '/v1/users/check-in/mark-popped'
  )
}

export type UserAssets = {
  exp: number
  diamond: number
}
// 获取当前用户资产信息经验值钻石
export function userAssets() {
  return api.get<unknown, UserAssets>('/v1/users/assets')
}

export interface EnergyResponse {
  energyLimit: number
  currentEnergy: number
  recoverInterval: 0

  // date eg: '2024-08-13T13:50:14.792Z'
  nextTicketAt?: string
}

export async function fetchEnergy(): Promise<EnergyResponse> {
  return api.get<unknown, EnergyResponse>('/v1/users/energy')
}

export async function fetchVipLeftFreeTimes() {
  return api.post<unknown, { leftTimes: number }>(
    '/v1/users/users/vip/energy/left-times'
  )
}

export async function vipExchangeEnergyFree() {
  return api.post<unknown, CommonResponse<EnergyResponse>>(
    '/v1/users/users/vip/add-energy'
  )
}

// 领取内测礼物 1023 没有可领取的内测福利 1024 已经领取过内测福利
export async function claimAlphaGift() {
  return api.post<unknown, CommonResponse<never>>('/v1/users/alpha/claim-gift')
}

export async function uploadWechatOpenId(openId: string) {
  return api.post<unknown>('/v1/users/wechat/mp/open-id', {
    openId,
  })
}
