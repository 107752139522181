import type { CardSchedule, UnitEventType } from '@/api/learn'
import {
  Queue,
  type ConcreteFrame,
  type Frame,
  type FrameConfig,
  getCardConfigs,
  getAltCards,
  orderFrames,
} from '../queue'
import {
  CardType,
  ClozeCardFaceType,
  LearnStatus,
  EnWordCardFaceType,
  Interaction,
  type Card,
} from '@/types/core'
import { parseCard } from '@/utils/card'

// 队列学习阶段
export enum QueueStage {
  // 基础
  Base = 'Base',

  // 挑战
  Challenge = 'Challenge',

  // 错题
  WrongQuestions = 'WrongQuestions',

  // 完成
  Done = 'Done',
}

export type QueueStagesConfig = {
  [QueueStage.Base]: FrameConfig[]
  [QueueStage.Challenge]: FrameConfig[]
  [QueueStage.WrongQuestions]: FrameConfig[]
}

const defaultBaseConfig: FrameConfig[] = [
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Choice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Judgement,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Judgement,
    status: LearnStatus.REVIEW,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Choice,
    status: LearnStatus.REVIEW,
    interaction: Interaction.Practice,
  },

  {
    cardType: CardType.EN_WORD,
    cardFace: EnWordCardFaceType.WordChoice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },

  {
    cardType: CardType.EN_WORD,
    cardFace: EnWordCardFaceType.PickImage,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
]

const defaultChallengeConfig: FrameConfig[] = [
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Choice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.EN_WORD,
    cardFace: EnWordCardFaceType.WordChoice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.EN_WORD,
    cardFace: EnWordCardFaceType.PickImage,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
]

const defaultWrongQuestionConfig: FrameConfig[] = [
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.MinimalChoice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Judgement,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Judgement,
    status: LearnStatus.REVIEW,
    interaction: Interaction.Practice,
  },
  {
    cardType: CardType.CLOZE,
    cardFace: ClozeCardFaceType.Choice,
    status: LearnStatus.REVIEW,
    interaction: Interaction.Practice,
  },

  {
    cardType: CardType.EN_WORD,
    cardFace: EnWordCardFaceType.WordChoice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },

  {
    cardType: CardType.EN_WORD,
    cardFace: EnWordCardFaceType.WordChoice,
    status: LearnStatus.DEBUT,
    interaction: Interaction.Practice,
  },
]

export const defaultConfig: QueueStagesConfig = {
  [QueueStage.Base]: defaultBaseConfig,
  [QueueStage.Challenge]: defaultChallengeConfig,
  [QueueStage.WrongQuestions]: defaultWrongQuestionConfig,
} as QueueStagesConfig

type WrongCardFrames = {
  cardId: number
  frames: ConcreteFrame[]
}

export interface StagesQueueState {
  stage: QueueStage
  frames: Frame[]
  wrongFrames: WrongCardFrames[]
  totalFrames: number
  activeCardEvents: {
    cardId: number
    event: UnitEventType
  }[]

  challengeCardIds: number[]
  wrongCardIds: number[]
}

export class StagesQueue extends Queue<QueueStagesConfig, StagesQueueState> {
  constructor(cards: CardSchedule[], config: QueueStagesConfig) {
    super(cards, config)

    super.frames = this._genBaseFrames()
    this.totalFrames = this.frames.length
    this.next()
  }

  totalFrames = 0

  stage: QueueStage = QueueStage.Base

  wrongFrames: WrongCardFrames[] = []

  challengeCardIds: Set<number> = new Set()
  wrongCardIds: Set<number> = new Set()

  getState(): StagesQueueState {
    return {
      stage: this.stage,
      frames: this.frames,
      wrongFrames: this.wrongFrames,
      totalFrames: this.totalFrames,
      activeCardEvents: this._activeCardEvents,

      challengeCardIds: [...this.challengeCardIds],
      wrongCardIds: [...this.wrongCardIds],
    }
  }

  setState(state: StagesQueueState) {
    this.stage = state.stage
    this.frames = state.frames
    this.wrongFrames = state.wrongFrames
    this.totalFrames = state.totalFrames
    this._activeCardEvents = state.activeCardEvents
    this.challengeCardIds = new Set(state.challengeCardIds)
    this.wrongCardIds = new Set(state.wrongCardIds)
  }

  private _genBaseFrames(): Frame[] {
    const orderedCards = this._orderCards()
    const frames: ConcreteFrame[] = []

    for (const item of orderedCards) {
      const card = JSON.parse(item.content) as Card
      const configs = getCardConfigs(this._config[QueueStage.Base], {
        cardType: card.type,
        status: item.cardStatus,
        random: false,
      })

      const altCards = getAltCards(this._cards, item.cardId, card)

      for (const config of configs) {
        const parsedResult = parseCard(card)

        if (parsedResult.error == null) {
          frames.push({
            type: 'concrete',
            status: item.cardStatus,
            cardId: item.cardId,
            face: {
              type: config.cardFace,
              cardId: item.cardId,
              card: parsedResult.card,
              interaction: config.interaction,
              altCards,
              style: {
                ...config.style,
              },
            },
          } as ConcreteFrame)
        }
      }
    }

    return orderFrames(
      frames,
      orderedCards.map(item => item.cardId)
    )
  }

  private _genChallengeFrames(): ConcreteFrame[] {
    if (this.challengeCardIds.size === 0) {
      return []
    }

    const frames: ConcreteFrame[] = []

    for (const id of this.challengeCardIds) {
      const cardInfo = this._getCardInfo(id)!

      const card = JSON.parse(cardInfo.content) as Card
      const configs = getCardConfigs(this._config[QueueStage.Challenge], {
        cardType: card.type,
        status: cardInfo.cardStatus,
        random: false,
      })

      const altCards = getAltCards(this._cards, cardInfo.cardId, card)
      const parsedResult = parseCard(card)

      if (parsedResult.error != null) return frames

      for (const config of configs) {
        frames.push({
          type: 'concrete',
          status: config.status,
          cardId: cardInfo.cardId,
          face: {
            type: config.cardFace,
            cardId: cardInfo.cardId,
            card: parsedResult.card,
            interaction: config.interaction,
            altCards,
            style: {
              ...config.style,
            },
          },
        } as ConcreteFrame)
      }
    }

    return frames
  }

  private _genWrongQuestionsFrames(): WrongCardFrames[] {
    if (this.wrongCardIds.size === 0) return []

    const frames: WrongCardFrames[] = []

    const wrongQuestionsConfig = this._config[QueueStage.WrongQuestions]
    for (const id of this.wrongCardIds) {
      const cardInfo = this._getCardInfo(id)!

      const card = JSON.parse(cardInfo.content) as Card
      const configs = getCardConfigs(wrongQuestionsConfig, {
        cardType: card.type,
        random: true,
        status: cardInfo.cardStatus,
      })
      const wrongFrame: WrongCardFrames = {
        cardId: id,
        frames: [],
      }

      const altCards = getAltCards(this._cards, cardInfo.cardId, card)
      const parsedResult = parseCard(card)

      if (parsedResult.error != null) return frames

      for (const config of configs) {
        wrongFrame.frames.push({
          type: 'concrete',
          status: config.status,
          cardId: cardInfo.cardId,
          face: {
            type: config.cardFace,
            cardId: cardInfo.cardId,
            card: parsedResult.card,
            interaction: config.interaction,
            altCards,
            style: {
              ...config.style,
            },
          },
        } as ConcreteFrame)
      }
      frames.push(wrongFrame)
    }

    return frames
  }

  onEvent(cardId: number, event: UnitEventType) {
    this._activeCardEvents.unshift({
      cardId,
      event,
    })
  }

  tick() {
    switch (this.stage) {
      case QueueStage.Done:
        return
      case QueueStage.Base: {
        if (this.frames.length === 0) {
          this._nextToChallengeStage()
          return
        }

        const frame = this.frames.shift()!

        if (frame.type === 'virtual') return

        // 全对，没有做错过的卡片进入挑战队列
        if (
          !this._activeCardEvents.some(item => item.event === 'WRONG') &&
          !this.wrongCardIds.has(frame.cardId!)
        ) {
          this.challengeCardIds.add(frame.cardId!)
        }

        // 做错过，则进入「错题」队列
        if (this._activeCardEvents.some(item => item.event === 'WRONG')) {
          this.wrongCardIds.add(frame.cardId!)
        }

        if (this.frames.length === 0) {
          this._nextToChallengeStage()
          return
        }
        break
      }
      case QueueStage.Challenge: {
        if (this.frames.length === 0) {
          this._nextToWrongQuestionStage()
          return
        }

        this.frames.shift()

        // 做错过，则进入「错题」队列
        const wrongEvents = this._activeCardEvents.filter(
          item => item.event === 'WRONG'
        )

        for (const item of wrongEvents) {
          this.wrongCardIds.add(item.cardId)
        }

        if (this.frames.length === 0) {
          this._nextToWrongQuestionStage()
          return
        }

        break
      }
      case QueueStage.WrongQuestions: {
        if (this.frames.length === 0) {
          this.stage = QueueStage.Done
          return
        }

        const frame = this.frames.shift()!

        if (frame.type === 'virtual') return

        const wrongFrame = this.wrongFrames.find(
          item => item.cardId === frame.cardId
        )!

        // 做对一次，直接弃牌
        const correct =
          this._activeCardEvents.some(item => item.event === 'CORRECT') &&
          this._activeCardEvents.every(item => item.event !== 'WRONG')
        if (correct) {
          wrongFrame.frames.length = 0
        } else if (wrongFrame.frames.length > 0) {
          // 如果做错了，则从该卡片的错误卡面中再拿出一张
          this.frames.push(wrongFrame.frames.pop()!)
        }

        if (this.frames.length === 0) {
          this.stage = QueueStage.Done
          return
        }
      }
    }

    this._activeCardEvents = []
  }

  next() {
    if (this.frames.length > 0) {
      return
    }

    if (this.stage === QueueStage.Base) {
      this._nextToChallengeStage()
      return
    }

    if (this.stage === QueueStage.Challenge) {
      this._nextToWrongQuestionStage()
      return
    }

    if (this.stage === QueueStage.WrongQuestions) {
      this.stage = QueueStage.Done
    }
  }

  _nextToChallengeStage() {
    this._activeCardEvents = []
    const frames = this._genChallengeFrames()

    if (frames.length > 0) {
      this.frames.push(...frames)
      this.stage = QueueStage.Challenge
      this.totalFrames = frames.length
    } else {
      this._nextToWrongQuestionStage()
    }
  }

  _nextToWrongQuestionStage() {
    this._activeCardEvents = []
    this.wrongFrames = this._genWrongQuestionsFrames()

    if (this.wrongFrames.length > 0) {
      this.wrongFrames.forEach(item => {
        if (item.frames.length > 0) {
          this.frames.push(item.frames.pop()!)
        }
      })
      this.stage = QueueStage.WrongQuestions
      this.totalFrames = this.frames.length
    } else {
      this.stage = QueueStage.Done
    }
  }
}
