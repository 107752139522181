<template>
  <div>
    <ClozeCardEssence
      v-if="props.card.type === CardType.CLOZE"
      :card="props.card"
    />

    <WordCardEssence
      v-if="props.card.type === CardType.EN_WORD"
      :card="props.card"
    />
  </div>
</template>

<script lang="ts" setup>
import { type Card, CardType } from '@/types/core'
import ClozeCardEssence from './ClozeCard.vue'
import WordCardEssence from './WordCard.vue'

const props = defineProps<{
  card: Card
}>()
</script>

<style scoped></style>
