<template>
  <div ref="mainRef">
    <label
      data-required
      class="block text-900 text-xl font-medium"
    >
      内容
    </label>

    <ClozeEditor
      class="my-2 w-full px-4 py-2 border-1 rounded-8px border-[var(--surface-300)]"
      :content="props.card.content"
      :limit="1000"
      @update="onChange({ content: $event })"
    />

    <div class="hstack">
      <label class="block text-900 text-xl font-medium">内容改述</label>
      <Button @click="onAlternativeContentAdd">{{ _t('添加') }}</Button>
    </div>

    <div class="vstack">
      <Empty
        v-if="altContents.length === 0"
        :text="_t('未添加内容改述')"
      />

      <div
        v-for="(item, i) in altContents"
        :key="i"
        class="hstack w-full"
      >
        <ClozeEditor
          class="mt-2 w-full px-4 py-2 border-1 rounded-8px border-[var(--surface-300)]"
          :content="item"
          :limit="1000"
          @update="onAlternativeContentChange(i, $event)"
        />

        <Button
          icon="pi pi-times"
          scene="wrong"
          aria-label="Cancel"
          @click="onAlternativeContentDelete(i)"
        />
      </div>
    </div>

    <div class="mt-4">
      <label class="block text-900 text-xl font-medium">解析</label>

      <ClozeEditor
        class="mt-2 w-full px-4 py-2 border-1 rounded-8px border-[var(--surface-300)]"
        :limit="1000"
        :content="props.card.analysis ?? []"
        @update="onChange({ analysis: $event })"
      />
    </div>

    <div class="mt-4">
      <label class="block text-900 text-xl font-medium">配图</label>

      <InputText
        maxlength="1000"
        class="mt-2 w-full"
        autoResize
        placeholder="上传图片后的 assetId"
        :modelValue="props.card.illustration"
        @update:modelValue="onChange({ illustration: $event })"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CardType } from '@/types/core'
import { ref, onMounted } from 'vue'
import ClozeEditor from '@/components/ClozeEditor/ClozeEditor.vue'

import type { ClozeCard, Content } from '@/types/core'
import { computed } from 'vue'
import Empty from '@/components/Empty.vue'

const props = defineProps<{
  card: ClozeCard
}>()

const emit = defineEmits<{
  change: [ClozeCard]
}>()

const altContents = computed(() => props.card.altContents ?? [])

function onChange({
  content,
  altContents,
  analysis,
  illustration,
}: {
  content?: Content
  altContents?: Content[]
  analysis?: Content
  illustration?: string
}) {
  emit('change', {
    type: CardType.CLOZE,
    content: content ?? props.card.content,
    altContents: altContents ?? props.card.altContents,
    analysis: analysis ?? props.card.analysis,
    illustration: illustration ?? props.card.illustration,
  })
}

function onAlternativeContentAdd() {
  onChange({
    altContents: altContents.value.concat([[]]),
  })
}

function onAlternativeContentChange(index: number, content: Content) {
  const contents = [...altContents.value]
  contents.splice(index, 1, content)

  onChange({
    altContents: contents,
  })
}

function onAlternativeContentDelete(index: number) {
  onChange({
    altContents: altContents.value.filter((_, i) => i !== index),
  })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('[data-focus]')?.focus()
  }, 0)
})
</script>

<style scoped></style>
