import type { Text, Cloze, InlineNode, Content } from '@/types/core'

export type RichText = string

const parseCloze = (input: string): Cloze => {
  input = input.trim()

  const result: Partial<Cloze> = {
    type: 'cloze',
  }

  // group，{{c1::xxx}}
  {
    const m = input.match(/^(.*?)(?<!\\)::/)
    if (m) {
      input = input.slice(m.index! + m[0].length)
      result.group = m[1]
    }
  }

  const parts = input.split(/(?<!\\)\|/)

  result.text = handleEscape(parts[0].trim())

  if (parts.length > 1) {
    const allDistrators = parts.slice(1).map(s => handleEscape(s.trim()))
    result.distrators = allDistrators.filter(item => !item.startsWith('~~'))
    result.giveAwayDistrators = allDistrators
      .filter(item => item.startsWith('~~'))
      .map(item => item.slice(2))
  }

  return result as Cloze
}

// ${xxxx}
function parseExpression(expr: string): InlineNode[] {
  expr = expr.trim()

  throw new Error(`invalid expression: ${expr}`)
}

// "\n" -> line feed
// "\\" -> "\"
// "\x" -> "x"
function handleEscape(input: string): string {
  return input.replaceAll('\\n', '\n').replaceAll(/\\(.)/g, '$1')
}

// `\` 转义
function parseStyleText(input: string): Text[] {
  let idx = 0
  const result: Text[] = []

  if (idx < input.length) {
    result.push({ type: 'text', text: handleEscape(input.slice(idx)) })
  }

  return result
}

// this method will __THROW__ if any error occurred
export function parseRichText(input: RichText): InlineNode[] {
  if (input === '') return []

  const result: InlineNode[] = []

  let idx = 0

  // cloze
  for (const m of input.matchAll(/(?<!\\)\{\{(.*?)\}\}/g)) {
    const left = input.slice(idx, m.index)

    result.push(...parseRichText(left))
    result.push(parseCloze(m[1]))

    idx = m.index! + m[0].length
  }

  // expression
  for (const m of input.matchAll(/(?<!\\)\$\{(.*?)\}/g)) {
    // 这里匹配的时候会匹配到之前 idx 之前的内容，此时不应该被处理
    if (m.index! < idx) {
      continue
    }

    const left = input.slice(idx, m.index)

    result.push(...parseRichText(left))
    result.push(...parseExpression(m[1]))

    idx = m.index! + m[0].length
  }

  // style text
  if (idx < input.length) {
    result.push(...parseStyleText(input.slice(idx)))
  }

  return result
}

export function stringifyRichText(content: Content): string {
  const texts: string[] = []

  for (const blockNode of content) {
    if (blockNode.type === 'p') {
      for (const n of blockNode.content) {
        if (n.type === 'text') {
          let text = n.text

          if (n.style?.dot) {
            text = `··${text}··`
          }

          texts.push(text)
        } else if (n.type === 'cloze') {
          const options = [n.text, ...(n.distrators ?? [])]
          if (n.group) {
            texts.push(`{{${n.group}::${options.join('|')}}}`)
          } else {
            texts.push(`{{${options.join('|')}}}`)
          }
        }
      }
    }
  }

  return texts.join('')
}
