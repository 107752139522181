<template>
  <div class="w-full p-4 relative vip-selector">
    <div class="flex items-enter justify-center h-24px">
      <span class="text-ld-premium-800 text-17px leading[1.4] font-semibold">
        {{ _t(`${store.isVipValid ? '续费' : '开通'}畅学卡`) }}
      </span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-2 top-2 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="flex flex-col gap-4 mt-6">
      <div
        v-for="sku in props.skuList"
        :key="sku.id"
        :class="[
          'sku',
          {
            selected: selectedSkuId === sku.id,
          },
        ]"
        @click="selectedSkuId = sku.id"
      >
        <div class="text-ld-premium-900 text-21px leading-[1.4] font-semibold">
          {{ sku.name }}
        </div>

        <div class="text-17px font-bold font-[DIN]">
          <span
            v-if="sku.originalPrice"
            class="text-gray line-through mr-1"
          >
            {{ $f.currency(sku.currency) }} {{ fenToYuan(sku.originalPrice) }}
          </span>
          <span class="text-ld-premium-700">
            {{ $f.currency(sku.currency) }} {{ fenToYuan(sku.price) }}
          </span>
        </div>

        <div
          class="absolute bottom-4 right-4 text-ld-premium-900 leading-[1em]"
        >
          <span class="text-15px">{{ $f.currency(sku.currency) }}</span>
          <span class="text-28px font-[DIN] mx-1">
            {{ fenToYuan(sku.pricePerMonth) }}
          </span>
          <span class="text-15px">/月</span>
        </div>

        <div
          v-if="sku.campaignName"
          class="campaign"
        >
          {{ sku.campaignName }}
        </div>
      </div>
    </div>

    <Button
      class="h-50px w-full mt-6 rounded-12px overflow-hidden"
      scene="vip"
      :label="buttonText"
      :lodaing="vipBuyLoading"
      @click="onVipBuy"
    ></Button>
  </div>
</template>
<script setup lang="ts">
import { OrderStatus, createVipOrder } from '@/api/order'
import type { VipSku } from '@/api/product'
import { payByEnv } from '@/shared/pay'
import { useCommonStore } from '@/stores'
import { fenToYuan } from '@/utils'
import { computed, onUnmounted, ref } from 'vue'

const store = useCommonStore()

const props = defineProps<{
  initialSkuId: number
  skuList: VipSku[]
}>()

const emit = defineEmits<{
  done: []
  bought: []
}>()

const selectedSkuId = ref(props.initialSkuId)
const vipBuyLoading = ref(false)

const buttonText = computed(() => {
  const sku = props.skuList.find(item => item.id === selectedSkuId.value)

  if (store.isVipValid) {
    return _t(`立即续费 畅学${sku?.shortName}`)
  }
  return _t(`立即开通 畅学${sku?.shortName}`)
})

let cancelPoll: VoidFunction

onUnmounted(() => {
  cancelPoll?.()
})

async function onVipBuy() {
  if (selectedSkuId.value == null) return

  vipBuyLoading.value = true

  try {
    const res = await createVipOrder(selectedSkuId.value)

    if (res.code !== 0) {
      vipBuyLoading.value = false
      _message.info(res.message)
      return
    }

    // 0 元订单，下单时就完成了
    if (res.data.status === OrderStatus.DELIVERED) {
      _message.success('订单支付成功（0元订单）')
      onPaySuccess()
      return
    }

    const payData = payByEnv(res.data.orderId, onPaySuccess)
    cancelPoll = payData.cancelPoll
    payData.payPromise
      .catch(e => {
        _message.info(e.message)
      })
      .finally(() => {
        vipBuyLoading.value = false
      })
  } catch (_err) {
    vipBuyLoading.value = false
  }
}

function onPaySuccess() {
  vipBuyLoading.value = false

  store.fetchUserVip()
  store.fetchEnergyStatus()

  emit('bought')
  emit('done')
}
</script>
<style scoped>
.vip-selector {
  background: linear-gradient(
    114.77deg,
    var(--ld-premium-100) 0%,
    var(--ld-premium-200) 100%
  );
}

.sku {
  padding: 16px;
  border-radius: 12px;
  background: var(--ld-premium-50);
  border: 1px solid var(--ld-premium-300);
  margin: 1px;
  position: relative;
  overflow: hidden;
}

.sku.selected {
  margin: 0px;
  border: 2px solid var(--ld-premium-900);
}

.campaign {
  color: var(--ld-premium-700);
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--ld-premium-300);
  border-radius: 0px 0px 0px 12px;
  font-size: 17px;
  line-height: 1.4;
  font-weight: 600;
  padding: 3px 6px;
}
</style>
