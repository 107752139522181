<template>
  <div
    :style="style"
    class="image-container"
  >
    <div
      v-if="isLoading"
      class="placeholder"
    ></div>
    <img
      :src="src"
      alt=""
      class="w-full h-full"
      @load="handleLoad"
      @error="handleError"
    />
    <div
      v-if="isError"
      class="error-ui"
    >
      <div style="flex: 30"></div>
      <Icon
        name="img-error"
        style="flex: 80"
      />
      <div
        style="flex: 15"
        class="text-ld-label-secondary"
      >
        {{ _t('加载失败') }}
      </div>
      <div style="flex: 46"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

const IMGS = import.meta.glob('../assets/images/*', { eager: true })

const props = defineProps<{
  name?: string
  assetId?: string
  imgStyle?: string
}>()

const src = computed(() => {
  if (props.assetId) {
    return _global.assetUrl(props.assetId, props.imgStyle)
  }
  for (const key in IMGS) {
    if (key.startsWith(`../assets/images/${props.name}.`)) {
      return (IMGS[key] as any).default
    }
  }
  return ''
})

const ratio = computed(() => {
  const nameWithExt = src.value.split('/').pop()
  if (!nameWithExt) return null
  const [_, width, height] = nameWithExt.split('.')
  return Number(width) / Number(height)
})

const style = computed(() => {
  if (ratio.value == null) return {}

  return {
    aspectRatio: ratio.value,
  }
})
// 定义状态
const isLoading = ref(true)
const isError = ref(false)
function handleLoad() {
  isError.value = false
  isLoading.value = false
}
function handleError() {
  isError.value = true
  isLoading.value = false
}
</script>

<style scoped>
.image-container {
  position: relative;
  overflow: hidden;
}

.placeholder,
.error-ui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.placeholder {
  background: linear-gradient(
    270deg,
    var(--gray-50) 18.75%,
    var(--gray-200) 179.69%
  );
}

.error-ui {
  background: var(--ld-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
