<template>
  <Loading
    v-if="data.loading"
    class="h-full"
  />

  <div
    v-else
    class="w-full pb-4 bg-ld-background h-full overflow-auto"
  >
    <div class="bg-ld-background sticky top-0 z-3 pt-6 pb-2 px-4">
      <div class="search-panel">
        <Icon
          name="search"
          class="w-20px"
        />

        <InputText
          ref="searchInput"
          class="flex-1 mx-4 border-none search-input"
          :placeholder="_t('搜索卡包')"
          @focus="onSearchInputFocus"
        ></InputText>

        <Divider
          layout="vertical"
          class="h-20px p-0"
        />

        <i
          class="pi pi-plus ml-auto cursor-pointer text-12px font-bold"
          @click="onCreatePackage"
        />
      </div>
    </div>

    <Empty
      v-if="!store.isLoggedIn"
      text="登录来查看您的卡包"
    >
      <Button
        label="登录"
        class="mt-4"
        @click="onLogin"
      />
    </Empty>

    <PkgList
      v-else
      :packages="data.packages"
      :empty-hint="_t('暂无卡包')"
      class="px-4"
      @edit="onPackageEdit"
      @share-setting="onPackageShareSetting"
      @delete="onPackageDelete"
      @remove-shelf="onPackageRemoveShelf"
      @package-create="onCreatePackage"
      @search="onSearchInputFocus"
      @click="onClickPackage"
    />
  </div>
</template>

<script setup lang="ts">
import { deletePackageById, fetchPackages } from '@/api/package-source'
import { useCommonStore } from '@/stores'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import Empty from '@/components/Empty.vue'
import { reactive } from 'vue'
import Loading from '@/components/Loading.vue'
import PkgList from '@/components/PkgList/PkgList.vue'
import PkgForm from '@/components/PkgList/PkgForm.vue'
import ShareForm from '@/components/PkgList/ShareForm.vue'
import SearchPanel from '@/components/SearchPanel/SearchPanel.vue'
import { useRoute, useRouter } from 'vue-router'
import hotkeys from 'hotkeys-js'
import { removeShelfPackageById } from '@/api/user'

import type { PackageBasic } from '@/api/package-source'
import { getPackageShowDate } from '@/utils/package'
import { FeatureType } from '@/api/beta-feature'

const store = useCommonStore()
const route = useRoute()
const router = useRouter()

const data = reactive({
  packages: [] as PackageBasic[],
  loading: true,
})

const searchInput = ref()
let searchPanelVisible = false

onMounted(() => {
  hotkeys('ctrl+k,command+k', () => {
    onSearchInputFocus()
  })
})

onUnmounted(() => {
  hotkeys.unbind('ctrl+k,command+k')
})

fetchData()

watch(() => store.user?.id, fetchData)

function onLogin() {
  router.push({
    name: 'auth',
    query: {
      from: encodeURIComponent(route.fullPath),
    },
  })
}

function onSearchInputFocus() {
  searchInput.value?.$el.blur()

  if (_global.isPcMode) {
    showSearchPanel()
  } else {
    router.push({ name: 'searchPanel' })
  }
}

function onClickPackage(id: number) {
  router.push({ name: 'package', params: { id: id } })
}

async function showSearchPanel() {
  if (searchPanelVisible) return

  searchPanelVisible = true

  await _openDialog(SearchPanel, {
    props: {
      isPage: false,
      navigator: 'modal',
      onPackageChallenge(pkgId: number) {
        router.push({
          name: 'atlas',
          query: {
            pkgId,
          },
        })
      },
    },
    dialog: {
      contentClass: 'p-0 bg-[var(--surface-hover)] ',
      showHeader: false,
    },
    rootClass: 'w-1024px mx-10 h-[80vh] min-w-[480px] min-h-[480px]',
  })

  searchPanelVisible = false
}

async function fetchData() {
  if (!store.isLoggedIn) {
    data.packages = []
    return
  }
  data.loading = true

  try {
    await fetchPackagesData()
  } finally {
    data.loading = false
  }
}

async function fetchPackagesData() {
  const res = await fetchPackages()

  data.packages = res.packages.sort(
    (a, b) => getPackageShowDate(b) - getPackageShowDate(a)
  )
}

function onPackageEdit(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  _openDialog(PkgForm, {
    title: '编辑卡包',
    rootClass: 'p-0',
    dialog: {
      showHeader: false,
      dismissableMask: true,
      contentClass: 'px-4 py-3',
    },
    props: {
      package: pkg,
      onUpdate(newPkg: PackageBasic) {
        pkg.name = newPkg.name
        pkg.style = newPkg.style
        pkg.contentTags = newPkg.contentTags
      },
    },
  })
}

function onPackageShareSetting(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  _openDialog(ShareForm, {
    title: '共享',
    rootClass: 'p-0 g-dialog',
    dialog: {
      showHeader: false,
      contentClass: 'px-4 py-3',
    },
    props: {
      package: pkg,
      onUpdate: (pkgRes: PackageBasic) => {
        const index = data.packages.findIndex(pkg => pkg.id === id)
        data.packages[index] = pkgRes
      },
    },
  })
}

function getPkgTitleForDialog(name: string) {
  return `《${name.length > 20 ? name.slice(0, 20) + '...' : name}》`
}

async function onPackageDelete(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  const res = await _confirm({
    title: `是否删除${getPkgTitleForDialog(pkg.name)}？`,
    type: 'warn',
    content: '删除后无法恢复，请确认',
    okText: '删除',
    cancelText: '暂不',
    onConfirm: async () => {
      const res = await deletePackageById(pkg.id)
      return res.code === 0
    },
  })

  if (res) {
    _message.info(_t('卡包已删除'))
    const index = data.packages.findIndex(pkg => pkg.id === id)
    data.packages.splice(index, 1)
  }
}

async function onPackageRemoveShelf(id: number) {
  const pkg = data.packages.find(pkg => pkg.id === id)!

  const res = await _confirm({
    content: `是否将${getPkgTitleForDialog(pkg.name)}从我的卡包中移除？`,
    type: 'warn',
    okText: '移除',
    cancelText: '暂不',
    onConfirm: async () => {
      try {
        await removeShelfPackageById(pkg.id)
        return true
      } catch (_e) {
        return false
      }
    },
  })

  if (res) {
    const index = data.packages.findIndex(pkg => pkg.id === id)
    data.packages.splice(index, 1)
    _message.success(`[${pkg.name}] 已从我的卡包中移除`)
  }
}

function onCreatePackage() {
  if (!store.isAlphaCreator) {
    _confirm({
      content: '卡包制作功能尚未开放，你可以申请抢先测试资格',
      type: 'confirm',
      okText: _t('去申请'),
      cancelText: _t('暂不'),
      async onConfirm() {
        router.push({
          name: 'beta-feature',
          query: {
            name: FeatureType.AlphaCreator,
          },
        })
        return true
      },
    })

    return
  }

  _openDialog(PkgForm, {
    title: '新建卡包',
    props: {
      onCreate(pkg: PackageBasic) {
        onClickPackage(pkg.id)
      },
    },
    rootClass: 'p-0',
    dialog: {
      showHeader: false,
      dismissableMask: true,
      contentClass: 'px-4 py-3',
    },
  })
}
</script>

<style scoped>
.search-panel {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 40px;
  border: 1px solid var(--ld-border);
  padding: 10px 12px;
}

.search-input {
  background-color: transparent;
  box-shadow: none;
  padding: 0px;
  margin: 4px;
}
</style>
