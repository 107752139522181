<template>
  <div class="w-full p-4 flex flex-col items-center">
    <div class="w-full flex items-enter h-24px">
      <DiamondCount class="absolute left-4" />

      <span class="text-17px leading[1.4] mx-auto">
        {{ _t('解锁完整版') }}
      </span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="mx-auto px-4 flex flex-col items-center my-8 gap-10px">
      <div
        class="w-99px h-99px rounded-4 text-60px flex justify-center items-center"
        :style="{
          backgroundColor: `var(--${props.package.style.themeColor}-200)`,
        }"
      >
        <PkgIcon
          :style="props.package.style"
          class="w-66px h-66px"
        />
      </div>

      <div
        class="text-19px leading-[1.2] font-semibold line-clamp-2"
        :style="{
          color: `var(--${props.package.style.themeColor}-800)`,
        }"
      >
        {{ props.package.name }}
      </div>
    </div>

    <template v-if="props.package.packageAccess === PackageAccess.Author">
      <div class="text-21px text-ld-brand-500 font-semibold mb-4">
        {{ _t('这是你自己的卡包') }}
      </div>
    </template>

    <template v-if="props.package.packageAccess === PackageAccess.Unlock">
      <div class="text-21px text-ld-brand-500 font-semibold mb-4">
        {{ _t('该卡包已经永久解锁') }}
      </div>
    </template>

    <template v-else>
      <div
        v-if="store.isVipValid"
        class="text-19px text-ld-premium-800 leading-[1.4] font-semibold text-center"
      >
        <div>{{ _t('已开通畅学卡') }}</div>
        <div>{{ _t('当前可以任意畅学所有卡包') }}</div>
      </div>

      <div
        v-else-if="store.vip != null"
        class="text-17px text-[var(--text-color-secondary)] leading-[1.4] font-semibold text-center mb-1"
      >
        {{ _t('畅学卡已过期，请重新开通') }}
      </div>

      <Button
        v-if="!store.isVipValid && defaultVipSku != null"
        class="h-74px w-full"
        scene="vip"
        @click="onVipBuy"
      >
        <div class="flex-1 flex flex-col items-center font-semibold gap-6px">
          <div class="text-19px leading-none">{{ _t('开通畅学卡') }}</div>
          <div class="text-15px leading-16px">
            {{ _t('所有卡包免解锁、无限畅学') }}
          </div>
        </div>
      </Button>

      <Button
        class="h-74px w-full mt-4"
        :loading="unlockLoading"
        @click="onUnlockByDiamonds"
      >
        <div class="flex-1 flex flex-col items-center font-semibold gap-6px">
          <div class="text-19px leading-none">{{ _t('使用钻石解锁') }}</div>
          <div class="flex items-center">
            <Icon
              name="diamond"
              class="w-16px mr-1"
            />
            <span class="text-15px leading-16px">
              {{ unlockDiamonds }}
            </span>
          </div>
        </div>
      </Button>
    </template>
  </div>
</template>
<script setup lang="ts">
import { fetchVipProducts, type VipSku } from '@/api/product'
import { computed, ref } from 'vue'
import PkgIcon from '@/pages/Atlas/PkgIcon.vue'
import {
  type PackageBasic,
  PackageAccess,
  unlockPackageByDiamonds,
} from '@/api/package-source'
import { useCommonStore } from '@/stores'
import { Code } from '@/api/code'
import DiamondCount from '@/components/DiamondCount.vue'
import NotEnoughDiamonds from './NotEnoughDiamonds.vue'
import { openStore } from '@/shared'

const store = useCommonStore()

const props = withDefaults(
  defineProps<{
    package: PackageBasic
    exitAfterUnlock?: boolean
  }>(),
  {
    exitAfterUnlock: false,
  }
)

const emit = defineEmits<{
  done: []
  unlock: [PackageAccess]
}>()

const vipSkuList = ref<VipSku[]>([])
const unlockDiamonds = ref(props.package.unlockDiamondRequirement)
const unlockLoading = ref(false)

const defaultVipSku = computed(() =>
  vipSkuList.value.find(item => item.defaultSelected)
)

store.fetchUserVip()
fetchVipProducts().then(res => {
  vipSkuList.value = res.productList
})

function onVipBuy() {
  openStore({
    exitAfterVipBought: true,
    onVipBought() {
      emit('unlock', PackageAccess.Vip)

      if (props.exitAfterUnlock) {
        emit('done')
      }
    },
  })
}

async function onUnlockByDiamonds() {
  unlockLoading.value = true
  unlockPackageByDiamonds(props.package.id)
    .then(res => {
      if (res.code === Code.NotEnoughDiamonds) {
        _message.info(res.message)
        _presentContent(NotEnoughDiamonds, {
          rootClass: 'min-w-400px max-w-600px',
        })
        return
      } else if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      _message.info(_t('卡包已解锁'))
      store.fetchUserAssets()
      emit('unlock', PackageAccess.Unlock)

      if (props.exitAfterUnlock) {
        emit('done')
      }
    })
    .finally(() => {
      unlockLoading.value = false
    })
}
</script>
<style scoped></style>
