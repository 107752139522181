<template>
  <div
    ref="padEl"
    class="pad ld-shadow"
  >
    <div
      v-if="studyCount"
      class="text-15px text-[var(--text-color-secondary)] mb-2"
    >
      {{ `已学习 ${studyCount} 次` }}
    </div>

    <CardEssence :card="card" />
  </div>
</template>
<script setup lang="ts">
import type { CardResponse } from '@/api/package-source'

import { type Card } from '@/types/core'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import bus, { BusEvent } from '@/bus/bus'
import CardEssence from '@/components/CardEssence/CardEssence.vue'

const props = defineProps<{
  cardResponse?: CardResponse
  card?: string
}>()

const padEl = ref<HTMLDivElement>()

const card = computed(() => {
  if (props.cardResponse) {
    return JSON.parse(props.cardResponse.content) as Card
  }
  if (props.card) {
    return JSON.parse(props.card) as Card
  }

  return {} as Card
})

// 学习次数
const studyCount = computed<number | null>(() => {
  // TODO: 获取学习次数
  return null
})

function onFocus(id: number) {
  if (id === props.cardResponse?.id) {
    padEl.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }
}

onMounted(() => {
  bus.on(BusEvent.CardFocus, onFocus)
})

onUnmounted(() => {
  bus.off(BusEvent.CardFocus, onFocus)
})
</script>
<style scoped>
.pad {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: 1px solid var(--ld-border);
  transition: all 0.3s;
  background-color: white;
}
</style>
