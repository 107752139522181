<template>
  <div class="flex-1 flex flex-col w-full overflow-auto">
    <div class="vstack items-center mb-4">
      <Avatar
        :avatar="user.avatar"
        class="w-90px h-90px"
      />
      <TextButton
        class="mb-6"
        :label="_t('更改头像')"
        :loading="avatarLoading"
        @click="onChangeAvatar"
      ></TextButton>

      <div class="w-full">
        <ListCell
          title="昵称"
          :label="user.nickname"
        >
          <template #action>
            <TextButton
              :label="_t('修改')"
              :loading="nicknameLoading"
              @click="onChangeNickname"
            ></TextButton>
          </template>
        </ListCell>
      </div>

      <div class="w-full mt-6">
        <ListCell
          title="微信"
          :label="wechatLabel"
        >
          <template #action>
            <TextButton
              v-if="!user.hasBoundWechat"
              :loading="wechatLoading"
              :label="_t('绑定')"
              @click="onBindWechat"
            ></TextButton>
            <div v-else></div>
          </template>
        </ListCell>

        <ListCell
          title="编号"
          :label="user.id"
        >
          <template #action>
            <TextButton
              :label="_t('复制')"
              @click="onCopyId"
            ></TextButton>
          </template>
        </ListCell>

        <ListCell
          title="邮箱"
          :label="user.email"
        >
          <template #action>
            <TextButton
              v-if="user.email != null"
              :loading="emailLoading"
              :label="_t('解绑')"
              @click="onUnbindEmail"
            ></TextButton>
            <TextButton
              v-else
              :loading="emailLoading"
              :label="_t('绑定')"
              @click="onBindEmail"
            ></TextButton>
          </template>
        </ListCell>

        <ListCell
          v-if="user.email != null"
          title="密码"
        >
          <template #action>
            <TextButton
              label="修改"
              @click="onChangePwd"
            ></TextButton>
          </template>
        </ListCell>
      </div>
    </div>

    <div class="w-full my-6">
      <ListCell
        title="当前版本"
        :label="gitHash"
        :arrow="false"
        class="break-all"
      ></ListCell>

      <ListCell title="清除缓存">
        <template #action>
          <TextButton
            label="立即清除"
            @click="onCacheClear"
          ></TextButton>
        </template>
      </ListCell>
    </div>

    <Button
      label="登出当前账户"
      class="mt-auto"
      scene="danger"
      @click="onLogout"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useCommonStore } from '@/stores'
import { bindWechat, patchUser, unbindEmail, uploadAvatar } from '@/api/user'
import BindEmailForm from './BindEmailForm.vue'
import PasswordChangeForm from './PasswordChangeForm.vue'
import BindWechat from './BindWechat.vue'
import { useRouter } from 'vue-router'
import ListCell from '@/components/ListCell/ListCell.vue'
import { pickImg } from '@/utils/index'
import { Code } from '@/api/code'
import { getCurrentUser } from '@/api/auth'
import db, { mergeDefaultDb, type DB } from '@/db'

const WECHAT_NICKNAME_LIMIT = 10

const router = useRouter()
const store = useCommonStore()
const user = store.user!

const emit = defineEmits<{
  done: []
}>()

const wechatLabel = computed(() => {
  if (user.hasBoundWechat) {
    const nickname = user.wechatInfo?.nickname ?? ''
    return nickname.length > WECHAT_NICKNAME_LIMIT
      ? `${nickname.slice(0, WECHAT_NICKNAME_LIMIT)}...`
      : nickname
  }
  return ''
})

const gitHash = computed(() => _global.gitCommit.split('(')[0])

function onChangePwd() {
  _openDialog(PasswordChangeForm, {
    title: _t('修改密码'),
    props: {
      email: user.email,
    },
  }).then(newPassword => {
    if (newPassword) {
      _message.success(_t('密码已修改'))
    }
  })
}

function onLogout() {
  store.logout()
  router.push({ name: 'landing' })
  emit('done')
}

const nicknameLoading = ref(false)

function onChangeNickname() {
  _openInputDialog({
    title: _t('输入新的昵称'),
    placeholder: _t('输入新的昵称'),
    okText: _t('保存'),
    async onSubmit(newNickname: string) {
      if (newNickname == null) return false

      if (newNickname.length < 2) {
        _message.info(_t('昵称最少 2 个字'))
        return false
      }
      if (newNickname.length > 10) {
        _message.info(_t('昵称最多 10 个字'))
        return false
      }
      if (!/^[^!\"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]*$/.test(newNickname)) {
        _message.info(_t('请勿使用特殊符号'))
        return false
      }

      nicknameLoading.value = true
      await patchUser({ nickname: newNickname.trim() })
        .then(() => {
          user.nickname = newNickname
          _message.success(_t('修改成功'))
        })
        .finally(() => {
          nicknameLoading.value = false
        })
      return true
    },
  })
}

// 微信绑定解绑
const wechatLoading = ref(false)

function onBindWechat() {
  wechatLoading.value = true

  _openDialog(BindWechat, {
    title: _t('扫码绑定微信'),
  })
    .then(code => {
      if (typeof code === 'string') {
        bindWechat(code).then(res => {
          if (res.code === 0) {
            store.patchUser({ hasBoundWechat: true })
            _message.success(_t('微信绑定成功'))

            getCurrentUser().then(res => {
              store.patchUser({ wechatInfo: res.user.wechatInfo })
            })
            return
          }

          _message.info(res.message)
        })
      }
    })
    .finally(() => {
      wechatLoading.value = false
    })
}

// 邮箱绑定解绑

const emailLoading = ref(false)
function onBindEmail() {
  _openDialog(BindEmailForm, {
    title: _t('绑定邮箱'),
  })
}

function onUnbindEmail() {
  emailLoading.value = true
  unbindEmail()
    .then(res => {
      if (res.code === 0) {
        _message.success(_t('解绑成功'))
        user.email = undefined
        return
      }

      _message.error(res.message)
    })
    .finally(() => {
      emailLoading.value = false
    })
}

function onCopyId() {
  navigator.clipboard.writeText(user.id).then(() => {
    _message.success(_t('已复制'))
  })
}

const avatarLoading = ref(false)

async function onChangeAvatar() {
  const imgFile = await pickImg(_t('更改头像'), true)

  if (!imgFile) return

  avatarLoading.value = true
  try {
    const res = await uploadAvatar(imgFile)

    if (res.code !== Code.Ok) {
      _message.info(res.message)
    } else {
      store.patchUser({ avatar: res.data.assetId })
      _message.success(_t('头像更换成功'))
    }
  } finally {
    avatarLoading.value = false
  }
}

function onCacheClear() {
  const newDb = mergeDefaultDb({
    loginToken: db.loginToken,
  })

  for (const key of Object.getOwnPropertyNames(db)) {
    // TODO(buding) 解决 ts 报错
    db[key as keyof DB] = newDb[key as keyof DB]
  }

  _message.info('缓存已全部清理')
  _refreshApp()
}
</script>

<style scoped></style>
