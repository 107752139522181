import { Interaction, LearnStatus } from '@/types/core'
import api from './base'

import type { CommonResponse } from './base'
import type { DailyTask, GoalType } from './task'

export interface CardSchedule {
  cardId: number
  content: string
  authorId: string
  cardStatus: LearnStatus

  r: number
}

export interface Unit {
  unitId: number
  local?: boolean
  atlasStageId?: number
  schedules: CardSchedule[]
}

export function fetchUnitScheduleByChapter(payload: { packageId: number }) {
  return api.post<never, CommonResponse<Unit>>(`/v1/learn/units`, payload)
}

export enum UnitStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export enum UnitEventType {
  WRONG = 'WRONG', // 做错
  CORRECT = 'CORRECT', // 做对
  TIP = 'TIP', // 提示
  LAPSE = 'LAPSE', // 记错了
}

export interface UnitEvent {
  event: UnitEventType
  stage: Interaction
  timestamp: number
}

interface UnitReportPayload {
  unitId: number
  status?: UnitStatus
  learnEvents: {
    [cardId in string]: UnitEvent[]
  }
  challengeStageParam?: {
    duelParam: {
      // 百分比小数 eg. 0.5 代表剩余 50% 血量
      leftBlood: number
    }
  }
  // 卡片学习时长，key 为卡片 id，value 为秒
  cardDurations: Record<number, number>
}

export interface UnitCompleteStat {
  unitId: number
  debutCount: number
  reviewCount: number
  duration: number
  exp: number
  star: number
  checkInPopped: boolean
}

export interface DailyTaskResponse {
  tasks: DailyTask[]
  lastStats: Record<GoalType, number>
  stats: Record<GoalType, number>
}

export function reportUnitEvents(payload: UnitReportPayload) {
  return api.post<
    never,
    { stats: UnitCompleteStat; dailyTaskResponse: DailyTaskResponse }
  >(`/v1/learn/units/report`, payload)
}

export enum StageType {
  NORMAL = 'NORMAL',
  QUIZ = 'QUIZ',
}

export interface Stage {
  id: number
  type: StageType
  createdAt: string
}

export interface CompletedStage extends Stage {
  completedAt: string
}

export enum StageStatus {
  OK = 'OK',
  WAIT = 'WAIT',
  NEED_VIP = 'NEED_VIP',
  CHALLENGE_COMPLETED = 'CHALLENGE_COMPLETED',
}

export interface CurrentStage {
  status: StageStatus
  dayToWait: number
  data?: Stage
}

export interface DictEnWord {
  spelling: string
  explain: string
  phoneticUk: string
  phoneticUs: string
}

export function searchEnWord(keyword: string) {
  return api.get<never, { dictEnList: DictEnWord[] }>(`/v1/dict/en-search`, {
    params: {
      keyword,
    },
  })
}
